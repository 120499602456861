<template>
  <div class="filter-partners--list">
    <div
      class="supplier-partners"
      v-for="(supplier, i) in suppliers"
      @click="handleGoSupplier(supplier.slug)"
      :key="i"
    >
      <div
        class="list-item--name"
      >
        <span class="check-icon">
          <icon
            :icon="'check-orange'"
            size="xs">
          </icon>
        </span>
        {{ supplier.title }}
      </div>
      <span
        v-if="supplier.keyword"
        class="list-item--category"
      >{{ supplier.keyword }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suppliers: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  methods: {
    handleGoSupplier (slug) {
      this.$router.push(`/fournisseur/${slug}`)
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>

<style lang="postcss" scoped>

.supplier-partners {
  @apply cursor-pointer
}

.filter-partners--list .supplier-partners .list-item--name {
  @apply bg-gray-200 rounded-full py-1 pl-1 pr-3 inline-flex
}

.list-item--name .check-icon {
  @apply rounded-full bg-white mr-1 p-2;
}
</style>
