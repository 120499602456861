import mutations from './mutations'

export default ({
  state: {
    filters: {
    },
  },

  mutations,
})
