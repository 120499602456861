<template>
  <card>
    <template slot="header">
      <icon
        icon="formation"
        size="xl" />
      <h2>Formations</h2>
    </template>

    <miniature-article
      v-for="(item, i) in formation"
      :key="i"
      :title="item.title"
      :media="item.medias"
      :body="item.body"
      :slug="item.slug"
      :type="item.type"
    />

    <template slot="footer">
      <button
        @click="handleClick"
        class="btn btn-small btn-outline--primary">Voir le catalogue</button>
    </template>
  </card>
</template>

<script>
import card from '@/components/commons/Card.vue'
import MiniatureArticle from '@/components/customs/articles/MiniatureArticle.vue'

export default {
  components: {
    card,
    MiniatureArticle
  },

  computed: {
    items () {
      return this.$store.state.home.formations
    },

    formation () {
      return this.items.slice(0, 1)
    }
  },

  methods: {
    handleClick () {
      this.$router.push({ name: 'formations' })
    },

    fetchFormations () {
      this.$store.dispatch('home/requestFormations')
    }
  },

  beforeMount () {
    this.fetchFormations()
  }
}
</script>
