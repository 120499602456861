<template>
  <article class="articles-item">
    <div class="articles-item--media">
      <img
        class="thumbnail clickable"
        :src="content.medias.thumbnail.source"
        :alt="content.medias.thumbnail.alt"
        :title="content.medias.thumbnail.title"
        @click="handleClick">

      <div
        v-if="content.metadatas.date"
        class="articles-item--date clickable"
        @click="handleClick">
        <span
          v-if="hasScheduledDate"
          class="label">Prochaine session</span>
        <span class="date">{{ content.metadatas.date }}</span>
      </div>
    </div>

    <div
      v-if="subinfos"
      class="articles-item--subinfos">
      <span>{{ content.type }}</span>
      <span v-if="!content.metadatas.date">{{ content.createdAt }}</span>
    </div>

    <h2
      class="articles-item--title clickable"
      @click="handleClick">{{ content.title }}</h2>
    <p class="articles-item--description">{{ content.body }}</p>

    <div class="articles-item--actions">
      <button
        class="btn btn-small btn-outline--primary"
        @click="handleClick">
        Lire la suite
      </button>
      <button
        v-if="this.content.type !== 'Webinars' && content.metadatas.subscription"
        class="btn btn-small btn-primary"
        @click="handleSubscribe">S'inscrire</button>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
      required: true
    },

    subinfos: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  computed: {
    hasScheduledDate () {
      return this.$route.name === 'formations'
    }
  },

  methods: {
    handleClick () {
      if (this.$route.name === 'formations' || this.$route.query.filtre === 'formations' || this.content.type === 'Formations') {
        this.$router.push(`/formation/${this.content.slug}`)
      } else {
        this.$router.push(`/article/${this.content.slug}`)
      }
    },

    handleSubscribe () {
      if (this.$route.name === 'formations' || this.$route.query.filtre === 'formations' || this.content.type === 'Formations') {
        this.$router.push(
        {
          name: 'form',
          query: {
            object: 'S\'inscrire à l\'évènement : '+ this.content.title,
            event: this.content.title,
            eventDate: this.content.metadatas.date,
            receiverEmail: 'mercurial@mercurial.fr',
            receiverName: 'Équipe Mercurial',
            isFormationContact: true
          }
        })
      }
      else {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'S\'inscrire à l\'évènement',
            event: this.content.title,
            eventDate: this.content.metadatas.date,
            receiverEmail: 'mercurial@mercurial.fr',
            receiverName: 'Équipe Mercurial'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-primary {
  @apply normal-case;
}
</style>
