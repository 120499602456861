<template>
  <div
    class="filter-families--list"
  >
    <div
      v-for="(family, indexFamily) in families"
      :key="indexFamily"
      class="supplier-family"
    >
      <span
        @click="handleGoFamily(family.categSlug, family.slug)"
        class="family--list-item--name"
      >
        {{ family.title }}
      </span>
      <span
        v-for="(subfamily, indexSubfamily) in family.subfamilies"
        :key="indexSubfamily"
        @click="handleGoSubfamily(family.categSlug, family.slug, subfamily.slug)"
        class="family--list-item--description"
      >
        {{ subfamily.title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    families: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  methods: {
    handleGoFamily (categSlug, familySlug) {
      this.$router.push(`/fournisseurs/${categSlug}?famille=${familySlug}`)
      this.$store.dispatch('engine/panelStatus', false)
    },

    handleGoSubfamily (categSlug, familySlug, subfamilySlug) {
      this.$router.push(`/fournisseurs/${categSlug}?famille=${familySlug}&sousfamille=${subfamilySlug}`)
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>

<style lang="postcss" scoped>
  .supplier-family {
    @apply cursor-pointer
  }

  .supplier-family .family--list-item--name {
    @apply inline-block lowercase font-bold mr-2;
  }

  .supplier-family .family--list-item--name:first-letter {
    text-transform: uppercase;
  }

  .supplier-family .family--list-item--description {
    @apply inline-block lowercase font-bold mr-2;
  }

  .supplier-family .family--list-item--description:first-letter {
    text-transform: uppercase;
  }
</style>
