const drawerItemsSupplier = [
  {
    value: 'supplierPresentation',
    label: 'Présentation',
    icon: 'presentation',
    link: '',
    active: true
  },
  {
    value: 'supplierOffers',
    label: 'Les offres',
    icon: 'offres',
    link: 'offres',
    active: false
  },
  {
    value: 'supplierDocuments',
    label: 'Documents fournisseur',
    icon: 'documents-supplier',
    link: 'documents',
    active: false,
    hidden: false
  },
  // {
  //   value: 'supplierCards',
  //   label: 'Fiches experts',
  //   icon: 'fiches-experts',
  //   link: 'fiches',
  //   active: false
  // }
  // {
  //   title: 'members',
  //   label: 'Adhérents utilisant ce fournisseur',
  //   icon: 'adherent-fournisseur',
  //   link: 'adherents',
  //   active: false
  // }
]

export default drawerItemsSupplier
