<template>
  <div class="wrapper">
    <div class="subcontainer">
      <section class="subcontainer-header pt-6">
        <div class="subcontainer-header--title">
          <icon
            icon="resultat-recherche"
            size="xl"/>
          <div>
            <h1 class="uppercase text-primary-light font-bold">Offres négociées</h1>
            <p class="uppercase font-bold text-xl">Résultat de votre recherche
              <span>« {{ $route.query.motclef }} »</span>
            </p>
          </div>
        </div>
      </section>
    </div>

    <div class="background">
      <div class="subcontainer">
        <section class="subcontainer-content pb-6">
          <list-offers />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ListOffers from '@/components/customs/offers/ListOffers'

export default {
  components: {
    ListOffers
  },

  methods: {
    fetchOffers () {
      const payload = {
        keyword: this.$route.query.motclef,
        page: this.$route.query.page
      }

      this.$store.dispatch('requestResultsOffers', payload)
    }
  },

  watch: {
    $route (to, from) {
      // On surveille s'il y a eut une redirection ou un changement dans la
      // route (peut s'effectuer si un user renseigen un nouveau mot clef ou
      // navigue avec la pagination par exemple), puis on requête les offres en
      // fonction des nouveaux paramètres renseignés dans l'URL.
      this.fetchOffers()
    }
  },

  beforeMount () {
    this.fetchOffers()
  }
}
</script>
