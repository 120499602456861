<template>
  <article
    class="offers-list--item"
    :class="{ 'special' : isSpecial }">
    <section class="offer-media">
      <img
        class="offer-media--image"
        :src="offer.medias.heading.source"
        :alt="offer.medias.heading.alt">
    </section>

    <section class="offer-infos">
      <span
        v-if="isSpecial"
        class="special">
        Offre spéciale
      </span>

      <div class="offer-infos--header">
        <p
          v-if="offer.supplier && $route.name === 'offers'"
          class="offer-infos--supplier">
          {{ offer.supplier.title }}
        </p>

        <h2 class="offer-infos--title">
          {{ offer.title }}
        </h2>

        <small
          v-if="offer.expirationDate"
          class="offer-infos--subtitle">
          Jusqu'au {{ offer.expirationDate }}
        </small>
      </div>

      <div class="flex">
        <img
          class="offer-infos--image"
          v-if="offer.supplier.medias && $route.name === 'offers'"
          :src="offer.supplier.medias.thumbnail.source"
          :alt="offer.supplier.medias.thumbnail.alt">

        <p class="offer-infos--body">{{ offer.body }}</p>
      </div>

      <div
        v-if="offer.contacts.length > 0"
        class="offer-infos--contacts">
        <h3 class="offer-infos--contacts contacts-title">Contacts fournisseurs pour cette offre</h3>

        <div class="contact-mesh">
          <contact
            v-for="(contact, index) in maximum2Contacts"
            :key="index"
            :contact="contact" />
        </div>
      </div>

        <div class="flex items-center text-sm mt-3">
                <icon
                  class="mx-1"
                  icon="mise-a-jour"
                  size="sm"></icon>
                <span>Mis à jour le</span>
                <strong>
                  {{ offer.updatedAt }}
                </strong>
          </div>

    </section>

    <section class="offer-actions">
      <div class="offer-actions--documents">
        <ul class="offer-actions--tab">
          <li
            class="tab-item"
            :class="{ 'active' : tabSetToPrices }"
            @click="handleSwitch">Vos tarifs Mercurial</li>
          <li
            class="tab-item"
            :class="{ 'active' : !tabSetToPrices }"
            @click="handleSwitch">Les documents de l'offre</li>
        </ul>
        <list-documents
          :documents="typeOfDocumentsToDisplay"
          :catalog="offer.catalogUrl"
          restricted />
      </div>

      <div class="offer-actions--contacts">
        <other-contacts
          v-if="offer.supplier.contacts.list"
          title="Autres contacts fournisseurs"
          :contacts="offer.supplier.contacts.list" />
        <button
          v-if="$route.name === 'offers'"
          class="btn btn-outline--primary btn-block"
          @click="handleGoSupplier">Voir le fournisseur</button>
        <button
          @click="handleGoContactForm"
          class="btn btn-secondary btn-block">Prendre contact</button>
      </div>
    </section>
  </article>
</template>

<script>
import Contact from '@/components/customs/contacts/Contact'
import ListDocuments from '@/components/customs/documents/ListDocuments'
import OtherContacts from '@/components/customs/contacts/OtherContacts'

export default {
  components: {
    Contact,
    ListDocuments,
    OtherContacts
  },

  data: () => ({
    tabSetToPrices: true
  }),

  props: {
    offer: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  computed: {
    isSpecial () {
      return this.offer.special
    },

    typeOfDocumentsToDisplay () {
      return this.tabSetToPrices ? this.offer.prices : this.offer.documents
    },

    maximum2Contacts () {
      return this.offer.contacts.slice(0, 2)
    }
  },

  methods: {
    handleSwitch () {
      this.tabSetToPrices = !this.tabSetToPrices
    },

    handleGoSupplier () {
      this.$router.push({ name: 'supplierPresentation', params: { slug: this.offer.supplier.slug } })
    },

    handleGoContactForm () {
      var supplier
      if(this.$route.name === 'offers') {
        supplier = this.offer.supplier
      } else {
        supplier = this.$store.state.suppliers.current
      }
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'Renseignements Achats',
            receiverEmail: supplier.contacts.list[0] ? supplier.contacts.list[0].email : '',
            ccReceiverEmail: supplier.contacts.references ? supplier.contacts.references.email : '',
            receiverName: supplier.contacts.list[0] ? supplier.contacts.list[0].lastname + ' ' +
                supplier.contacts.list[0].firstname : '',
            family: supplier.metadatas.family
          }
        })
    }
  }
}
</script>
