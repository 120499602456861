const SET_PANNEL = (state, status) => {
  state.isPannelOpen = status
}

const SET_DROPDOWN = (state, status) => {
  state.displayDropdown = status
}

const SET_SEARCH_TYPE = (state, value) => {
  state.searchTypeValue = value
}

const SET_LABEL = (state, label) => {
  state.label = label
}

const SET_KEYWORD = (state, value) => {
  state.keyword = value
}

const SET_RESULTS = (state, value) => {
  state.hasResults = value
}

const SET_TAGS_LIST = (state, data) => {
  state.tags = data
}

const SET_SUPPLIERS_LIST = (state, data) => {
  state.suppliers = data
}

const SET_SUBSCRIBERS_LIST = (state, data) => {
  state.subscribers = data
}

const SET_SUPPLIER_PARTNERS_LIST = (state, data) => {
  state.supplierPartners = data
}

const SET_FAMILIES_LIST = (state, data) => {
  state.families = data
}

const SET_ARTICLES_LIST = (state, data) => {
  state.articles = data
}

const SET_CATEGORIES_LIST = (state, data) => {
  state.categories = data
}

export default {
  SET_PANNEL,
  SET_DROPDOWN,
  SET_SEARCH_TYPE,
  SET_LABEL,
  SET_KEYWORD,
  SET_RESULTS,
  SET_TAGS_LIST,
  SET_SUPPLIERS_LIST,
  SET_SUBSCRIBERS_LIST,
  SET_SUPPLIER_PARTNERS_LIST,
  SET_FAMILIES_LIST,
  SET_ARTICLES_LIST,
  SET_CATEGORIES_LIST
}
