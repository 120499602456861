
const SET_ARTICLE_PROCESSING = (state, status) => {
  state.isProcessingArticle = status
}

const SET_ARTICLES_PROCESSING = (state, status) => {
  state.isProcessingList = status
}

const SET_ARTICLES_CATEGORIES = (state, data) => {
  state.categories = data
}

const SET_ARTICLES_FILTERS = (state, data) => {
  state.filters = data
}

const SET_ARTICLES_LIST = (state, data) => {
  state.articleList = data
}

const SET_CURRENT_FORMATION = (state, data) => {
  state.current = data
}

const SET_FORMATIONS_LIST = (state, data) => {
  state.formationList = data
}

const SET_FORMATIONS_CATEGORIES = (state, data) => {
  state.formationCategories = data
}

const SET_FORMATIONS_TARGET = (state, data) => {
  state.targets = data
}

const SET_CURRENT_ARTICLE = (state, data) => {
  state.current = data
}

const SET_LAST_LIST = (state, data) => {
  state.lastList = data
}

export default {
  SET_ARTICLE_PROCESSING,
  SET_ARTICLES_PROCESSING,
  SET_ARTICLES_CATEGORIES,
  SET_ARTICLES_FILTERS,
  SET_ARTICLES_LIST,
  SET_CURRENT_ARTICLE,
  SET_FORMATIONS_LIST,
  SET_FORMATIONS_CATEGORIES,
  SET_FORMATIONS_TARGET,
  SET_CURRENT_FORMATION,
  SET_LAST_LIST
}
