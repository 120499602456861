<template>
  <card
    edged
    left-title>
    <template
      slot="header"
      class="text-left">
      <icon :icon="icon"></icon>
      <h2>{{ title }}</h2>
    </template>

    <contact :contact="contact" />

    <template
      slot="footer">
      <button
        class="btn btn-secondary btn-block"
        @click="handleClick">Prendre contact</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import Contact from './Contact.vue'

export default {
  components: {
    Card,
    Contact
  },

  props: {
    title: {
      type: String,
      default: 'Contact',
      required: false
    },

    icon: {
      type: String,
      default: 'contact',
      required: false
    },

    contact: {
      type: Object,
      default: () => ({
      }),
      required: true
    },

    event: {
      type: Object,
      default: '',
      required: false
    },

    eventDate: {
      type: Object,
      default: '',
      required: false
    }
  },

  methods: {
    handleClick () {
      this.$router.push(
        {
          name: 'form',
          query: {
            receiverEmail: this.contact.email,
            receiverName: this.contact.firstname + ' ' + this.contact.lastname,
            event: this.event,
            eventDate: this.eventDate
          }
        })
    }
  }
}
</script>
