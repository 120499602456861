<template>
  <div
    class="filter-subscribers--list"
  >
    <div
      v-for="(subscriber, i) in subscribers"
      :key="i"
      @click="handleGoSubscriber(subscriber.slug)"
      class="subscriber-subscriber">
      <span class="list-item--name">{{ subscriber.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subscribers: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  methods: {
    handleGoSubscriber (slug) {
      this.$router.push({ name: 'subscriberPresentation', params: { slug }})
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>

<style lang="postcss" scoped>
 .subscriber-subscriber {
   @apply cursor-pointer
 }
</style>
