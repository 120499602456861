<template>
  <div class="login">
    <div class="login-wrapper">
      <div class="login-wrapper--logo">
        <logo />
      </div>

      <form-password-new v-if="$route.query.newPassword"/>

      <form-login
        v-else-if="!resetPassword"
        @switch="resetPassword= true"/>

      <form-password-ask
        v-else
        @switch="resetPassword= false"/>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/commons/Logo.vue'
import FormLogin from './FormLogin.vue'
import FormPasswordAsk from './FormPasswordAsk.vue'
import FormPasswordNew from './FormPasswordNew.vue'

export default {
  name: 'LoginForm',

  components: {
    Logo,
    FormLogin,
    FormPasswordAsk,
    FormPasswordNew
  },

  data: () => ({
    resetPassword: false
  })
}
</script>

<style lang="scss" scoped>
.btn-large {
  @apply text-base;
}

.login {
  @apply flex items-center justify-center;
  background: url('~@/assets/images/default/background.jpg');
  background-size: cover;
  height: 100vh;

  &-wrapper {
    @apply flex items-stretch justify-between;
    @apply bg-white rounded-xl shadow-lg overflow-hidden;
    width: 600px;
    height: 400px;

    &--logo {
      @apply flex items-center justify-center;
      flex: 3;
    }

    &--form {
      @apply p-16;
      flex: 2;
    }
  }
}
</style>
