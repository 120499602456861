<template>
  <div
    v-if="loading"
    class="subcontainer">Chargement...</div>

  <div
    v-else
    class="wrapper"
    :class="backgroundClass">
    <div class="subcontainer">
      <select
        class="btn lg:hidden"
        v-model="selected"
        name="navigation"
        @change="changeRoute">
        <option
          :value="link.value"
          v-for="link in navigationSupplier"
          :key="link.index">
          {{ link.label }}
        </option>
      </select>
      <section-family :title="supplierTitle" />
      <transition
        name="fade"
        mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import navigationSupplier from '@/layout/datas/navigation-supplier.js'
import SectionFamily from '@/components/customs/sheet/SectionFamily'

export default {
  name: 'supplier-view',

  components: {
    SectionFamily
  },

  data: () => ({
    selected: 'supplierPresentation'
  }),

  computed: {
    ...mapState({
      loading: state => state.suppliers.isProcessing,
      supplierTitle: state => state.suppliers.current.metadatas.family
    }),

    backgroundClass () {
      return this.$route.name === 'supplierPresentation' ? 'background_presentation' : 'background'
    },

    navigationSupplier () {
      return navigationSupplier
    }
  },

  methods: {
    fetchDatas () {
      this.$store.dispatch('requestSupplierBySlug', this.$route.params.slug)
      const payload = {
        category: this.$route.params.slug
      }
      this.$store.dispatch('requestDocumentsByCategory', payload)
      this.$store.dispatch('requestOffersBySupplier', this.$route.params.slug)
    },

    changeRoute (event) {
      this.$router.push({ name: event.target.value })
    }
  },

  beforeMount () {
    this.fetchDatas()
  },

  watch: {
    $route (to, from) {
      this.fetchDatas()
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('savePreviousRoute', from)
      vm.$store.dispatch('updateNavigationType', 'supplier')
    })
  }
}
</script>

<style lang="scss" scoped>
.subcontainer-header /deep/ {
  @apply uppercase opacity-75 text-primary-bluer font-bold;
}
</style>
