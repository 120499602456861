<template>
  <Card
    leftTitle
    dense
    edged>
    <template slot="header">
      <h2 class="uppercase">Intervenants formateurs</h2>
    </template>

    <template>
      <ul class="speakers">
        <li
          v-for="speaker in speakers"
          :key="speaker.index"
          class="speakers-item">
          <h3 class="font-bold mb-2">{{ speaker.name }}</h3>
          <p v-html="speaker.description"></p>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  computed: {
    speakers () {
      return this.$store.state.articles.current.speakers
    }
  }
}
</script>

<style lang="scss" scoped>
.speakers {
  &-item {
    @apply py-3;

    + .speakers-item {
      @apply border-t;
    }
  }
}
</style>
