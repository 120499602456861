<template>
  <section class="subcontainer-content">
    <div class="subcontainer-content--title logo-offre">
      <h1>
        <icon icon="offres"></icon>
        Les offres {{ $route.params.slug }}
      </h1>

  <img class="logo-suppliers-thumb"
  :src="logo"
    >

    </div>
    <p class="subcontainer-content--body">
      {{ text }}
    </p>
    <button
      v-if="ondemand"
      @click="handleGoContactForm"
      class="btn btn-small btn-outline--primary"
    >
      Demander une offre sur-mesure
    </button>

    <div class="offers">
      <list-offers />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import ListOffers from '@/components/customs/offers/ListOffers.vue'

export default {
  components: {
    ListOffers
  },

  computed: {
    ...mapState({
      family: state => state.suppliers.current.metadatas.family,
      text: state => state.suppliers.current.offers.body,
      ondemand: state => state.suppliers.current.offers.ondemand,
      logo () {
      return this.$store.state.suppliers.current.medias.thumbnail.source
      }
    })
  },

  methods: {
    handleGoContactForm () {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'Renseignements Achats',
            receiverEmail: this.$store.state.suppliers.current.contacts.references.email,
            receiverName: this.$store.state.suppliers.current.contacts.references.lastname + ' ' +
                          this.$store.state.suppliers.current.contacts.references.firstname,
            family: this.family
          }
        })
    }
  }
}
</script>

.<style lang="scss" scoped>
.wrapper {
  background: theme('colors.gray.200');
}

.subcontainer {
  &-content {
    > * + * {
      @apply mt-3;
    }

    &--title {
      @apply flex items-center justify-start;
      @apply text-xl uppercase font-bold;
    }
  }
}
.logo-suppliers-thumb {
  float: right;
  max-width: 100px;
}
.subcontainer-content--title.logo-offre {
  justify-content: space-between;
}
.subcontainer-content--title.logo-offre h1 {
  display: inline-flex;
  justify-content: space-between;
  .icon {
    margin-right: 6px;
  }
}
</style>
