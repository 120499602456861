<template>
  <div
    class="filter-suppliers--list"
  >
    <div
      v-for="(supplier, i) in suppliers"
      :key="i"
      @click="handleGoSupplier(supplier.slug)"
      class="supplier-supplier">
      <span class="list-item--name">{{ supplier.title }}</span>
      <span
        v-if="supplier.keyword"
        class="list-item--category"
      >{{ supplier.keyword }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suppliers: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  methods: {
    handleGoSupplier (slug) {
      this.$router.push(`/fournisseur/${slug}`)
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>

<style lang="postcss" scoped>
 .supplier-supplier {
   @apply cursor-pointer
 }
</style>
