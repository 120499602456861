import axios from '@/axios'
import Cookies from 'js-cookie'
import router from '@/router'

/**
 * Lors de la connection, on envoie les credentials afin d'obtenir un token
 * d'accès qu'on va paramétrer sur toutes les requêtes et sauvegarder en cookie
 * pour les prochaines navigations.
 *
 * Si la requête a bien fonctionnée, on redispatch ensuite une seconde action
 * qui consiste à récupérer les infos de l'user grâce à son Id.
 * @param {payload.username} String identifiant de l'user
 * @param {payload.password} String mot de passe de l'user
 */
const requestToken = async ({ commit, dispatch }, payload) => {
  // console.log('[Vuex] requestToken action')
  commit('SET_PROCESSING', true)

  const newPayload = new FormData()

  newPayload.append('grant_type', 'password')
  newPayload.append('client_id', process.env.VUE_APP_CLIENT_ID)
  newPayload.append('client_secret', process.env.VUE_APP_CLIENT_SECRET)
  newPayload.append('username', payload.username)
  newPayload.append('password', payload.password)

  try {
    const auth = await axios.post('/oauth/token', newPayload)
    const xcsrf = await axios.get('/session/token')

    commit('SET_REFRESH_TOKEN', auth.data.refresh_token)
    commit('SET_ACCESS_TOKEN', auth.data.access_token)
    commit('SET_XCSRF_TOKEN', xcsrf.data)
    commit('CONNECT_USER', true)

    // On crée un cookie qui nous permettra de redemander le token
    Cookies.set('mercurial_refresh_token', auth.data.refresh_token, {
      expires: 365
    })

    // On crée un cookie qui nous permettra de définir le Bearer token
    Cookies.set('mercurial_access_token', auth.data.access_token, {
      expires: 365
    })

    Cookies.set('mercurial_xcsrf_token', xcsrf.data, {
      expires: 365
    })

    const accountInformations = await axios.get(`${process.env.VUE_APP_API_VERSION}/account-informations`, {
      headers: {
        'Authorization': 'Bearer ' + auth.data.access_token
      }
    })

    const userData = { ...auth.data.user, ...accountInformations.data }

    commit('SET_USER', userData)
    // On stock les infos de l'user dans un Cookie
    localStorage.setItem('mercurial_user_data', JSON.stringify(userData), {
      expires: 365
    })

    const redirectPath = router.currentRoute.query.redirect || '/'
    router.push(redirectPath)

    // On requête nos infos personnelles à l'API
    // dispatch('requestUserDatasLogin')

    // Paramétrage du moteur de recherche en fonction de l'user
    dispatch('engine/initiateEngineParams', payload, { root: true })
  } catch (err) {
    commit('SET_ERROR_MESSAGE', err.response.data.error)
  } finally {
    commit('SET_PROCESSING', false)
  }
}

/**
 * On requête les infos de l'user au login
 * @param {*} param0
 */
/* const requestUserDatasLogin = async ({ commit }) => {
  // console.log('[Vuex] requestUserDatas action')
  commit('SET_PROCESSING', true)
  try {
    const user = await axios.get(`${process.env.VUE_APP_API_VERSION}/profil`)

    // On stock les infos de l'user au login dans un Cookie car on a besoin de connaître
    // son rôle dans le cas d'un refresh ou d'une future navigation.
    Cookies.set('mercurial_user_data', JSON.stringify(user.data), {
      expires: 365
    })

    commit('SET_USER', user.data)
    commit('CONNECT_USER', true)

    const redirectPath = router.currentRoute.query.redirect || '/'
    router.push(redirectPath)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_PROCESSING', false)
  }
} */

/**
 * On requête les infos de l'user
 * @param {*} param0
 */
const requestUserDatas = async ({ commit }) => {
  try {
    const user = await axios.get(`${process.env.VUE_APP_API_VERSION}/profil`)
    commit('SET_USER_PROFIL', user.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

/**
 * Action qui permet de demander un nouvel access token en fournissant le
 * refresh token (qui a une durée de vie plus étendue) :
 * @param {String} token
 */
const refreshAccessToken = async ({ commit, dispatch }, token) => {
  // console.log('[Vuex] refreshAccessToken action')
  try {
    const newPayload = new FormData()

    newPayload.append('grant_type', 'refresh_token')
    newPayload.append('client_id', process.env.VUE_APP_CLIENT_ID)
    newPayload.append('client_secret', process.env.VUE_APP_CLIENT_SECRET)
    newPayload.append('refresh_token', token)

    const auth = await axios.post('/oauth/token', newPayload)
    commit('SET_REFRESH_TOKEN', auth.data.refresh_token)
    commit('SET_ACCESS_TOKEN', auth.data.access_token)
  } catch (err) {
    // À la moindre erreur, on déconnecte l'user
    // console.log('[Axios] Refresh Token invalid, user has been logged out')
    dispatch('disconnectUser')
    commit('SET_ERROR_MESSAGE', 'Session expirée, veuillez vous reconnecter')
  }
}

/**
 * Permet de se déconnecter de la plateforme, ce qui supprime toutes les données
 * enregistrées dans le front et sur l'application.
 */
const disconnectUser = async ({ commit }) => {
  // console.log('[Vuex] disconnectUser action')
  try {
    // Le store vuex est vidé
    commit('CONNECT_USER', false)
    commit('SET_ACCESS_TOKEN', null)
    commit('SET_REFRESH_TOKEN', null)
    commit('SET_XCSRF_TOKEN', null)
    commit('SET_USER', {
      roles: [
        'guest'
      ]
    })

    // Les cookies sont effacés
    Object.keys(Cookies.get()).map(cookieName => Cookies.remove(cookieName))

    // L'user est renvoyé sur la page de login
    router.push({ name: 'login' })
  } catch (err) {
    console.error(err)
    // Notification de l'erreur
  } finally {
    // Effet quoiqu'il arrive
  }
}

/**
 * Action permettant d'envoyer un email à l'utilisateur afin de lui permettre de
 * réinitialiser son mot de passe.
 * @param {String} email
 */
const requestNewPasswordMail = async ({ commit }, email) => {
  commit('SET_PROCESSING', true)

  try {
    await axios.post('/user/lost-password?_format=json', { mail: email })
    commit('SET_EMAIL_SENDED', true)
  } catch (err) {
    commit('SET_ERROR_MESSAGE', err.response.data.message)
  } finally {
    commit('SET_PROCESSING', false)
  }
}

/**
 * Action permettant de paramétrer le nouveau mot de passe
 * @param {String} email
 */
const resetPassword = async ({ commit }, payload) => {
  commit('SET_PROCESSING', true)
  try {
    await axios.post('/user/lost-password-reset?_format=json', payload)

    // L'user est renvoyé sur la page de login
    router.push({ name: 'login' })
  } catch (err) {
    commit('SET_ERROR_MESSAGE', err.response.data.message)
  } finally {
    commit('SET_PROCESSING', false)
  }
}

const changePassword = async ({ commit, state }, payload) => {
  try {
    await axios.patch(`/user/${ state.user.id }?_format=json`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': state.xcsrf_token
      }
    })
  } catch (err) {
    if(err.response.status === 422) {
      commit('SET_ERROR_MESSAGE', 'Mot de passe actuel incorrect')
    } else {
      commit('SET_ERROR_MESSAGE', err.response.data.message)
    }
  } finally {
  }
}

const closeErrorMessage = async ({ commit }) => {
  commit('SET_ERROR_MESSAGE', null)
}

const closeEmailSended = async ({ commit }) => {
  commit('SET_EMAIL_SENDED', false)
}

export default {
  requestToken,
  // requestUserDatasLogin,
  requestUserDatas,
  refreshAccessToken,
  disconnectUser,
  requestNewPasswordMail,
  resetPassword,
  changePassword,
  closeErrorMessage,
  closeEmailSended
}
