<template>
  <div v-if="items">
    <div
      ref="categoriesSwiper"
      v-if="items"
      v-swiper:categoriesSwiper="categoriesOptions"
      :key="categoriesOptions.breakpoints"
      :auto-update="true"
      :auto-destroy="true"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
      class="list_categories">
      <ul class="swiper-wrapper">
        <list-categories-item
          class="swiper-slide"
          v-for="item in items"
          :key="item.i"
          :label="item" />
      </ul>
    </div>
  </div>
</template>

<script>
import ListCategoriesItem from '@/components/customs/categories/ListCategoriesItem'

export default {
  components: {
    ListCategoriesItem
  },

  data: () => ({
    categoriesOptions: {
      slidesPerView: 'auto',
      slidesPerColumn: 2,
      spaceBetween: 10,
      breakpoints: ''
    },
    windowWidth: window.innerWidth
  }),

  computed: {
    items () {
      return this.$store.state.categories.list
    },

    swiper () {
      return this.$refs.categoriesSwiper.$swiper
    }
  },

  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },

    responsiveSwiperConstructor () {
      this.windowWidth > 1440
        ? this.categoriesSwiper.destroy()
        : this.categoriesSwiper.init()
    }
  },

  watch: {
    windowWidth (newWidth, oldWidth) {
      this.responsiveSwiperConstructor()
    }
  },

  beforeMount () {
    this.$store.dispatch('flushCategories')
    if (this.$store.getters.userRole === 'subscriber') {
      this.$store.dispatch('requestCategories')
    }
  },

  mounted () {
    this.responsiveSwiperConstructor()
    
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
