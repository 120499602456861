<template>
  <section class="page-drawer--navigation">
    <div
      class="return"
      @click="handleGoBack">
      <div class="icon-text mx-8">
        <icon
          class="mr-0 lg:mr-4"
          icon="retour"
          size="lg"></icon>
        <span class="uppercase text-sm hidden lg:block">Retour</span>
      </div>
    </div>

    <ul class="list">
      <li
        v-for="(item, index) in drawerItems"
        :key="index"
        :class="{ 'active' : $route.name === item.value}"
        class="list-item"
        @click="handleRedirection(item.link)">
        <icon
          class="icon"
          :icon="item.icon"></icon>
        <span
          class="label">
          {{ item.label }}
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import navigationSupplier from './../../datas/navigation-supplier.js'
import navigationSubscriber from './../../datas/navigation-subscriber.js'

export default {
  computed: {
    drawerItems () {
      var items = navigationSubscriber
      if (this.$store.state.navigationType === 'supplier') {
        items = navigationSupplier
      }
      items = items.filter(item => {
        if ((item.value === 'supplierDocuments' || item.value === 'subscriberDocuments') && !this.documents) {
          return false
        }
        if (item.value === 'subscriberCompanies' && !this.affiliates) {
          return false
        }
        return true
      })
      return items
    },

    previousRoute () {
      return this.$store.state.previousRoute
    },

    documents () {
      return this.$store.state.documents.list.length > 0
    },

    offers () {
      return this.$store.state.offers.list.rows ? this.$store.state.offers.list.rows.length > 0 : false
    },

    affiliates () {
      return this.$store.state.subscribers.current.metadatas.affiliates && this.$store.state.subscribers.current.metadatas.affiliates.length > 0
    }
  },

  methods: {
    handleRedirection (link) {
      const parentRoute = this.$store.state.navigationType === 'supplier'
        ? 'fournisseur'
        : 'adherent'

      link !== ''
        ? this.$router.push(`/${parentRoute}/${this.$route.params.slug}/${link}`).catch(() => {})
        : this.$router.push(`/${parentRoute}/${this.$route.params.slug}`).catch(() => {})
    },

    handleGoBack () {
      if (this.previousRoute.name) {
        history.back()
      }
      const parentRoute = this.$store.state.navigationType === 'supplier'
        ? 'suppliers'
        : 'subscribers'
        
      this.$router.push({ name: parentRoute })
    }
  }
}
</script>
