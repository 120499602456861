<template>
    <modal
      :visible="isOpen"
      :close-action="null" 
      :is-shadow-closable="false"
    >
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Définir le statut du collaborateur
            </span>
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>
  
        <div class="p-10 text-center">
        Llorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus quam risus, commodo sollicitudin lacus luctus ut. Nullam nec ante ut massa bibendum accumsan in nec enim.
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center']">
        <div v-for="option in options"
          :key="option.id"
          :class="['py-1', 'px-5', 'flex', 'flex-row', 'items-center']"
        >
          <input
            :key="option.id"
            :value="option.id"
            :id="option.id"
            v-model="userPermission"
            name="permissions"
            type="radio"
            :class="'cursor-pointer'"
          />
          <label :for="option.id" :class="['px-1', 'cursor-pointer']">
            {{ option.label }}
          </label>
        </div>
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
        <button
        :class="['btn', 'btn-outline--primary', 'mx-5']"
        @click="handleClose"
      >
        Annuler
      </button>
      <button
        :class="['btn', 'btn-secondary', 'mx-5']"
        @click="submitCollaborator"
      >
        Valider
      </button>
      </div>
      </div>
    </modal>
  </template>
    
  <script>
  import Modal from '@/components/commons/Modal'
  
  export default {
    components: {
      Modal,
    },

    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      collaborator: {
        type:  Object | null,
        default: null,
      },
    },
  
    data: () => ({
      userPermission: null,
    }),

    watch: {
      collaborator(newVal) {
        this.userPermission = newVal.permission
      }
    },

    computed: {
      options () {
        return [
          {
            id: 'read',
            label: 'Droit de lecture'
          },
          {
            id: 'write',
            label: 'Droit d\'écriture'
          },
        ]
      }
    },

    beforeMount(){
      this.userPermission = this.options?.find((opt) => opt.id === 'read')?.id
    },
  
    methods: {
      /**
       * Close action from Pursuit
       * @return {void}
       */
      handleClose(){
        this.$emit('closeModalCollaborator')
      },

      /**
       * Submit Collaborator method
       * @return {void}
       */
      submitCollaborator(){
        this.$emit('submit', this.collaborator.id, this.userPermission)
        
        this.handleClose()
      }
    }
  }
  </script>