import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    isProcessingArticle: false,
    isProcessingList: false,
    categories: [],
    filters: {},
    formationList: [],
    formationCategories: {},
    articleList: [],
    lastList: [],
    current: {}
  },

  actions,
  mutations,
  getters
})
