import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const getDefaultState = () => {
  return {
    loading: false,
    listing: [],
    detail: {
      entity: {},
      filters: {
        startDate: null,
        endDate: null,
        currency: null,
      }
    },
    families: [],
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

export default ({
  namespaced: true,

  state: getDefaultState(),

  actions,
  mutations : { ...mutations, RESET_STATE },
  getters
})
