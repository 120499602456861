import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    isProcessing: false,
    current: {},
    list: [],
    filters: {},
    banners: []
  },

  actions,
  mutations,
  getters
})
