<template>
  <img
    class="icon"
    :class="iconSize"
    :src="iconSource">
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
      required: true
    },

    size: {
      type: String,
      default: 'md',
      required: false
    }
  },

  computed: {
    iconSource () {
      return require(`@/assets/images/icons/${this.icon}.svg`)
    },

    iconSize () {
      return `icon-${this.size}`
    }
  }
}
</script>
