<template>
  <section
    v-if="otherFamilies && otherFamilies.length > 0"
    class="page-drawer--section">
    <h3 class="title">Autres familles d'achat</h3>
    <div class="content">
      <div
        class="filter-item"
        v-for="(item, index) in otherFamilies"
        :key="index"
        @click="handleClick(item.categSlug, item.slug)">
        <icon
          class="filter-item--icon"
          icon="fleche-orange"
          size=sm></icon>
        <span class="filter-item--title">
          {{ item.title }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    otherFamilies () {
      return this.$store.state.suppliers.filters.relatedFamilies
    }
  },

  methods: {
    /**
     * Au click sur l'un des éléments, on est redirigé vers la page catégorie
     * correspondant à la famille d'achat, avec le filtre de ladite famile
     * préselectionné, il faut donc envoyer 2 paramètres :
     * @params {slug} String Correspond au slug de la catégorie (page : _slug)
     * @params {family} String correspond au slug de la famille (pour la query)
     */
    handleClick (slug, family) {
      this.$router.push(`/fournisseurs/${slug}?famille=${family}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-item {
  @apply flex items-start cursor-pointer mb-3;

  &--icon {
    @apply mt-1;
  }

  &--title {
    @apply font-bold uppercase pl-1;
  }
}
</style>
