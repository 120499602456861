<template>
  <div class="login-wrapper--form">
    <h1 class="font-bold text-xl uppercase text-center mb-6">Mot de passe oublié</h1>
    <p
        v-if="emailSended"
        class="message"
    >
      Un email de réinitialisation a été envoyé
    </p>
    <p
        v-if="validationError"
        class="message error"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="dispatchError"
        class="message error"
    >
      {{ dispatchError }}
    </p>
    <form
      action="">
      <fieldset class="flex flex-col">
        <label
          for="email"
          class="form-label">Adresse mail</label>
        <input
          v-model="form.email"
          placeholder="adresse@mail.com"
          type="email"
          class="form-input">
      </fieldset>
      <button
        class="btn btn-primary btn-large btn-block mt-3"
        :class="{ 'disabled' : loading}"
        @click.prevent="handleSubmit">
        Envoyer
      </button>
      <a
        class="block mt-3 cursor-pointer"
        @click="handleSwitchForm">
        Retour
      </a>
    </form>
  </div>
</template>

<script>
import Alert from '@/components/commons/Alert'

export default {
  components: {
    Alert
  },

  data: () => ({
    validationError: false,
    form: {
      email: ''
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    },

    dispatchError () {
      return this.$store.state.authentication.errorMessage
    },

    emailSended () {
      return this.$store.state.authentication.emailSended
    }
  },

  methods: {
    handleSubmit () {
      this.$store.dispatch('closeErrorMessage')
      this.$store.dispatch('closeEmailSended')
      this.validationError = false;
      if(!this.form.email) {
        this.validationError = 'Email requis'
        return
      }
      this.$store.dispatch('requestNewPasswordMail', this.form.email)
    },

    handleSwitchForm () {
      this.$store.dispatch('closeErrorMessage')
      this.$emit('switch')
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  @apply p-3 mb-5 bg-green-800 text-white font-bold rounded;

  &.error {
    @apply bg-red-700;
  }
}

</style>

