const subscriberTabs = [
  {
    value: 'month',
    label: 'Vos CA Mercurial déclarés par mois'
  },
  {
    value: 'year',
    label: 'Vos CA Mercurial déclarés par année'
  },
  {
    value: 'region',
    label: 'Vos CA Mercurial déclarés par région'
  },
  {
    value: 'subscriber',
    label: 'Vos CA Mercurial déclarés par adhérent'
  // },
  // {
  //   value: 'activity',
  //   label: 'Vos CA Mercurial déclarés par secteur d\'activités adhérent'
  }
]

export default subscriberTabs
