<template>
  <ShareNetwork
    network="linkedin"
    :url="shareUrl"
    title="Mercurial"
  >
    <card
      class="my-3"
      edged>
      <div class="flex flex-col items-center ">
        <span class="uppercase font-bold text-lg">Partagez cet article sur</span>
        <img
          class="mt-3"
          src="@/assets/images/icons/linkedin.svg"
          alt="Logo de LinkedIn"
          width="110">
      </div>
    </card>
  </ShareNetwork>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  computed: {
    shareUrl () {
      return window.location.href
    }
  }
}
</script>

<style lang="scss" scoped>
.share-network-linkedin {
  @apply text-black cursor-pointer no-underline;

  .card {
    @apply transition duration-150 ease-in-out;
  }

  &:hover .card {
    @apply shadow-md;
    transform: scale(1.02)
  }
}
</style>
