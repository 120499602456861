<template>
  <section class="page-drawer--section" @click.stop>
    <FiltersTitleHandler :always-closable="true"/>

    <hr class="spacer my-6" />

    <Calendar
      v-model="startDate"
      dateFormat="dd/mm/yy"
      show-icon
      placeholder="Date de début"
      class="w-full"
    />
    <Calendar
      v-model="endDate"
      dateFormat="dd/mm/yy"
      show-icon
      placeholder="Date de fin"
      class="w-full mt-6"
    />

    <div class="text-center">
      <button type="submit" class="btn btn-block btn-secondary" @click="onSubmit">Valider</button>
    </div>
  </section>
</template>

<script>
import FiltersTitleHandler from '../drawer/FiltersTitleHandler';

export default {
  components: {
    FiltersTitleHandler
  },

  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },

  computed: {
    filters() {
      return this.$store.state.indices.detail.filters
    }
  },

  watch: {
    'filters.startDate'(newVal) {
      this.startDate = newVal
    },
    'filters.endDate'(newVal) {
      this.endDate = newVal
    },
  },

  beforeMount() {
    this.startDate = this.filters.startDate
    this.endDate = this.filters.endDate
  },

  methods: {
    handleCloseDrawer () {
      this.$store.dispatch('drawerStatus', false)
    },
    async onSubmit() {
      this.$store.commit('indices/SET_DETAIL_FILTERS', {
        ...this.filters,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.handleCloseDrawer()
    }
  },
}
</script>

<style lang="scss" scoped>
button[type="submit"] {
  max-width: 195px;
  margin-top: 30px;
}

::v-deep {
  .p-datepicker-trigger, .p-datepicker-trigger:enabled:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  .p-datepicker table td { padding: 0; }
}
</style>
