<template>
  <div
    v-if="isLoading"
    class="articles">
    Chargement...
  </div>

  <div
    v-else-if="articles.length === 0"
    class="articles">
    Aucun article
  </div>

  <div
    v-else
    class="articles">
    <list-articles-item
      v-for="(article, index) in articles"
      :key="index"
      :content="article"
      :subinfos="!formations" />
  </div>
</template>

<script>
import ListArticlesItem from '@/components/customs/articles/ListArticlesItem.vue'

export default {
  components: {
    ListArticlesItem
  },

  props: {
    // Permet d'afficher un nombre X des derniers articles
    last: {
      type: Boolean,
      default: false,
      required: false
    },

    // Permet de requêter des formations à afficher dans le composant
    formations: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    isLoading () {
      return this.$store.state.articles.isProcessingList
    },

    articles () {
      if(this.last) {
        return this.$store.state.articles.lastList.rows
      } else if(this.formations === true) {
        return this.$store.state.articles.formationList.rows
      } else {
        return this.$store.state.articles.articleList.rows
      }
    },

    // Dans le cas des derniers articles, on peut avoir besoin de ne montrer
    // qu'un certain nombre X (this.last) d'articles. Utile dans la page
    // d'article par exemple.
    /*amountOfArticlesDisplayed () {
      return this.last
        ? this.articles.slice(0, this.last)
        : this.articles
    }*/
  },

  methods: {
    /**
     * On requête les articles en fonction du type d'article
     */
    fetchArticles () {
      if (this.$route.query.filtre === 'formations' ||
          this.formations === true) {
        const payload = {
          category: this.$route.query.category,
          page: this.$route.query.page
        }
        this.$store.dispatch('requestFormations', payload)
      } else {
        const payload = {
          id: this.$route.query.filtre,
          page: this.$route.query.page
        }

        this.$store.dispatch('requestArticlesByParams', payload)
      }
    }
  },

  watch: {
    $route (to, from) {
      this.fetchArticles()
    }
  },

  beforeMount () {
    // On requête la liste des articles avec les params
    this.fetchArticles()
  }
}
</script>
