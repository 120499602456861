<template>
  <div
    tabindex="0"
    id="type"
    :value="$store.state.engine.label"
    name="type"
    class="form_engine-select"
    @click.self="isOpen = !isOpen"
    v-click-outside="handleClose">
    <span class="uppercase pointer-events-none pr-2">
      {{ $store.state.engine.label }}
    </span>

    <ul
      v-if="isOpen"
      class="form_engine-select--dropdown">
      <template v-for="(option, index) in options">
        <li
          tabindex="0"
          class="form_engine-select--option"
          v-if="hasPermission(option)"
          :key="index"
          @click="selectOption(option)">
          {{ option.label }}
        </li>
      </template>
    </ul>

    <icon
      :class="{'transform rotate-180' : isOpen}"
      class="form-select--icon"
      icon="fleche-bas"
      size="xs" />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

// Import de la mixin qui vérifie les permissions de liens
import checkPermissionMatch from '@/mixins/checkPermissionMatch'

// Données du dropdown pour les permidssions
import dropdownOptions from '@/layout/datas/search-dropdown.js'

export default {
  mixins: [checkPermissionMatch],

  data: () => ({
    isOpen: false
  }),

  computed: {
    options () {
      return dropdownOptions
    }
  },

  methods: {
    selectOption (option) {
      this.$store.dispatch('engine/setTypeOfValueToSearch', option.value)
      this.$store.dispatch('engine/setLabelToDisplayInSelect', option.label)
      this.isOpen = false

      this.$emit('handler', option.value)
    },

    handleClose () {
      this.isOpen = false
    }
  },

  beforeMount () {
    this.$store.dispatch('engine/initiateEngineParams')
  },

  directives: {
    ClickOutside
  }
}
</script>
