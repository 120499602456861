<template>
  <div
    class="card"
    :class="cardClasses">
    <!-- Le header de la card accueil un titre et accessoirement une icone -->
    <header
      v-if="$slots.header"
      :class="headerClasses">
      <slot name="header" />
    </header>

    <hr
      v-if="$slots.header"
      class="spacer">

    <!-- N'importe quel contenu peut-être inséré par défaut dans cette balise -->
    <article
      class="card-content"
      :class="contentClasses">
      <slot />
    </article>

    <hr
      v-if="$slots.footer"
      class="spacer">

    <!-- Le footer est prévu pour n'accueillir que des boutons d'action -->
    <footer
      v-if="$slots.footer"
      class="card-footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
      required: false
    },

    edged: {
      type: Boolean,
      default: false,
      required: false
    },

    flexed: {
      type: Boolean,
      default: false,
      required: false
    },

    dense: {
      type: Boolean,
      default: false,
      required: false
    },

    background: {
      type: String,
      default: '',
      required: false
    },

    hoverable: {
      type: Boolean,
      default: false,
      required: false
    },

    leftTitle: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    cardClasses () {
      return [
        this.flat ? 'shadow-none' : 'shadow-xs',
        this.hoverable ? 'card-hoverable' : ''
      ]
    },

    contentClasses () {
      return [
        this.edged ? 'p-4' : 'p-0',
        this.flexed ? 'flex items-center justify-center' : 'block',
        this.background ? this.background : null
      ]
    },

    headerClasses () {
      return [
        'card-header',
        'flex',
        'flex-col',
        'md:flex-row',
        'items-center',
        this.dense ? 'p-3' : 'p-6',
        this.leftTitle ? 'justify-start' : 'justify-center',
      ]
    }
  }
}
</script>
