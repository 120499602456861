<template>
  <div
    class="subcontainer-content">
    <section-infos
      :title="title"
      :body="body"
      :thumbnail="thumbnail"
      :address="address"
      :affiliate="affiliate"
      :siret="siret"
      :collaborator="collaborator"
      :favorite="favorite"
      :entityId="entityId"
      :entityType="entityType"
      :phone="phone"
      :variant="variant"
    />

    <section-metas
      :contacts="contacts"
      :referenceYear="referenceYear"
      :updatedAt="updatedAt"
      :metadatas="metadatas"
      :variant="variant"/>

    <section-stats
      :linkedin="linkedin"
      :website="website"
      :video="video"
      :document="document"
      :metadatas="metadatas" />

    <section-labels :labels="labels" />

    <section-certifs :certifs="certifs"/>

    <section-tags :tags="tags" />

    <section-gallery :slides="slides" />

    <section-affiliates :affiliates="affiliates" />
  </div>
</template>

<script>
import SectionInfos from '@/components/customs/sheet/SectionInfos'
import SectionMetas from '@/components/customs/sheet/SectionMetas'
import SectionStats from '@/components/customs/sheet/SectionStats'
import SectionLabels from '@/components/customs/sheet/SectionLabels'
import SectionCertifs from '@/components/customs/sheet/SectionCertifs'
import SectionTags from '@/components/customs/sheet/SectionTags'
import SectionGallery from '@/components/customs/sheet/SectionGallery'
import SectionAffiliates from '@/components/customs/sheet/SectionAffiliates'

export default {
  name: 'presentation-sheet',

  components: {
    SectionAffiliates,
    SectionInfos,
    SectionMetas,
    SectionStats,
    SectionLabels,
    SectionCertifs,
    SectionTags,
    SectionGallery
  },

  props: {
    variant: {
      type: String,
      default: 'subscriber',
      required: true
    }
  },

  computed: {
    title () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.title
        : this.$store.state.subscribers.current.title
    },

    body () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.body
        : this.$store.state.subscribers.current.body
    },

    thumbnail () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.medias.thumbnail
        : this.$store.state.subscribers.current.medias.thumbnail
    },

    address () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.address
        : this.$store.state.subscribers.current.address
    },

    phone () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.metadatas.phone
        : this.$store.state.subscribers.current.metadatas.phone
    },

    siret () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.metadatas.siret
        : this.$store.state.subscribers.current.metadatas.siret
    },

    affiliates () {
      return this.$store.state.subscribers.current.metadatas.affiliates
    },

    affiliate () {
      return this.$store.state.subscribers.current.metadatas.affiliate
    },

    contacts () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.contacts
        : this.$store.state.subscribers.current.contacts
    },

    metadatas () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.metadatas
        : this.$store.state.subscribers.current.metadatas
    },

    referenceYear () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.metadatas.referenceYear
        : this.$store.state.subscribers.current.metadatas.referenceYear
    },

    updatedAt () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.updatedAt
        : this.$store.state.subscribers.current.updatedAt
    },

    linkedin () {
      return this.variant === 'supplier'
      ? this.$store.state.suppliers.current.metadatas.linkedin
      : this.$store.state.subscribers.current.metadatas.linkedin
    },

    website () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.metadatas.website
        : this.$store.state.subscribers.current.metadatas.website
    },

    video () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.medias.video
        : this.$store.state.subscribers.current.medias.video
    },

    document () {
      return this.$store.state.subscribers.current.metadatas.document
    },

    labels () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.labels
        : this.$store.state.subscribers.current.labels
    },

    certifs () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.certifications
        : this.$store.state.subscribers.current.certifications
    },

    tags () {
      return this.$store.state.suppliers.current.tags
    },

    slides () {
      return this.variant === 'supplier'
        ? this.$store.state.suppliers.current.medias.gallery
        : this.$store.state.subscribers.current.medias.gallery
    },

    collaborator () {
      return this.variant === 'supplier'
      ? this.$store.state.suppliers.current.metadatas.collaborator
      : this.$store.state.subscribers.current.metadatas.collaborator
    },

    favorite () {
      return this.variant === 'supplier'
          ? this.$store.state.suppliers.current.metadatas.favorite
          : this.$store.state.subscribers.current.metadatas.favorite
    },

    entityId () {
      return this.variant === 'supplier'
          ? this.$store.state.suppliers.current.id
          : this.$store.state.subscribers.current.id
    },

    entityType () {
      return this.variant === 'supplier'
          ? this.$store.state.suppliers.current.type
          : this.$store.state.subscribers.current.type
    },
  }
}
</script>
