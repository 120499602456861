const dropdownOptions = [
  {
    label: 'Fournisseurs',
    value: 'suppliers',
    hiddenFor: [
      'supplier'
    ]
  },
  {
    label: 'Adhérents',
    value: 'subscribers'
  },
  {
    label: 'Offres négociées',
    value: 'offers',
    hiddenFor: [
      'supplier'
    ]
  },
  {
    label: 'Documents',
    value: 'documents',
    hiddenFor: [
      'supplier'
    ]
  }
]

export default dropdownOptions
