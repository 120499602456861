<template>
  <div class="card_document">
    <div class="card_document-infos">
      <div class="card_document-infos--icon">
        <icon
          :icon="documentFormat"
          size="xl"/>
      </div>

      <div
        class="card_document-infos--header">
        <span class="block text-primary opacity-50 font-bold uppercase text-lg">
          {{ document.category }}
        </span>
        <span class="block">
          {{ document.affiliate }}
        </span>
        <h2 class="font-bold text-xl">
          {{ document.title }}
        </h2>
      </div>
    </div>

    <div
      class="card_document-body"
      v-html="document.body">
    </div>

    <div class="card_document-actions">
      <a
        v-if="document.link"
        class="btn btn-dark no-underline"
        :href="document.link"
        target="_blank"
        download>
        Télécharger
      </a>
      <Modal v-if="document.preview">
        <button
          class="btn btn-outline--primary uppercase no-underline"
          slot="activator"
        >
          Voir l'aperçu
        </button>
        <pdf
          :src="document.preview"
          :key="currentPage"
          :page="currentPage"
          @num-pages="pageCount = $event"
        >
        </pdf>
        <div class="pagination">
          <div
            v-if="currentPage > 1"
            @click="prevPdfPage">
            <icon
              :icon="'slide-drt'"
              size="lg"
            />
          </div>
          <div
            v-if="currentPage < pageCount"
            @click="nextPdfPage">
            <icon
              :icon="'slide-gch'"
              size="lg"
            />
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>

import pdf from 'vue-pdf'
import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal,
    pdf
  },

  data: () => {
    return {
      currentPage: 1,
      pageCount: 0
    }
  },

  props: {
    document: {
      type: Object,
      default: () => ({

      }),
      required: true
    }
  },

  computed: {
    /**
     * Si pas de format retourné par le BO, mettre une icone pdf par défault.
     */
    documentFormat () {
      return this.document.format ? this.document.format : 'pdf'
    }
  },

  methods: {
    nextPdfPage () {
      this.currentPage++
    },

    prevPdfPage () {
      this.currentPage--
    },

    downloadDocument(slug) {
      this.$router.push({ name: 'DocumentFile', params: { slug }});
    }
  }
}
</script>
