import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    isProcessing: false,
    list: [],
    pageCount: '1',
    filters: {
      default: {
        label: 'Trier par',
        items: []
      }
    }
  },

  actions,
  mutations,
  getters
})
