<template>
  <section class="subcontainer-content">

    <div class="subcontainer-content--title">
      <icon icon="documents"></icon>
      <h1>Fiches experts fournisseur</h1>
    </div>

    <div
      v-if="expertPages && expertPages.length > 0"
      class="mt-8 p-8 bg-white rounded shadow-xs">
      <div class="border-l border-black">
        <div
          v-for="(expertPage, index) in expertPages"
          :key="index"
          class="flex items-center p-3"
        >
          {{ expertPage.title }}
          <button
            @click="handleClick(expertPage.slug)"
            class="btn btn-primary btn-small ml-5"
          >
            Voir la fiche
          </button>
        </div>
      </div>
    </div>
    <div v-else>Aucune fiche expert</div>

  </section>
</template>

<script>

export default {
  components: {
  },

  data: () => ({

  }),

  computed: {
    isLoading () {
      return this.$store.state.documents.isProcessing
    },

    expertPages () {
      return this.$store.state.suppliers.current.expertPages
    }
  },

  methods: {
    handleClick (slug) {
      this.$router.push(`/page/${slug}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background: theme('colors.gray.200');
  }

  .subcontainer {
    &-content {
      > * + * {
        @apply mt-3;
      }
    }
  }
</style>
