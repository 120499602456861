<script>
// Codebase took from this blogpost :
// https://medium.com/@adrianjost/how-to-render-an-html-string-with-custom-vue-components-2c9989a81a1

// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from 'vue/dist/vue.esm'

export default {
  props: {
    html: {
      type: String,
      default: ''
    }
  },
  data () {
    return { templateRender: undefined }
  },
  watch: {
    html (to) {
      this.updateRender()
    }
  },
  created () {
    this.updateRender()
  },
  methods: {
    updateRender () {
      const compiled = VueWithCompiler.compile(this.html)
      this.templateRender = compiled.render
      this.$options.staticRenderFns = []
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction)
      }
    }
  },
  render () {
    return this.templateRender()
  }
}
</script>
