<template>
  <div class="login-wrapper--form">
    <h1 class="font-bold text-xl uppercase text-center mb-6">Nouveau mot de passe</h1>

    <p
        v-if="validationError"
        class="error"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="dispatchError"
        class="error"
    >
      {{ dispatchError }}
    </p>

    <form action="">
      <fieldset class="flex flex-col">
        <label
          for="email"
          class="form-label">Nouveau mot de passe</label>
        <input
          v-model="form.password"
          placeholder="password"
          type="password"
          class="form-input">
      </fieldset>
      <button
        class="btn btn-primary btn-large btn-block mt-3"
        :class="{ 'disabled' : loading}"
        @click.prevent="handleSubmit">
        Enregistrer
      </button>
    </form>
  </div>
</template>

<script>
import Alert from '@/components/commons/Alert'

export default {
  components: {
    Alert
  },

  data: () => ({
    validationError: false,
    form: {
      password: ''
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    },

    dispatchError () {
      return this.$store.state.authentication.errorMessage
    }
  },

  methods: {
    handleSubmit () {
      this.$store.dispatch('closeErrorMessage')
      this.validationError = false;
      if(!this.form.password) {
        this.validationError = 'Mot de passe requis'
        return
      }
      this.$store.dispatch('resetPassword', {
        name: this.$route.query.user,
        temp_pass: this.$route.query.token,
        new_pass: this.form.password
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  @apply p-3 mb-5 bg-red-700 text-white font-bold rounded
}
</style>
