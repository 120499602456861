const SET_CATEGORIES_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_CATEGORIES = (state, data) => {
  state.list = data
}

const FLUSH_CATEGORIES = (state) => {
  state.list = []
}

const SET_CURRENT_CATEGORY = (state, data) => {
  state.current = data
}

export default {
  SET_CATEGORIES_PROCESSING,
  SET_CATEGORIES,
  FLUSH_CATEGORIES,
  SET_CURRENT_CATEGORY
}
