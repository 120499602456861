import axios from '@/axios'

/**
 * Requêter la liste de toutes les catégories mères
 */
const requestCategories = async ({ commit }) => {
  commit('SET_CATEGORIES_PROCESSING', true)
  try {
    const categories = await axios.get(
      `${process.env.VUE_APP_API_VERSION}/categories`
    )
    commit('SET_CATEGORIES', categories.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_CATEGORIES_PROCESSING', false)
  }
}

/**
 * Requêter une catégorie en fonction de son slug pour la page catégorie
 * @param {String} slug Slug utilisé pour chercher la catégorie
 */
const requestCategoryBySlug = async ({ commit }, slug) => {
  commit('SET_CATEGORIES_PROCESSING', true)
  try {
    const category = await axios.get(
      `${process.env.VUE_APP_API_VERSION}/categories/${slug}`
    )
    commit('SET_CURRENT_CATEGORY', category.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_CATEGORIES_PROCESSING', false)
  }
}

const flushCategories = async ({ commit }) => {
  commit('FLUSH_CATEGORIES')
}

export default {
  requestCategories,
  requestCategoryBySlug,
  flushCategories
}
