<template>
  <div class="toolbar-hints">
    <m-dropdown right>
      <template v-slot:activator>
        <button @click="handleRedirect">
          <icon
            icon="aide"
            size="xl"/>
        </button>
      </template>

      <ul class="list">
        <div class="list-item--group">
          <template v-for="(item, index) in toolbarItems">
            <li
              v-if="hasPermission(item)"
              :key="index"
              class="list-item uppercase"
              @click="handleClick(item.link, item.query, item.params)">
              <div class="list-item--content">
                <icon
                  :icon="item.icon"
                  class="list-item--media"></icon>
                <span class="list-item--title">
                  {{ item.text }}
                </span>
              </div>
            </li>
          </template>
        </div>
      </ul>
    </m-dropdown>
  </div>
</template>

<script>
import checkPermissionMatch from '@/mixins/checkPermissionMatch'
import MDropdown from '@/components/commons/Dropdown'
import toolbarItems from '../../datas/toolbar'

export default {
  components: {
    MDropdown
  },

  computed: {
    toolbarItems () {
      return toolbarItems.hints
    }
  },

  mixins: [
    checkPermissionMatch
  ],

  methods: {
    handleClick (link, query, params) {
      this.$router.push({ name: link, query: query, params: params })
    },

    handleRedirect () {
      this.$router.push('/formulaire')
    }
  }
}
</script>
