<template>
  <div class="login-wrapper--form">
    <h1 class="font-bold text-xl uppercase text-center mb-6">Connexion</h1>
    <p
        v-if="validationError"
        class="error"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="dispatchError"
        class="error"
    >
      {{ dispatchError }}
    </p>
    <form >
      <fieldset class="flex flex-col">
        <label
          for="login"
          class="form-label">Identifiant </label>
        <input
          v-model="form.login"
          placeholder="John Doe"
          type="text"
          class="form-input">
        <label
          for="password"
          class="form-label">Mot de passe</label>
        <input
          v-model="form.password"
          placeholder="password"
          type="password"
          class="form-input">
      </fieldset>
      <button
        class="btn btn-primary btn-large btn-block mt-3"
        :class="{ 'disabled' : loading}"
        @click.prevent="handleLogin">
        Se connecter
      </button>
      <a
        class="block mt-3 cursor-pointer"
        @click="handleSwitchForm">Mot de passe oublié ?</a>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    validationError: false,
    form: {
      login: '',
      password: ''
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    },

    dispatchError () {
      return this.$store.state.authentication.errorMessage
    }
  },

  methods: {
    handleLogin () {
      this.$store.dispatch('closeErrorMessage')
      this.validationError = false;
      if(!this.form.login || !this.form.password) {
        this.validationError = 'Email et mot de passe requis'
        return
      }

      const formData = {
        username: this.form.login,
        password: this.form.password
      }

      this.$store.dispatch('requestToken', formData)
    },

    handleSwitchForm () {
      this.$store.dispatch('closeErrorMessage')
      this.$emit('switch')
    }
  }
}
</script>

<style lang="scss" scoped>
  .error {
    @apply p-3 mb-5 bg-red-700 text-white font-bold rounded
  }
</style>
