import axios from '@/axios'

const getListing = async ({ commit }, payload) => {
  commit('SET_LOADING', true)

  try {
    const params = { ...payload }
    let facets = {
      f: []
    }

    for (const [i, entry] of Object.entries(params)) {
      switch (i) {
        case 'contract_status':
        case 'contract_nature':
        case 'purchase_category':
        case 'automatic_renewal':
          const values = entry.split(',')
          
          for(const value of values) {
            facets.f.push(`${i}:${value}`)
          }

          delete params[i]
          break
          default:
            break;
      }
    }

    const requestParams = {
      ...facets,
      ...params
    }

    const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/contracts`, {
      params: requestParams,
    })
    commit('SET_LISTING', res.data?.rows)

    commit('SET_CONTRACTS_FILTERS', res.data?.filters)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_LOADING', false)
  }
}

export default {
  getListing,
}
