<template>
  <section
    :class="[
    'grid',
    'grid-cols-1',
    'gap-8',
    this.$store.getters.userRole === 'supplier' ? 'sm:grid-cols-2' : 'md:grid-cols-2',
    this.$store.getters.userRole === 'subscriber' ? 'xl:grid-cols-4' : null,
    ]"
  >
    <section-card
      v-for="(card, index) in displayCardsAccordingToUserRole"
      :key="index"
      :content="card"
      />
  </section>
</template>

<script>
import SectionCard from './SectionCard.vue'

export default {
  components: {
    SectionCard
  },

  computed: {
    displayCardsAccordingToUserRole () {
      if (this.$store.getters.userRole === 'subscriber') {
        return this.$store.state.content.dashboard['card/subscriber']
      } else {
        return this.$store.state.content.dashboard['card/supplier']
      }
    }
  },

  methods: {
    fetchCardData () {
      if (this.$store.getters.userRole === 'subscriber') {
        this.$store.dispatch('requestDashboardDatas', 'card/subscriber')
      } else {
        this.$store.dispatch('requestDashboardDatas', 'card/supplier')
      }
    }
  },

  beforeMount () {
    this.fetchCardData()
  }
}
</script>
