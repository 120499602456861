const sidebarItems = [
  {
    icon: 'prendre-rdv',
    text: 'Prendre contact',
    link: 'form',
    params: {},
    query: {
      receiverEmail: 'mercurial@mercurial.fr',
      receiverName: 'Equipe Mercurial'
    }
  },
  {
    icon: 'trouver-formation',
    text: 'Trouver une formation',
    link: 'formations',
    params: {},
    hiddenFor: [
      'supplier'
    ]
  },
  {
    icon: 'participer-evenement',
    text: 'Participer à un évènement',
    link: 'articles',
    params: {},
    query: {
      filtre: 'agenda-mercurial'
    },
    hiddenFor: [
      'supplier'
    ]
  },
  {
    icon: 'webinar',
    text: 'Revisionner nos webinars',
    link: 'articles',
    params: {},
    query: {
      filtre: 'webinars'
    },
    hiddenFor: [
      'supplier'
    ]
  },
  {
    icon: 'webinar',
    text: 'Suivre l\'actualité du réseau',
    link: 'articles',
    params: {},
    query: {},
    hiddenFor: [
      'subscriber'
    ]
  }
]

export default sidebarItems
