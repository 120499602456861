<template>
  <div class="subscribers">
    <div
      v-if="isLoading"
      class="">Chargement...</div>

    <div
      v-else-if="subscribers.length > 0"
      class="subscribers-list">
      <list-subscribers-item
        class="subscribers-list--item"
        v-for="(subscriber, index) in subscribers"
        :key="index"
        :subscriber="subscriber" />
    </div>
    <div v-else>Aucun résultat</div>
  </div>
</template>

<script>
import ListSubscribersItem from '@/components/customs/subscribers/ListSubscribersItem.vue'

export default {
  components: {
    ListSubscribersItem
  },

  computed: {
    subscribers () {
      return this.$store.state.subscribers.list.rows
    },

    isLoading () {
      return this.$store.state.subscribers.isProcessing
    }
  }
}
</script>
