<template>
  <div class="toolbar-search">
    <engine-form
      v-show="!isMobileScreen || isVisible"
      @click="toggle"
      @onSearch="isVisible = false"
      v-click-outside="hide"></engine-form>

    <engine-search></engine-search>

    <button @click="isVisible = !isVisible">
      <icon

        class="toolbar-search--icon"
        icon="rechercher"
        size="xl">
      </icon>
    </button>
  </div>
</template>

<script>
import EngineForm from '@/components/engine/Form.vue'
import EngineSearch from '@/components/templates/EngineSearch.vue'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    EngineForm,
    EngineSearch
  },

  data: () => ({
    focused: false,
    isMobileScreen: window.innerWidth <= 1220,
    isVisible: false
  }),

  methods: {
    toggle () {
      this.isVisible = true
    },

    hide() {
      this.isVisible = false
    }
  },

  created () {
    addEventListener('resize', () => {
      this.isMobileScreen = innerWidth <= 1220
    })
  },

  destroyed () {
    removeEventListener('resize')
  },

  directives: {
    ClickOutside
  }
}
</script>
