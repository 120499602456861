<template>
  <div
    class="toolbar-navcaller"
    :class="{ open: isOpen }"
    @click.stop="handleClick">
    <div class="toolbar-navcaller--icon">
      <span class="burger-line burger-line--top"></span>
      <span class="burger-line burger-line--middle"></span>
      <span class="burger-line burger-line--bottom"></span>
    </div>
    <span
      class="toolbar-navcaller--text"
      :class="{ 'shown' : isOpen }">Menu</span>
  </div>
</template>

<script>
export default {
  computed: {
    isOpen () {
      return this.$store.state.isNavbarOpen
    }
  },

  methods: {
    handleClick () {
      const nextStatus = !this.isOpen
      this.$store.dispatch('navbarStatus', nextStatus)
    }
  }
}
</script>
