<template>
  <card class="home-articles">
    <template slot="header">
      <icon
        icon="actualites"
        size="xl" />
      <h2>Actualités</h2>
    </template>

    <div class="flex">
      <miniature-article
        v-for="(article, index) in last2Items"
        :key="index"
        :title="article.title"
        :media="article.medias"
        :body="article.body"
        :slug="article.slug"
        :type="article.type"
        >
      </miniature-article>
    </div>

    <template slot="footer">
      <button
        @click="handleClick"
        class="btn btn-small btn-outline--primary">Voir toutes les actualités</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import MiniatureArticle from '@/components/customs/articles/MiniatureArticle.vue'

export default {
  components: {
    Card,
    MiniatureArticle
  },

  computed: {
    articles () {
      return this.$store.state.home.articles
    },

    last2Items () {
      return this.articles.slice(0, 2)
    }
  },

  methods: {
    handleClick () {
      this.$router.push({ name: 'articles' })
    },

    fetchArticles () {
      this.$store.dispatch('home/requestArticles')
    }
  },

  beforeMount () {
    this.fetchArticles()
  }
}
</script>

<style lang="scss" scoped>
  .home-articles .article_miniature {
    width: 50%;
  }
</style>
