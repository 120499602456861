<template>
  <li
    class="list_categories-item"
    @click="handleClick(label.slug)">
    <img
      class="list_categories-item--image"
      :src="label.media.source"
      :alt="label.media.alt">
    <h3 class="list_categories-item--title">
      {{ label.title }}
    </h3>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      default: () => ({
        title: '',
        slug: '',
        media: {
          source: '',
          alt: ''
        }
      })
    }
  },

  methods: {
    handleClick (category) {
      this.$router.push(`fournisseurs/${category}`)
    }
  }
}
</script>
