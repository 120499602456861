<template>
  <section class="section grid grid-cols-1 md:grid-cols-3 gap-6">
    <div class="col-span-3 lg:col-span-1">
      <card
        dense
        edged
        v-if="contacts.references">
        <template slot="header">
          <icon icon="contact"></icon>
          <h2 class="font-bold">Référent Mercurial</h2>
        </template>

        <address class="mb-3">
          <span class="font-bold">{{ referenceContactName }}</span><br>
          <span v-if="contacts.references.email">{{ contacts.references.email }}</span><br v-if="contacts.references.email">
          <span v-if="contacts.references.phone || contacts.references.tel">{{ contacts.references.phone ? contacts.references.phone : contacts.references.tel }}</span><br>
        </address>

        <div
          class="icon-text"
          @click="$router.push({ name: 'page', params: { slug: 'acheteurs-mercurial' } })">
          <icon
            icon="fleche-bleue"
            size="sm"></icon>
          <span class="text-primary underline cursor-pointer">Autres contacts Mercurial</span>
        </div>

        <template slot="footer">
          <button
            @click="handleGoContactForm(referenceContactEmail, referenceContactName)"
            class="btn btn-large btn-secondary">Prendre contact</button>
        </template>
      </card>
    </div>

    <div class="col-span-3 lg:col-span-1">
      <card
        dense
        edged
        v-if="contacts.list.length > 0">
        <template slot="header">
          <icon icon="contact"></icon>
          <h2 class="font-bold">Contact {{ variant === 'supplier' ? 'fournisseur' : 'adhérent' }}</h2>
        </template>

        <address class="mb-3">
          <span class="font-bold">{{ contacts.list[0].firstname + ' ' + contacts.list[0].lastname }}</span><br>
          <span v-if="contacts.list[0].email">{{ contacts.list[0].email }}</span><br v-if="contacts.list[0].email">
          <span v-if="contacts.list[0].phone || contacts.list[0].tel">{{ contacts.list[0].phone ? contacts.list[0].phone : contacts.list[0].tel  }}</span>
        </address>

        <div class="icon-text">
          <icon
            icon="fleche-bleue"
            size="sm"></icon>
          <modal>
            <a
              slot="activator"
              href=""
              @click.prevent>Autres contacts</a>

            <div class="p-6">
              <Contact
                class="mb-5"
                v-for="(contact, index) in contacts.list"
                :key="index"
                :contact="contact">
              </Contact>
              <div class="text-center">
                <a
                  v-if="contacts.file"
                  :href="contacts.file"
                >
                  Télécharger le fichier de contact
                </a>
              </div>
            </div>
          </modal>
        </div>

        <template slot="footer">
          <button
            @click="handleGoContactForm(supplierContactEmail, supplierContactName, ccReceiverEmail)"
            class="btn btn-large btn-secondary">Prendre contact</button>
        </template>
      </card>
    </div>

    <div class="col-span-3 lg:col-span-1">
      <card
        v-if="metadatas.keyNumbers.length > 0"
        class="mb-3"
        dense
        edged>
        <template slot="header">
          <icon icon="chiffres-cles"></icon>
          <h2 class="uppercase text-xl font-bold">Chiffres clés</h2>
        </template>

        <template v-for="(metadata, index) in metadatas.keyNumbers">
          <span :key="index.key">
            {{ metadata.key }}
          </span>
          <strong :key="index.value">
            {{ metadata.value }}
          </strong><br :key="index.spacer">
        </template>
      </card>

      <div class="text-right text-sm">
        <div class="flex items-center justify-end">
          <icon
            icon="referencement-mercurial"
            size="sm"/>
          <span v-if="referenceYear">
            {{ variant === 'supplier' ? 'Année de référencement Mercurial:' : 'Année d\'adhésion Mercurial :' }}
            <strong>{{ referenceYear }}</strong><br>
          </span>
        </div>

        <div class="flex items-center justify-end">
          <icon
            icon="mise-a-jour"
            size="sm"/>
          <span>Mise à jour le </span>
          <span>{{ updatedAt }}</span>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import Modal from '@/components/commons/Modal.vue'
import Contact from '@/components/customs/contacts/Contact.vue'

export default {
  components: {
    Card,
    Modal,
    Contact
  },

  props: {
    contacts: {
      type: Object,
      default: () => ({
      }),
      required: false
    },

    metadatas: {
      type: Object,
      default: () => ({

      }),
      required: false
    },

    referenceYear: {
      type: String,
      default: '',
      required: true
    },

    updatedAt: {
      type: String,
      default: '',
      required: true
    },

    variant: {
      type: String,
      default: '',
      required: false
    }
  },

  computed: {
    referenceContactName () {
      return this.contacts.references.firstname + ' ' + this.contacts.references.lastname
    },

    referenceContactEmail () {
      return this.contacts.references.email
    },

    supplierContactName () {
      return this.contacts.list[0].email ? this.contacts.list[0].firstname + ' ' + this.contacts.list[0].lastname : this.referenceContactName
    },

    supplierContactEmail () {
      return this.contacts.list[0].email ? this.contacts.list[0].email : this.referenceContactEmail
    },

    ccReceiverEmail() {
      if (this.$store.getters.userRole === 'supplier') {
        return this.$store.state.authentication.user.referentMercurialEmail
      } else if (this.$store.getters.userRole === 'subscriber' && this.$route.name === 'supplierPresentation') {

        return this.referenceContactEmail
      }
      return ''
    },

    supplier () {
      return this.$store.state.suppliers.current
    }
  },

  methods: {
    handleGoContactForm (receiverEmail, receiverName, ccReceiverEmail = '') {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: this.variant === 'supplier' ? 'Renseignements Achats' : '',
            receiverEmail: receiverEmail,
            ccReceiverEmail: ccReceiverEmail,
            receiverName: receiverName,
            family: this.variant === 'supplier' ? this.supplier.metadatas.family : ''
          }
        })
    }
  }
}
</script>
