import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    isProcessing: false,
    list: [],
    current: {}
  },

  actions,
  mutations,
  getters
})
