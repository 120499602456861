<template>
  <section
    v-if="labels.length > 0"
    class="section flex pt-6">
    <card
      edged
      background="bg-gray-100">
      <template slot="header">
        <icon
          icon="plus-mercurial"
          size="xl"></icon>
        <h2 class="uppercase text-xl font-bold">
          {{ $route.name === 'supplierPresentation'
            ? 'Les plus Mercurial'
            : 'Plus d\'informations' }}
        </h2>
      </template>

      <ul class="labels-list">
        <li
          class="labels-list--item"
          v-for="(label, index) in labels"
          :key="index">
          <icon
            icon="plus-orange"
            size="sm"></icon>
          <span class="flex-1">
            {{ label }}
          </span>
        </li>
      </ul>
    </card>
  </section>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  props: {
    labels: {
      type: Array,
      default: () => ([

      ]),
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-header {
  background-color: var(--color-primary);
  color: #FFF;
}

.labels-list {
  @apply grid grid-cols-2 gap-3 p-6;

  &--item {
    @apply flex items-start justify-between;

    .icon {
      @apply mt-1;
    }
  }
}
</style>
