<template>
  <div class="select">
    <div 
      @click="toggleDropdown" 
      :class="[
        'btn', 
        'btn-select', 
        'cursor-pointer', 
        disabled ? 'disabled' : null, 
        error ? 'error' : null]
      "
    >
      <span>{{ selectedText }}</span>
      <icon
        class="transition-all duration-100 ease-in-out ml-3"
        :class="{'transform rotate-180' : isOpen}"
        icon="fleche-bas"
        size="xs"/>
    </div>
    <div v-show="isOpen" class="select-dropdown">
      <ul v-if="isOpen" class="select-list">
        <li 
          v-for="option in options" 
          :key="option[valueKey]" 
          class="select-list--item"
          @click="selectOption(option)"
        >
          <div>
            {{ option.label }}
          </div>
        </li>
      </ul>
    </div>
    <div :class="['absolute', 'text-sm']" :style="{color: 'red'}">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    valueKey: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      isOpen: false,
      selectedValue: this[this.valueKey]
    };
  },
  computed: {
    selectedText() {
      const selected = this.options.find(option => option[this.valueKey] === this.selectedValue);
      return selected ? selected.label : 'Sélectionnez...';
    }
  },
  watch: {
    value(newVal) {
      this.selectedValue = newVal;
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedValue = option[this.valueKey];
      this.$emit('input', this.selectedValue);
      this.isOpen = false;
    }
  }
};
</script>

<style scoped lang="scss">
.select-list--item {
  padding: 10px;
}
</style>
