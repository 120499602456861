<template>
  <card
    dense
    leftTitle>
    <template slot="header">
      <icon
        icon="favoris"
        size="sm" />
      <h2 class="uppercase text-base font-bold">
        Adhérents repérés
      </h2>
    </template>

    <template>
      <div
        class="subscribers"
        v-if="favoriteSubscribers">
        <div
          v-for="(subscriber, index) in favoriteSubscribers"
          :key="index"
          class="subscribers_item">
          <p class="text-sm w-1/2 truncate">
            <span class="uppercase font-bold">{{ subscriber.title }} </span>
            <!-- <span>({{ subscriber.title }})</span> -->
          </p>
          <router-link
            :to="{ name: 'subscriberPresentation', params: { slug: subscriber.slug }}"
            class="w-1/2 flex items-center justify-end text-sm">
            <span class="mr-2">Consulter la fiche</span>
            <icon icon="fleche-orange"/>
          </router-link>
        </div>
      </div>
    </template>

    <template slot="footer">
      <button
        class="btn btn-small btn-outline--primary"
        @click="$router.push({ name: 'subscribers', query: { favorites: true }})">Voir tous les adhérents repérés</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import axios from "@/axios";

export default {
  data: function () {
    return {
      favoriteSubscribers: []
    }
  },

  components: {
    Card
  },

  beforeMount() {
    this.fetchFavoritesSubscribers()
  },

  methods: {
    async fetchFavoritesSubscribers() {
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/subscribers`, {
          params: {
            favorites: true,
            limit: 9
          }
        })


        this.favoriteSubscribers = res.data.rows

      } catch (err) {
      console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subscribers {
  @screen lg {
    max-height: 360px;
  }

  &_item {
    @screen lg {
      height: 40px;
      @apply truncate;
    }

    @apply flex items-center justify-between;
    @apply p-3;
    @apply border-b;

    &:last-of-type { @apply border-0; }

    &:nth-child(2n+1) {
      @apply border-r;
    }
  }
}
</style>
