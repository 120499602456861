<template>
  <div class="wrapper">
    <div class="subcontainer">
      <h1 class="text-center uppercase font-bold my-8 text-2xl">Formulaire de contact</h1>
      <p class="text-center">Vous avez une question sur les fournisseurs, leurs offres, les formations, notre offre de conseil et de délégation ? ou bien sur tout autre sujet, remplissez ce formulaire et les acheteurs Mercurial vous répondront directement par mail</p>
      <p
        v-if="success"
        class="success"
      >Votre demande de contact a bien été envoyée</p>
      <p
        v-if="error"
        class="error"
      >
        {{ error }}
      </p>
      <form
        autocomplete="off"
        class="mt-8"
        ref="form"
        @submit.prevent="handleSubmit">
        <!-- TMA 37151 : formulaires avec destinaire "Equipe Mercurial" Retirer le champs destinataire Décaler le champ "objet" et lui faire prendre toute la longueur de la ligne. -->
        <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8" v-if="receiverName != 'Equipe Mercurial' && receiverName != 'Équipe Mercurial'">
          <div class="flex flex-col">
            <label
              for="recipient"
              class="form-label">Destinataire</label>
            <input
              id="recipient"
              name="receiver"
              placeholder=""
              type="text"
              disabled
              :value="receiverName"
              class="form-input">
          </div>
          <div class="flex flex-col">
            <label
              for="title"
              class="form-label">Objet</label>
            <input
              id="title"
              name="object"
              v-model="form.object"
              placeholder=""
              type="text"
              class="form-input">
          </div>
        </fieldset>

          <fieldset v-else class="grid grid-cols-1 gap-8">
            <div class="flex flex-col">
              <label
                for="title"
                class="form-label">Objet</label>
              <input
                id="title"
                name="object"
                v-model="form.object"
                placeholder=""
                type="text"
                class="form-input">
            </div>
            </fieldset>

        <fieldset class="grid gap-8">
          <div class="flex flex-col mt-4">
            <label
              for="body"
              class="form-label">Votre demande</label>
            <textarea
              id="body"
              name="your_demand"
              v-model="form.your_demand"
              placeholder="Ici votre demande"
              rows="5"
              type="textfield"
              class="form-input">
            </textarea>
          </div>
        </fieldset>

        <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
          <div v-if="!form.isFormation && !form.isFormationContact" class="flex flex-col">
            <label
              for="family"
              class="form-label">Famille d'achat liée à votre demande</label>
            <input
              id="family"
              name="purchase_family"
              v-model="form.purchase_family"
              placeholder=""
              type="text"
              class="form-input">
          </div>
          <div
            v-show="form.event"
            class="flex flex-col">
            <label
              for="event"
              class="form-label">Evènement</label>
            <input
              id="event"
              name="event"
              v-model="form.event"
              placeholder=""
              type="text"
              class="form-input">
          </div>
          <div
            v-show="form.event_date"
            class="flex flex-col">
            <label
              for="family"
              class="form-label">Date de l'évènement</label>
            <input
              id="family"
              name="event_date"
              v-model="form.event_date"
              placeholder="28/08/2020"
              type="text"
              class="form-input">
          </div>
          <div class="flex flex-col">
            <label
              for="event"
              class="form-label">Numéro de téléphone pour être rappelé</label>
            <input
              id="event"
              name="phone"
              v-model="form.phone"
              placeholder="06 12 34 56 78"
              type="tel"
              class="form-input">
          </div>
        </fieldset>

        <div class="text-center mt-8">
          <button
            class="btn btn-secondary"
            type="submit">
            <icon
              icon="fleche-blanche"
              size="xs" />
            <span v-if="form.isFormation">Valider mon inscription</span>
            <span v-else>Envoyer votre demande</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $axios from '@/axios'

export default {
  data: function () {
    return {
      isLoading: false,
      error: false,
      success: false,
      receiverName: '',
      form: {
        receiver: '',
        cc_receiver: '',
        email: '',
        object: '',
        your_demand: '',
        purchase_family: '',
        event: '',
        event_date: '',
        phone: '',
        webform_id: 'contact',
        isFormation: '',
        isFormationContact: ''
      }
    }
  },

  methods: {
    async handleSubmit () {
      if (!this.form.object || !this.form.your_demand || !this.form.phone) {
        this.error = 'Les champs "Object", "Votre demande" et "Téléphone" sont requis'
        return
      }
      this.isLoading = true
      try {
        await $axios.post('/webform_rest/submit', this.form, {
          headers: {
            'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
          }
        })
        this.error = false
        this.success = true
      } catch (err) {
        this.error = 'Une erreur est survenue lors de la soumission du formulaire'
      } finally {
        this.isLoading = false
      }
    },

    async updateValues () {
      const user = this.$store.state.authentication.user
      this.form.receiver = user.referentMercurialEmail
      this.receiverName = user.referentMercurialName
      this.form.phone = user.phone ? user.phone : user.tel
      if (this.$route.query.receiverEmail && this.$route.query.receiverName) {
        this.form.receiver = this.$route.query.receiverEmail
        this.receiverName = this.$route.query.receiverName
      }
      if(this.$route.query.ccReceiverEmail) {
        this.form.cc_receiver = this.$route.query.ccReceiverEmail
      }

      this.form.email = this.$store.state.authentication.user.email
      this.form.object = this.$route.query.object
      this.form.purchase_family = this.$route.query.family
      this.form.event = this.$route.query.event
      this.form.event_date = this.$route.query.eventDate
      this.form.isFormation = this.$route.query.isFormation
      this.form.isFormationContact = this.$route.query.isFormationContact
    }
  },

  watch: {
    async $route (from, to) {
      await this.updateValues()
    }
  },

  async beforeMount () {
    await this.updateValues()
  }

}
</script>

<style lang="scss" scoped>

.success {
  @apply p-3 mt-5 bg-green-800 font-bold text-center rounded text-white;
}

.error {
  @apply p-3 mt-5 bg-red-700 font-bold text-center rounded text-white;
}

.form {
  &-input {
    @apply border-gray-400;

    &::placeholder {
      @apply italic
    }
  }

  &-label {
    @apply font-bold;
  }
}

@media screen and (max-width: 767px) {
.subcontainer .form-input {
  margin-bottom: 0;
  }
}

</style>
