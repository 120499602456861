<template>
  <li class="suppliers-list--item">
    <div class="flex supplier">

      <section class="supplier-aside">
        <div class="ratio-square">
          <img
            :src="supplier.medias.thumbnail.source"
            :alt="supplier.medias.thumbnail.alt"
            class="supplier-aside--logo cursorPointer"
            @click="handleGoToSupplierPage(supplier.slug)"
            >
        </div>

        <div
          v-if="supplier.metadatas.members"
          class="supplier-aside--indication">
          <icon icon="plus-bleu-fonce"></icon>
          <span>de {{ supplier.metadatas.members }} adhérents</span>
        </div>
      </section>

      <section class="supplier-main">
        <div class="supplier-main--header">
          <div class="block">
            <h3 class="supplier-main--title">
              {{ supplier.title }}
            </h3>
            <p class="supplier-main--subfamily">
              {{ supplier.family }}
            </p>
          </div>
          <div class="flex col-gap-2">
            <work-with :collaborator="supplier.metadatas.collaborator" class="hidden lg:block" />
            <Favorite :value="supplier.metadatas.favorite" :entity-type="supplier.type" :entity-id="supplier.id" />
          </div>
        </div>

        <div class="supplier-main--tags">
          <tag
            v-for="(tag, index) in maxAmountOfTags"
            :key="index"
            variant="small"
            :label="tag"/>
        </div>

        <div class="supplier-main--actions">
          <div class="lg:w-2/3 w-full mb-3">
            <button
              class="btn btn-large btn-dark mr-3"
              @click="handleGoToSupplierPage(supplier.slug)">Voir la fiche</button>
            <button
              class="btn btn-large btn-outline--secondary"
              @click="handleGoToSupplierOffersPage(supplier.slug)">Voir les offres</button>
          </div>

          <div class="lg:w-1/3 w-full">
            <div class="flex items-center text-sm mb-1">
              <icon
                icon="referencement-mercurial"
                size="md"></icon>
              <span>Année de référencement Mercurial :
                <strong>
                  {{ supplier.referenceYear }}
                </strong>
              </span>
            </div>

            <div class="flex items-center text-sm">
              <icon
                class="mx-1"
                icon="mise-a-jour"
                size="sm"></icon>
              <span>Mis à jour le</span>
              <strong>
                {{ supplier.updatedAt }}
              </strong>
            </div>
          </div>
        </div>
      </section>
    </div>
  </li>
</template>

<script>
import Tag from '@/components/commons/Tag'
import WorkWith from '@/components/customs/suppliers/WorkWith.vue'
import Favorite from "@/components/customs/suppliers/Favorite.vue";

export default {
  props: {
    supplier: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  components: {
    Favorite,
    Tag,
    WorkWith
  },

  computed: {
    // Il faut afficher 10 tags ou 5 tags pour garder sur 2 lignes
    maxAmountOfTags () {
      const tagList = []
      let max = 10

      if (window.screen.width <= 768) max = 6

      // Concatenation des tags en fonction de la taille de l'écran
      tagList.push(...this.supplier.tags.slice(0, max))

      if (tagList.length > max) {
        tagList.push('...')
      }

      return tagList
    }
  },

  methods: {
    handleGoToSupplierPage (slug) {
      this.$router.push(`/fournisseur/${slug}`)
    },

    handleGoToSupplierOffersPage (slug) {
      this.$router.push(`/fournisseur/${slug}/offres`)
    }
  }
}
</script>
