<template>
  <section class="page-drawer--section">
    <span class="text-xl">Filtrer</span>
    <hr class="spacer my-6" />
    <template v-if="families.length > 0">
      <div
        class="checker"
        v-for="(family, index) in families"
        :key="index"
      >
        <div class="checker-parent">
          <input
            type="checkbox"
            :id="family.id"
            :value="family.code"
            v-model="checkedFamilies">
          <label :for="family.id">
            {{ family.label }}
          </label>
        </div>

        <div
          class="checker-children"
          v-for="(subFamily, subIndex) in family.subFamilies"
          :key="subIndex"
        >
          <input
            type="checkbox"
            :value="subFamily.code"
            :id="subFamily.id"
            v-model="checkedFamilies">
          <label
            :for="subFamily.id">
            {{ subFamily.label }}
          </label>
        </div>
      </div>
    </template>
    <template v-else>
      <span>Aucun filtre disponible</span>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    checkedFamilies: [],
  }),

  computed: {
    ...mapState('indices', {
      families: state => state.families
    })
  },

  methods: {
    // Quand on sélectionne ou déselectionne une checkbox, d'abord on renseigne
    // dans l'URL quel élément a été checké puis ensuite à chaque fois qu'on
    // change d'URL, la page va forcément effectuer une nouvelle requête des
    // fournisseurs avec les nouveaux params.
    itemChecked () {
      this.$router.push({
        query: {
          families: this.checkedFamilies.join(','),
        }
      })

      // Pour éviter d'avoir un message d'erreur dans le cas où on redirige sur
      // la même route (ex rafraîchissement de page), on met ce petit catch :
      .catch(() => {})
    },

    handleCloseDrawer () {
      this.$store.dispatch('drawerStatus', false)
    }
  },

  watch: {
    'checkedFamilies' () {
      this.itemChecked()
    }
  },

  beforeMount () {
    // En cas de refresh de la page, on récupère ce qui a déjà été renseigné
    // dans les query params pour ne pas perdre la recherche précédente.
    if (this.$route.query.families) {
      this.checkedFamilies = this.$route.query.families.split(',')
    }
  }
}
</script>
