<template>
  <div class="wrapper">
    <div class="container">
      <div class="flex flex-wrap">
        <section class="container-header">
          <div class="container-header--title">
            <icon
              icon="formation"
              size="xl" />
            <h1>Formations</h1>
          </div>
          <p class="container-header--description">Toutes les formations Mercurial à disposition pour vous inscrire, retrouver les supports, vous informer.</p>
      
          <div class="container-header--boutons flex mt-3">
            <div v-if="planningFile">
              <button class="btn btn-outline btn-outline--primary btn-small downloadPdf mr-3" @click="handleClick(planningFile)">Planning des
                formations
              </button>
            </div>
            <div v-if="catalogueFile">
              <button class="btn btn-outline btn-outline--primary btn-small downloadPdf" @click="handleClick(catalogueFile)">Catalogue des
                formations
              </button>
            </div>
          </div>

        </section>

        <section class="container-sorter">
          <div
            v-if="isLoading"
            class="">Chargement...</div>
          <SelectFilter
            v-else
            :filter="filter" />
        </section>
      </div>
    </div>

    <div class="background">
      <div class="container">
        <list-articles formations />
      </div>
      <pagination
        :page-count="pageCount"
      />
    </div>
  </div>
</template>

<script>
import SelectFilter from '@/components/commons/SelectFilter.vue'
import ListArticles from '@/components/customs/articles/ListArticles.vue'
import Pagination from '@/components/commons/Pagination'

export default {
  components: {
    ListArticles,
    SelectFilter,
    Pagination
  },

  computed: {
    isLoading () {
      return this.$store.state.articles.isProcessingArticle
    },

    filter () {
      // console.log(this.$store.state.articles.isProcessingArticle)
      return this.$store.state.articles.formationCategories ? this.$store.state.articles.formationCategories : null
    },

    pageCount () {
      return this.$store.state.articles.formationList.pager ? this.$store.state.articles.formationList.pager.pageCount : 1
    },

    planningFile() {
      return this.$store.state.articles.formationList.planningfile
        ? this.$store.state.articles.formationList.planningfile
        : ''
    },

    catalogueFile() {
      return this.$store.state.articles.formationList.cataloguefile
        ? this.$store.state.articles.formationList.cataloguefile
        : ''
    },

  },

  methods: {
    handleClick(link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  &-header {
    @apply w-full my-8;

    @screen md {
      @apply w-3/4 my-8;
    }

    &--title {
      @apply flex items-center justify-start;
      @apply font-bold text-xl uppercase;
      @apply mb-4;
    }

    &--description {
      @apply opacity-50;
      @apply text-sm;
    }
  }

  &-sorter {
    @apply flex items-end justify-end;
    @apply w-full mb-4;

    @screen md {
      @apply w-1/4 my-8;
    }
  }
}
</style>
