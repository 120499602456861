<template>
    <modal :close-action="'ICON'">
        <div slot="activator">
            <button :class="['btn', 'btn-small', 'btn-outline--primary']">
                {{ title }}
            </button>
        </div>
        <div :class="['container']">
            <section :class="['container-header', 'mb-5']">
                <h1 :class="['container-header--title', !popin_subtitle ? 'mb-8' : null]">
                    <icon :icon="popin_icon" />
                    <span :class="['uppercase', 'font-bold']">
                        {{ popin_title }}
                    </span>
                </h1>
                <p v-show="popin_subtitle" :class="['container-header--description']">
                    {{ popin_subtitle }}
                </p>
                <div :class="['w-full']">
                    <div :class="['scroller', 'overflow-y-auto']">
                        <table :class="['m-auto', 'w-full', 'border-collapse', 'border-none']">
                            <!-- HEADER Table -->
                            <thead>
                                <tr>
                                    <th
                                      v-for="(rubric, index) in popin_rubrics"
                                      :key="index"
                                      v-html="rubric"
                                      :class="['px-2', 'font-normal', popin_id === 'ca_achat' ? 'whitespace-no-wrap' : null]"
                                    />
                                </tr>
                            </thead>
                            <tbody>
                              <!-- BODY Table Blue Highlighted Line -->
                              <tr :class="['bg-primary-lighter', 'text-lg']">
                                <td :class="['pl-16', 'p-2']">
                                  <a :class="['no-underline	']" :href="'/dashboard'">
                                    {{ popin_group.title }}
                                  </a>
                                </td>
                                <td
                                  v-if="popin_id === 'ca_achat' && popin_group.ca"
                                  v-html="popin_group.ca.replace(/([0-9,€])/g, '<b>$1</b>')"
                                  :class="['p-2', 'text-right', 'whitespace-no-wrap']"
                                />
                                <td
                                  v-html="popin_id === 'ca_achat' ? popin_group.part : popin_group.lastTwelveMonths"
                                  :class="['p-2', 'text-center', 'font-bold']"
                                />
                                <td :class="['pr-16', 'p-2', 'text-right', 'font-bold']">
                                  <span :class="['inline-flex', 'align-center', 'justify-center']">
                                    <icon
                                      v-if="popin_id === 'ca_achat'"
                                      :icon="
                                        Math.sign(popin_group.sign) === 1 ?
                                        'ca-achat' :
                                        Math.sign(popin_group.sign) === -1 ?
                                        'ca-achat-down' :
                                        'ca-achat-equal'
                                      "
                                      :class="['mr-2']"
                                      size="xs" />
                                    {{ popin_id === 'ca_achat' ? popin_group.evol : popin_group.lastYear }}
                                  </span>
                                </td>
                              </tr>
                              <!-- BODY Other Lines -->
                              <tr
                              v-for="(affiliate, index) in popin_affiliates"
                              :key="index"
                              >
                                <td :class="['pl-16']">
                                  <div :class="['p-2']" :style="{ borderBottom: '1px solid var(--color-primary-lighter)'}">
                                    <a
                                      :class="[
                                        'no-underline', 
                                        'text-black'
                                      ]"
                                      :href="'/dashboard/' + affiliate.slug"
                                    >
                                      {{ affiliate.title }}
                                    </a>
                                  </div>
                                </td>
                                <td
                                  v-if="popin_id === 'ca_achat' && affiliate.ca"
                                  :class="['text-right', 'whitespace-no-wrap']"
                                >
                                  <div 
                                    :class="['p-2']"
                                    :style="{ borderBottom: '1px solid var(--color-primary-lighter)'}"
                                    v-html="affiliate.ca.replace(/([0-9,€])/g, '<b>$1</b>')"
                                  />
                                </td>
                                <td>
                                  <div
                                  :class="['p-2', 'text-center', 'font-bold']"
                                  :style="{ borderBottom: '1px solid var(--color-primary-lighter)'}"
                                  v-html="popin_id === 'ca_achat' ? affiliate.part : affiliate.lastTwelveMonths"
                                  />
                                </td>
                                <td :class="['pr-16']">
                                  <div
                                    :class="['p-2', 'text-right', 'font-bold']"
                                    :style="{ borderBottom: '1px solid var(--color-primary-lighter)' }"
                                  >
                                    <div :class="['align-center', 'justify-center']">
                                      <icon
                                        v-if="popin_id === 'ca_achat'"
                                        :icon="
                                          Math.sign(affiliate.sign) === 1 ?
                                          'ca-achat' :
                                          Math.sign(affiliate.sign) === -1 ?
                                          'ca-achat-down' :
                                          'ca-achat-equal'
                                        "
                                        :class="['mr-2', 'inline']"
                                        size="xs" />
                                    {{ popin_id === 'ca_achat' ? affiliate.evol : affiliate.lastYear }}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import Contact from '@/components/customs/contacts/Contact'

export default {
  components: {
    Modal,
    Contact
  },
  props: {
    title: {
      type: String,
    },
    endpoint: {
      type: String,
      required: true,
    },
    popin_id: {
      type: String,
      required: true,
    },
    popin_icon: {
      type: String,
      required: true,
    },
    popin_title: {
      type: String,
      required: true,
    },
    popin_label_group: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    popin_subtitle: '',
    popin_rubrics: [],
    popin_group: {},
    popin_affiliates: {},
  }),
  methods:{
    getSectionCardData() { 
      this.$axios.get(`${process.env.VUE_APP_API_VERSION}${this.endpoint}`)
      .then(response => {       
        if (this.popin_id === 'ca_achat'){
            this.popin_subtitle += '(' + response.data.range + ')';
            this.popin_rubrics.push(...[null, 'CA n', 'Répartition n', 'Evolution n/n-1'])
        } else {
            this.popin_rubrics.push(...[
                null,
                response.data.range.lastTwelveMonths, 
                response.data.range.lastYear
            ])
        }

        this.popin_group = response.data.group;
        this.popin_affiliates = response.data.affiliates;

          // #35516 : tri décroissant sur N
          if (this.popin_id === 'ca_achat') {
            this.popin_affiliates.sort((a, b) => parseInt(b.ca_num, 10) - parseInt(a.ca_num, 10));
          }
          else if (this.popin_id === 'nb_suppliers') {
            this.popin_affiliates.sort((a, b) => parseInt(b.lastTwelveMonths, 10) - parseInt(a.lastTwelveMonths, 10));
          }
          else if (this.popin_id === 'nb_families') {
            this.popin_affiliates.sort((a, b) => parseInt(b.lastTwelveMonths, 10) - parseInt(a.lastTwelveMonths, 10));
          }
          else {
          }

      })
    }
  },
  created () {
    this.getSectionCardData();
  },
}
</script>

<style lang="scss" scoped>
.container {
  @apply my-8;

  &-header {
    @apply flex items-center justify-center flex-col;

    &--title {
      @apply flex items-center;
      @apply uppercase font-bold;
    }

    &--description {
      @apply text-center;
      @apply mt-0;
      @apply mb-8;
      max-width: 800px;
    }
  }

  .block /deep/ {
    .card {
      @apply flex-1 h-full;
    }
  }

  .map {
    @screen lg {
      width: 66%;
    }
  }

  .favorites-cards {
    @apply flex flex-col justify-between;

    @screen lg {
      width: 32%;
    }

    .favorites {
      @apply my-8;

      @screen lg {
        height: 70%;
        @apply m-0;
      }
    }

    .favorites-card-list {
      @screen lg {
        height: 29%;
      }
    }
  }
}

fieldset {
    columns: 2;

    legend {
        color: var(--color-primary);
        @apply font-bold;
    }
}

tbody {
  tr {
    &:first-child {
      td {
        &:first-child {
          color: var(--color-primary);
          @apply font-bold;
        }
      }
    }
  }
}
</style>
