<template>
  <section class="page-drawer--section">
    <template
      v-for="(filter, index) in newFilters"
    >
      <select-filter
        v-if="(filter.value === 'affiliates' && isFiliales) || filter.value !== 'affiliates'"
        :key="index"
        :filter="filter"
        :style="stateKey === 'roadmap' ? { marginTop: '.75rem' } : null"
      />
    </template>
  </section>
</template>

<script>
import SelectFilter from '@/components/commons/SelectFilter.vue'

export default {
  components: {
    SelectFilter
  },

  props: {
    stateKey: {
      type: String,
      default: function () {
        return this.$route.name
      },
      required: false
    }
  },

  computed: {
    filters () {
      return this.$store.state[this.stateKey].filters
    },
    isFiliales () {
      return this.$store.state.subscribers.isAffiliates
    },
    newFilters () {
      return Object.keys(this.filters).map(key => {
        const newFilters = {
          ...this.filters[key],
          value: key
        }
        return newFilters
      })
    }
  }
}
</script>

<style scoped>
 .page-drawer--section {
   @apply p-0 px-8 border-0;
 }
</style>
