<template>
  <div class="suppliers">
    <section class="subcontainer">
      <div
        v-if="loading"
        class="subcontainer-header">
        <div class="p-3">
          Chargement...
        </div>
      </div>

      <div
        v-else-if="isParentPage"
        class="subcontainer-header">
        <div class="subcontainer-header--text">
          <h1 class="title">
            Tous les fournisseurs
          </h1>
        </div>
            <div
            v-if="Listesuppliers"
             class="suppliers-header--downloadPdf">
            <button
              class="btn btn-outline btn-outline--primary btn-small"
               @click="handleClick(Listesuppliers)"
               >Liste des fournisseurs référencés
            </button>
          </div>
      </div>

      <div
        v-else
        class="subcontainer-header">
        <img
          v-if="image"
          class="subcontainer-header--image"
          :src="image.source"
          :alt="image.alt"
          :title="image.title">

        <div class="subcontainer-header--text">
          <h1 class="title">
            {{ title }}
          </h1>
          <p class="body">
            {{ body }}
          </p>
          <button
            v-if="link"
            @click="handleExpertFiches(link)"
            class="btn btn-outline uppercase">
            <icon icon="fiches-experts_1"></icon>
            <span>Voir les fiches experts</span>
          </button>
        </div>
      </div>
    </section>

    <div class="bg-gray-200 flex-1 pb-8">
      <section class="subcontainer">
        <div class="suppliers-header">
          <h2 class="suppliers-header--title">
            <icon
              class="suppliers-header--icon"
              icon="fournisseurs" />
            <span>Liste des fournisseurs</span>
          </h2>

          <div class="suppliers-header--download">
            <button
              class="btn btn-outline btn-outline--primary btn-small"
              @click="handleExportXLSX">Export de la liste au format xlsx
            </button>
          </div>

          <div class="suppliers-header--filters">
            <button
              class="btn btn-dark btn-small mr-3"
              @click="handleOpenDrawer">
              Filtrer
            </button>
          </div>
        </div>

        <list-suppliers />
      </section>
      <pagination
        :page-count="pageCount"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ListSuppliers from '@/components/customs/suppliers/ListSuppliers'
// import SectionPagination from './blocks/SectionPagination'
import Pagination from '@/components/commons/Pagination'
import axios from '@/axios'

export default {
  name: 'SuppliersPage',

  components: {
    ListSuppliers,
    Pagination
  },

  data: () => ({
    isParentPage: false,
    excelContent: []
  }),

  computed: {
    pageCount () {
      return this.$store.state.suppliers.list.pager ? this.$store.state.suppliers.list.pager.pageCount : 1
    },

    ...mapState({
      loading: state => state.categories.isProcessing,
      title: state => state.categories.current.title,
      body: state => state.categories.current.body,
      image: state => state.categories.current.media,
      link: state => state.categories.current.link
    }),

      Listesuppliers() { 
         return this.$store.state.suppliers.list.fournisseurfile
        ? this.$store.state.suppliers.list.fournisseurfile
        : ''
    },

  },

  methods: {
    async fetchExcelContent () {
      try {
        const params = { xlsx: 'true', category: this.$route.params.slug, ...this.$route.query }
        const content = await axios.get(`${process.env.VUE_APP_API_VERSION}/suppliers`, {
          params: params
        })
        this.excelContent = content.data.rows
      } catch (err) {
        // Gestion des erreurs
      } finally {
      }
    },

    async handleExportXLSX () {
      await this.fetchExcelContent()

      var sheet = this.$xlsx.utils.json_to_sheet(this.excelContent)
      var wb = this.$xlsx.utils.book_new()
      this.$xlsx.utils.book_append_sheet(wb, sheet, 'Feuille 1')
      // export Excel file
      this.$xlsx.writeFile(wb, 'fournisseurs.xlsx')
    },

    handleExpertFiches (slug) {
      this.$router.push({
        name: 'page',
        params: {
          slug: slug
        }
      })
    },

    fetchSuppliers () {
      const payload = {
        category: this.$route.params.slug,
        families: this.$route.query.famille,
        subfamilies: this.$route.query.sousfamille,
        page: this.$route.query.page,
        mySuppliers: this.$route.query.mySuppliers,
        favorites: this.$route.query.favorites,
      }

      this.$store.dispatch('requestSuppliersByParams', payload)
    },

    fetchCategoryIfExists () {
      // On vérifie s'il y a un slug de renseigné. Si oui, on requête le type de
      // catégorie  correspondant à ce slug et on définit cette page comme
      // enfant de la page liste de TOUS les fornisseurs
      if (this.$route.params.slug) {
        this.isParentPage = false
        this.$store.dispatch('requestCategoryBySlug', this.$route.params.slug)
      } else {
        this.isParentPage = true
      }
    },

    handleOpenDrawer () {
      this.$store.dispatch('drawerStatus', true)
    },

        handleClick(link){
        window.open(link, '_blank')
      }

  },

  watch: {
    $route (to, from) {
      // Si un slug est renseigné dans la page, on requête la catégorie
      // correspondant à ce slug (sauf si c'est déjà fait)
      this.fetchCategoryIfExists()

      //  Sinon on requête quoiqu'il arrive les fournisseurs. Cette fonction est
      //  appellée à n'importe quelle navigation car l'user peut très bien
      //  passer d'une page triée à la liste de tous les fournisseurs en
      //  cliquant sur le bouton "Liste des fournisseurs" dans le header
      this.fetchSuppliers()
    }
  },

  beforeMount () {
    // Requête du type de catégorie
    this.fetchCategoryIfExists()

    // Requête de l'ensemble des fournisseurs en fonction de la page
    this.fetchSuppliers()
  }
}
</script>
