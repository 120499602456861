import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  namespaced: true,
  state: {
    isPannelOpen: false,
    displayDropdown: true,
    searchTypeValue: 'suppliers',
    label: 'Fournisseurs',
    keyword: '',
    hasResults: false,
    categories: [],

    // Autocomplete
    tags: [],
    suppliers: [],
    subscribers: [],
    supplierPartners: [],
    families: [],
    articles: {},
  },

  actions,
  mutations,
  getters
})
