const isAuthenticated = state => state.isConnected
const refreshToken = state => state.refresh_token
const accessToken = state => state.access_token

const userRole = state => {
  if (state.user && state.user.roles) {
    return state.user.roles[0]
  } else {
    return 'Guest'
  }
}

export default {
  isAuthenticated,
  refreshToken,
  accessToken,
  userRole
}
