<template>
  <div class="bg-tertiary text-white px-4 py-6 rounded">
    <h2 class="uppercase font-bold mb-2">Public et pré-requis</h2>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    text () {
      return this.$store.state.articles.current.metadatas.public
    }
  }
}
</script>
