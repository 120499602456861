<template>
  <section class="subcontainer-content">
    <div class="subcontainer-content--title">
      <icon icon="documents"></icon>
      <h1>Documents adhérent</h1>
    </div>

    <div class="tabs-documents">
      <list-documents class="border-l border-black" />
    </div>

  </section>
</template>

<script>
import ListDocuments from '@/components/customs/documents/ListDocuments'

export default {
  components: {
    ListDocuments
  },

  computed: {
    isLoading () {
      return this.$store.state.documents.isProcessing
    },

    documents () {
      return this.$store.state.documents.list
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: theme('colors.gray.200');
}

.subcontainer {
  &-content {
    > * + * {
      @apply mt-3;
    }
  }
}

.tabs {
  @apply mt-8;
  .btn {
    @apply outline-none;
  }

  &-documents {
    @apply mt-8 py-8 px-16;
    @apply bg-white rounded-lg shadow-xs;
  }
}
</style>
