<template>
  <section
    v-if="affiliates.length > 0"
    class="section pt-6"
  >

    <div class="section-title" ref="companyEntities">
      <icon icon="certification"></icon>
      <h2 class="font-bold">Filiales du groupe</h2>
    </div>

    <div class="affiliates">

      <div
        v-for="(affiliate, index) in affiliates"
        :key="index"
        class="affiliates-item"
      >
        <div class="affiliates-item-title">
          <strong>{{ affiliate.title }}</strong>

          <p>SIRET : {{ affiliate.siret }}</p>
          <p>{{ affiliate.address.postal }} {{ affiliate.address.city }}<br></p>
        </div>

        <div class="affiliates-item-buttons">
          <button
            @click="handleClick(affiliate.slug)"
            class="btn btn-primary btn-small"
          >
            Voir la fiche
          </button>
          <modal>
            <button
              slot="activator"
              class="btn btn-primary btn-small"
            >
              Voir les coordonnées
            </button>
            <div class="p-6">
              <div class="mb-3">
                <strong>Adresse</strong> :<br>
                {{ affiliate.address.street }}<br>
                {{ affiliate.address.postal }} {{ affiliate.address.city }}<br>
                {{ affiliate.address.country }}
              </div>
              <div>
                <strong>Téléphone</strong> : {{ affiliate.phone }}
              </div>
            </div>
          </modal>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},

  props: {
    affiliates: {
      type: Array,
      default: () => ([]),
      required: false
    }
  },

  mounted() {
    if (this.$route.params.anchorID) {
      const refName = this.$route.params.anchorID.replace('#', '')
      this.scrollToAnchorPoint(refName)
    }
  },

  methods: {
    scrollToAnchorPoint(refName) {
        const el = this.$refs[refName]
        el.scrollIntoView({ behavior: 'smooth' })
    },

    handleClick (slug) {
      this.$router.push(`/adherent/${slug}`)
    }
  }
}
</script>

<style lang="scss">
.affiliates {
  @apply mt-8 p-8 bg-white rounded shadow-xs;

  h2 { @apply font-bold mb-6; }

  .affiliates-item {
    @apply flex items-center p-3;

    &-title, &-buttons { @apply w-1/2 };

    &-title { @apply truncate; }

    &-buttons { @apply flex justify-center; }

    a { @apply no-underline text-black; }

    button { @apply ml-5; }
  }
}
</style>
