<template>
  <section class="section grid grid-cols-3 gap-3">
    <card
      v-if="linkedin"
      class="col-span-1"
      edged
      flexed
      hoverable
      @click.native="handleClick(linkedin)">
      <span class="font-bold uppercase mr-1">Réseaux sociaux :</span>
      <img
        src="@/assets/images/icons/linkedin.svg"
        alt="Logo de LinkedIn"
        width="75px">
    </card>

    <card
      v-if="document && $route.name === 'subscriberPresentation'"
      class="col-span-1"
      edged
      flexed
      hoverable
      @click.native="handleClick(document)">
      <icon icon="presentation-entreprise"></icon>
      <span class="font-bold uppercase">Présentation entreprise</span>
    </card>

    <card
      v-if="website"
      class="col-span-1"
      edged
      flexed
      hoverable
      @click.native="handleClick(website)">
      <icon icon="curseur-orange"></icon>
      <span class="font-bold">Accéder au site web</span>
    </card>

    <card
      v-if="metadatas.chanel_youtube"
      class="col-span-1"
      edged
      flexed
      hoverable
      @click.native="handleClick(metadatas.chanel_youtube)"
      >
      <img
        src="@/assets/images/icons/youtube.svg"
        alt="Logo de Youtube"
        width="32px">
      <span class="font-bold">  Accéder à la chaine YouTube</span>
    </card>

    <card
      v-if="metadatas.custom_link"
      class="col-span-1"
      edged
      flexed
      hoverable
      @click.native="handleClick(metadatas.custom_link[0].uri)"
      >
      <span class="font-bold">  {{ metadatas.custom_link[0].title }}</span>
    </card>

    <modal v-if="youtubeVideoId || video">
      <card
        class="col-span-1"
        slot="activator"
        edged
        flexed
        hoverable
      >
        <icon icon="voir-la-video" size="lg"></icon>
        <span class="font-bold">{{ metadatas.videoLinkText }}</span>
      </card>
      <iframe
        v-if="youtubeVideoId"
        :style="{ 'width': '100%' }"
        height="315"
        :src="'https://www.youtube.com/embed/' + youtubeVideoId"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen />
      <video
        v-else
        :src="video"
        controls
      />
    </modal>

    <card
      v-if="metadatas.members"
      class="col-span-1"
      edged
      flexed>
      <icon icon="plus-orange"></icon>
      <span class="font-bold uppercase">De {{ metadatas.members }} {{ $route.name === 'supplierPresentation' ? 'adhérents utilisateurs' : 'fournisseurs utilisés' }}</span>
    </card>

    <!-- <card
      class="col-span-1"
      edged
      flexed>
      <span class="font-bold mr-1">C.A. achat avec le fournisseur :</span>
      <h2 class="text-xl font-bold">
        {{ metadatas.sales }} €
      </h2>
    </card> -->
  </section>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  computed: {
    youtubeVideoId() {
      if(this.metadatas.youtube) {
        const videoId = this.metadatas.youtube.split('v=')[1];
        if(videoId) {
          var ampersandPosition = videoId.indexOf('&');
          if(ampersandPosition != -1) {
            return videoId.substring(0, ampersandPosition);
          }
          return videoId
        }
      }
      return '';
    },
  },

  props: {
    linkedin: {
      type: String,
      default: '',
      required: false
    },

    website: {
      type: String,
      default: '',
      required: false
    },

    video: {
      type: String,
      default: '',
      required: false
    },

    document: {
      type: String,
      default: '',
      required: false
    },

    metadatas: {
      type: Object,
      default: () => ({
      }),
      required: false
    }
  },

  methods: {
    handleClick (link) {
      if (link.startsWith('http')) {
        window.open(link, '_blank')
      } else {
        window.open('https://' + link, '_blank')
      }
    }
  }
}
</script>
