<template>
  <sheet variant="subscriber" />
</template>

<script>
import Sheet from '@/components/customs/sheet'

export default {
  components: {
    Sheet
  }
}
</script>
