import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  namespaced: true,

  state: {
    listing: [],
    filters: {
      default: {
        label: 'Filtrer par',
        items: []
      }
    },
  },

  actions,
  mutations : { ...mutations },
  getters
})
