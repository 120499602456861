<template>
  <div
    class="banner"
    v-swiper:bannerSwiper="swiperOptions">
    <div class="swiper-wrapper">
      <list-banner-item
        class="swiper-slide"
        v-for="(slide, index) in slides"
        :key="index"
        :slide="slide" />
    </div>

    <div class="swiper-button-prev swiper-button-black"></div>
    <div class="swiper-button-next swiper-button-black"></div>
  </div>
</template>

<script>
import ListBannerItem from './CardBannerListItems'

export default {
  components: {
    ListBannerItem
  },

  props: {
    slides: {
      type: Array,
      default: () => ([]),
      required: true
    }
  },

  data: () => ({
    swiperOptions: {
      loop: true,
      autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  })
}
</script>
