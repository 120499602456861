const SET_CONTENT_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_DASHBOARD_CARDS = (state, object) => {
  state.dashboard[object.type] = object.data
}

export default {
  SET_CONTENT_PROCESSING,
  SET_DASHBOARD_CARDS
}
