<template>
  <div>
    <component :is="layout">
      <router-view></router-view>
    </component>
    <PrimeToast position="bottom-right" />
  </div>
</template>

<script>

export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  }
}
</script>
