<template>
  <Card
    id="sessions"
    leftTitle
    dense
    edged>
    <template slot="header">
      <h2 class="uppercase">Sessions</h2>
    </template>

    <template>
      <div
        v-if="sessions.length > 0"
        class="sessions">
        <div
          class="sessions-item"
          v-for="(session, index) in sessions"
          :key="index">
          <div class="sessions-item--title">
            <h3>{{ session.dateTime }}</h3>
          </div>
          <div class="sessions-item--location justify-center">
            <span class="uppercase">{{session.location }}</span>
          </div>
          <div class="sessions-item--availability">
            <span class="italic">{{ session.available && session.endTime > session.now ? 'Il reste des places disponibles' : 'Il ne reste plus de place disponible pour cette session' }}</span>
          </div>
          <div class="sessions-item--subscription justify-center">
            <button
              @click="handleSubscribe(session.dateTime)"
              class="btn btn-tertiary"
              :class="{ 'disabled' : !session.available || session.endTime < session.now }">
              S'inscrire
            </button>
          </div>
          <div
            :class="{ 'opacity-0': !session.body }"
            class="sessions-item--more justify-end">
            <button
              class="activator"
              @click="handleClick(index)">
              {{ active !== index ? '?' : 'X'}}
            </button>
          </div>
          <div
            v-if="active === index"
            class="sessions-item--text"
            v-html="session.body" />
        </div>
      </div>

      <div
        v-else
        class="sessions">
        <div class="sessions-item">
          <div class="sessions-item--title">
            Aucune session n'est disponible pour le moment.
          </div>
          <div class="sessions-item--subscription justify-end">
            <router-link
              to="/formulaire"
              class="btn btn-tertiary">
              Demander un devis
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  data: () => ({
    active: undefined
  }),

  methods: {
    handleClick (index) {
      if (this.active === index) {
        this.active = ''
      } else {
        this.active = index
      }
    },

    handleSubscribe (eventDate) {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'S\'inscrire à l\'évènement',
            event: this.title,
            eventDate: eventDate,
            receiverEmail: 'mercurial@mercurial.fr',
            receiverName: 'Équipe Mercurial',
            isFormation: true
          }
        })
    }
  },

  computed: {
    sessions () {
      return this.$store.state.articles.current.sessions
    },

    title () {
      return this.$store.state.articles.current.title
    }
  }
}
</script>

<style lang="scss" scoped>
.sessions {
  &-item {
    @apply flex flex-row flex-wrap items-center justify-between;
    @apply bg-gray-200 mb-3 ;

    > * {
      @apply py-2 px-4;
      @apply flex-1 flex;
    }

    .activator {
      @apply rounded-full bg-white border border-dark ;
      @apply h-8 w-8 leading-4;
      @apply text-xl;
    }

    &--text {
      @apply bg-white border p-4;
      flex-basis: 100%;
    }
  }
}
</style>
