const SET_NAVBAR = (state, status) => {
  state.isNavbarOpen = status
}

const SET_DRAWER = (state, status) => {
  state.isDrawerOpen = status
}

const SET_PREVIOUS_ROUTE = (state, name) => {
  state.previousRoute = name
}

const SET_PAGINATION = (state, page) => {
  state.pagination = page
}

const SET_NAVIGATION_TYPE = (state, type) => {
  state.navigationType = type
}

export default {
  SET_NAVBAR,
  SET_DRAWER,
  SET_PREVIOUS_ROUTE,
  SET_PAGINATION,
  SET_NAVIGATION_TYPE
}
