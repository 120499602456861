<template>
  <div class="toolbar-access">
    <m-dropdown
      v-if="userRole === 'subscriber'"
      left >
      <template v-slot:activator>
        <button
          class="btn btn-primary suppliers"
          @click="handleClickButtonSupplier">
          Liste des fournisseurs
        </button>
      </template>

      <div
        class="access-list">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="category-item"
          @click="handleClickCategory(category.slug)">
          <img
            :src="category.media.source"
            :alt="category.media.alt"
            class="category-item--image">

          <h4 class="category-item--title">
            {{ category.title }}
          </h4>
        </div>
      </div>

      <div
        class="access-shortcuts">
        <button
          @click="handleMySuppliers"
          class="btn btn-small btn-secondary btn-block">
          <icon icon="mes-fournisseurs"></icon>
          <span>Mes fournisseurs</span>
        </button>

        <button
          @click="handleFavorites"
          class="btn btn-small btn-secondary btn-block">
          <icon icon="fournisseurs-reperes"></icon>
          <span>Fournisseurs repérés</span>
        </button>
      </div>
    </m-dropdown>
    <button
      class="btn btn-primary subscribers"
      @click="handleClickButtonSubscribers">
      Liste des adhérents
    </button>
  </div>
</template>

<script>
import MDropdown from '@/components/commons/Dropdown'

export default {
  components: {
    MDropdown
  },

  computed: {
    userRole () {
      return this.$store.getters.userRole
    },

    categories () {
      return this.$store.state.categories.list
    }
  },

  methods: {
    handleClickButtonSupplier () {
      this.$router.push('/fournisseurs').catch(() => {})
    },

    handleClickButtonSubscribers () {
      this.$router.push('/adherents').catch(() => {})
    },

    handleClickCategory (link) {
      this.$router.push(`/fournisseurs/${link}`).catch(() => {})
    },

    handleMySuppliers () {
      this.$router.push(`/fournisseurs?mySuppliers=1`).catch(() => {})
    },

    handleFavorites () {
      this.$router.push(`/fournisseurs?favorites=true`).catch(() => {})
    }
  },

  beforeMount () {
    if (this.$store.state.categories.list.length <= 0 && this.$store.getters.isAuthenticated && this.$store.getters.userRole === 'subscriber') {
      this.$store.dispatch('requestCategories')
    }
  }
}
</script>

<style lang="scss" scoped>
$dropdown_width: 320px;

.btn.suppliers {
  @apply mr-4
}

.btn.subscribers {
  @apply mr-6
}

.access {
  &-list {
    width: $dropdown_width;
    @apply flex flex-wrap;

    .category-item {
      @apply w-1/2 flex items-center justify-start;
      @apply p-3 border-b border-gray-300 cursor-pointer;

      &:hover {
        @apply bg-gray-300 bg-opacity-50;
      }

      &--image {
        @apply rounded-lg mr-3 bg-gray-400;
        width: 36px;
        height: 36px;
      }

      &--title {
        @apply uppercase text-xs;
        // La taille maxi est calculée en fonction de 320px
        // moins les marges et les tailles des images, cela permet
        // d'afficher des carrés gris si chargement de l'image
        // Ne pas toucher !
        max-width: 92px
      }
    }
  }

  &-shortcuts {
    @apply flex p-3;

    > .btn {
      @apply w-1/2;

      &:first-child {
        @apply mr-3;
      }

      &:last-child {
        @apply ml-3;
      }
    }
  }
}
</style>
