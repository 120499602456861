<template>
  <section
    id="amChart"
    :class="['container-content', 'relative']">
      <button v-if="this.$route.query.tab === 'supplier'"
        type="button" class="btn btn-info action_btn downloadXls" v-on:click="downloadCSVData">
          Télécharger la liste complète
      </button>
      <button
        v-if="this.displayDatas.isDetail"
        @click="fetchCardData()"
        :class="[
        'btn',
        'btn-small',
        'btn-outline--primary',
        'mb-4',
        'lg:absolute',
        'top-0',
        'right-0',
        'btn_back-to-original',
        'z-50',
        ]"
      >
        Retour au graphique d'origine
      </button>
    <div
      class="chart"
      ref="chart">
    </div>
    
  </section>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

/* eslint-disable */
// On va respecter la doc de la lib et ne pas suivre notre linter :
// https://www.amcharts.com/docs/v4/getting-started/integrations/using-vue-js/
// import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import axios from '@/axios'

am4core.useTheme(am4themes_animated)

export default {
  data: () => ({
    chartTitle: '',
    displayDatas: {},
    displayDatasExport: {},
  }),

  computed: {
    isAffiliates(){
      return this.$store.state.subscribers.isAffiliates
    }
  },

  watch:{
    $route (){
      this.fetchCardData().then(
        this.$forceUpdate()
      )
    },

    '$store.state.subscribers.isAffiliates'(){
      this.fetchCardData().then(
        this.$forceUpdate()
      )
    },

    displayDatas: {
      handler(val){
        if (val.error){
          console.error(val.error)
        } else {
          this.displayChart(val)
        }
      },
      deep: true
    },
  },

  methods: {
    async fetchCardData(detail = false){
      const base_url = `${process.env.VUE_APP_API_VERSION}/dashboard/ca/`;
      const chart_tab = this.$route.query.tab + '/';
      const chart_context = this.$route.params.slug ? this.$route.params.slug : 'headquarter';

      try {
          const url =
            this.$store.getters.userRole === 'supplier' ?
            base_url + chart_tab :
            (detail && (detail.context === 'year' || detail.context === 'month') && detail.id) ?
            base_url + chart_tab + 'detail/' + detail.id :
            (detail && (detail.context === 'family' || detail.context === 'supplier') && detail.id) ?
            base_url + detail.context + '/detail/' + detail.id :
            base_url + chart_tab + (this.isAffiliates ? chart_context : '');

          const response = await axios.get(url);
          response.data.isDetail = !!detail;

          if (response.data?.chartDatas && response.data?.chartDatas?.length > 0){
            this.displayDatas = response.data;
            this.displayDatasExport = response.data.chartDatasExport;

            if(this.$route.query.tab === 'family' || this.$route.query.tab === 'supplier'){
              this.sortData(this.displayDatas.chartDatas)
            }
          } else {
            this.displayDatas = {
              error: 'Données manquantes pour ce graphique.'
            };

            window.alert('Données manquantes pour ce graphique.');
          }

          this.$forceUpdate()
      } catch (err) {
        console.error(err)
      }
    },

    sortData(chartData) {
      chartData.sort(function(a, b) {
        return a.ybis - b.ybis;
      });
    },

    arrangeColumns(chart) {
      const xAxis = chart.xAxes.values[0];
      let series = chart.series.getIndex(0);
      let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);

      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function(series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function(series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta

            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    },

    createSeries(chart, value, name, type, unite, colorCta) {
      let series = null;

      switch (type) {
        case 'CurvedColumnSeries':
          series = chart.series.push(new am4charts.CurvedColumnSeries())
          break;
        case 'PieChart':
          series = chart.series.push(new am4charts.PieSeries())
          break;
        case 'LineSeries':
          series = chart.series.push(new am4charts.LineSeries());
          break;
        case 'XYChart':
        case 'XYChartH':
        case 'XYChartHDetail':
        default:
          series = chart.series.push(new am4charts.ColumnSeries())
      }

      series.name = name

      series.hiddenState.properties.visible = true;

      // Tooltip
      if ('XYChartH' == type || 'XYChartHDetail' == type) {
        series.tooltipText = "{categoryY}: {valueX} " + unite
        series.tooltip.dx = 0
        series.tooltip.pointerOrientation = 'right'
        series.dataFields.valueX = value;
        series.dataFields.categoryY = 'x';
      } else {
        series.tooltipText = "{categoryX}: {valueY}" + unite
        series.dataFields.valueY = value;
        series.dataFields.categoryX = 'x'

        if (series.columns){
          series.columns.template.width = am4core.percent(100);
        }

        series.events.on("hidden", () => { this.arrangeColumns(chart) });
        series.events.on("shown", () => { this.arrangeColumns(chart) });
      }

      const bullet = series.bullets.push(new am4charts.LabelBullet());
      const ctaDetails =
        `
          <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px" height="20px" class="mx-auto my-0">
                <g>
                  <g>
                    <g>
                      <g>
                        <circle fill="${colorCta}" cx="10" cy="10" r="10"/>
                        <path fill="#ffffff" d="M10,20A10,10,0,1,1,20,10,10,10,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Z"/>
                      </g>
                      <g fill="#ffffff">
                        <path fill="#ffffff" d="M10.08,16.36a1,1,0,0,1-1-1V5.43a1,1,0,0,1,2,0v9.93A1,1,0,0,1,10.08,16.36Z"/>
                        <path fill="#ffffff" d="M15.08,11.4h-10a1,1,0,0,1,0-2h10a1,1,0,0,1,0,2Z"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
          </div>
        `;

      bullet.label.truncate = false;
      bullet.label.hideOversized = false;
      bullet.label.interactionsEnabled = false;
      bullet.label.keepTargetHover = true;
      if ('XYChartH' === type){
        const hoverState = series.columns.template.states.create("hover");
        hoverState.properties.fillOpacity = 1;
        hoverState.properties.tension = 0.4;

        bullet.label.horizontalCenter = "left";
        bullet.label.dx = 5;

        if (this.$store.getters.userRole === 'supplier' || !this.isAffiliates){
        bullet.label.html =
          `<div class="flex align-center justify-center">
            <div>
              {valueX} ${unite}
            </div>
          </div>`
        } else {
        bullet.label.html =
          `<div class="flex align-center justify-center">
            <div class="pr-2">
              ${ctaDetails}
            </div>
            <div>
              {valueX} ${unite}
            </div>
          </div>`
        }
      } else if ('XYChartHDetail' === type) {
        bullet.label.horizontalCenter = "left";
        bullet.label.dx = 5;
        bullet.label.html = `{valueX} ${unite}`;
      } else if ('CurvedColumnSeries' === type && this.isAffiliates) {
        bullet.dy = 30;
        bullet.label.html =
          this.$store.getters.userRole === 'subscriber' ?
          `{valueY} ${unite} <br />` + ctaDetails :
          `{valueY} ${unite}`;
        bullet.label.fill = am4core.color('#ffffff')
      } else if ('ColumnSeries' === type && this.isAffiliates) {
        bullet.label.html =
          this.$store.getters.userRole === 'subscriber' ?
          `{valueY} ${unite} <br />` + ctaDetails :
          `{valueY} ${unite}`;

          bullet.dy = 3;

        bullet.label.fill = am4core.color("000000")
      } else if ('LineSeries' === type) {
        bullet.label.html = (this.$store.getters.userRole === 'subscriber' && this.isAffiliates) ?
        ctaDetails :
        `<div style="width: 5px; height: 5px; background: #EA5906; border-radius: 50%; content: '';"></div>`;
        bullet.label.fill = am4core.color('#ffffff')
      } else {
        if (this.isAffiliates){
          bullet.dy = 30;
          bullet.label.text = '{valueY} '+ unite;
          bullet.label.fill = am4core.color('#ffffff')
        } else {
          bullet.dy = -10;
          bullet.label.text = '{valueY} '+ unite;
          bullet.label.fill = am4core.color('#000000')
        }

      }

      // Add '+' details button behaviour
      if ('CurvedColumnSeries' == type || 'ColumnSeries' == type || 'LineSeries' == type || 'XYChartH' == type){
        bullet.label.interactionsEnabled = true;
        bullet.label.clickable = true;
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        bullet.label.events.on("hit", (ev) => {
          const detail = {
            context: 'XYChartH' == type ?
            this.$route.query.tab :
            'ColumnSeries' == type ?
            'month' :
            'year',
            id: 'XYChartH' == type ?
            ev.target.dataItem.dataContext.targetSlug :
            'ColumnSeries' == type ?
            ('0' + (months.indexOf(ev.target.dataItem.categoryX) + 1)).slice(-2) :
            ev.target.dataItem.categoryX,
          };

          this.fetchCardData(detail)
        });
      }

      return series;
    },

    /** Global function */
    displayChart() {
      // Init Chart Object
      const chart =
        this.displayDatas.chartType === 'PieChart' ?
        am4core.create(this.$refs.chart, am4charts.PieChart) :
        am4core.create(this.$refs.chart, am4charts.XYChart);

      // Language
      chart.language.locale = am4lang_fr_FR;

      // Colors
      chart.colors.list = [
        am4core.color("#EA5906"),
        am4core.color("#09255C")
      ];

      // Title
      const title = chart.titles.create();
      const chartTitle = this.displayDatas?.chartTitle || '';

      Object.assign(title, {
        text: chartTitle.toUpperCase(),
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 30,
      });

      // Legend
      const legend = chart.legend = new am4charts.Legend();
      legend.position = 'top';
      legend.paddingBottom = 20;
      legend.labels.template.maxWidth = 95;

      // Menu Grey Top-left button
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = 'left';
      chart.exporting.menu.items = [{
        label: 'exporter',
        defaultStyles: false,
        "menu": [
          { "type": "pdf", "label": "PDF" },
          { "type": "print", "label": "Print",  },
          { "type": "xlsx", "label": "Excel" }
        ]
      }];

      // MaskBullets
      chart.maskBullets = false;

      // Axes
      let yAxis = null
      let xAxis = null
      if (this.displayDatas.chartType == 'XYChartH' || this.displayDatas.isDetail) {
        yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "x";
        yAxis.renderer.grid.template.location = 0;
        yAxis.renderer.minGridDistance = 10;
        yAxis.cursorTooltipEnabled = false;
        xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
      } else {
        xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'x'
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.cellStartLocation = 0.05
        xAxis.renderer.cellEndLocation = 0.95

        yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
      }

      // Series
      chart.data = this.displayDatas ?
      JSON.parse(JSON.stringify(this.displayDatas.chartDatas)) :
      [];

      if (this.displayDatas.isDetail){
        chart.data = chart.data.sort((a, b) => {
            return a.y - b.y;
        });
      }

      let isYSerie = false;

      if (
        this.displayDatas.SerieNameA &&
        this.displayDatas.chartDatas.length > 0 &&
        'y' in this.displayDatas.chartDatas[0]
      ){
        isYSerie = true;

        this.createSeries(
          chart,
          'y',
          this.displayDatas.SerieNameA,
          this.displayDatas.isDetail ? 'XYChartHDetail' : this.displayDatas.chartType,
          this.displayDatas.chartUnite,
          '#EA5906'
        );
      }

      if (
        this.displayDatas.SerieNameB &&
        this.displayDatas.chartDatas.length > 0 &&
        this.displayDatas.chartDatas.every(objet => 'ybis' in objet)
      ){
        this.createSeries(
          chart,
          'ybis',
          this.displayDatas.SerieNameB,
          this.displayDatas.isDetail ? 'XYChartHDetail' : this.displayDatas.chartType,
          this.displayDatas.chartUnite,
          isYSerie ? '#09255C' : '#EA5906',
        );
      }

      // Scrollbar
      if (this.displayDatas.chartType == 'XYChartH' || this.displayDatas.isDetail) {
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.parent = chart.bottomAxesContainer;
      } else {
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;
      }

      // Cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.maxTooltipDistance = 0;
      chart.cursor.tooltipDisabled = true;
      chart.logo.disabled = true
      const cellSize = 75;

        // const cellSize = 75;
        // const height = cellSize * chart.data.length;
        // chart.svgContainer.htmlElement.style.height = height < 300 ? "300px" : height + "px";

      if(this.displayDatas.chartType === 'XYChartH' && !this.displayDatas.isDetail) {
        const categoryAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        const adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

        // get current chart height
        const targetHeight = chart.pixelHeight + adjustHeight;

        // Set it on chart's container
        chart.svgContainer.htmlElement.style.height = targetHeight < 300 ? "300px" : targetHeight + "px";
      } else if (this.displayDatas.isDetail) {
        const height = cellSize * chart.data.length;

        chart.svgContainer.htmlElement.style.height = height < 300 ? "300px" : height + "px";
      } else if(window.screen.width >= 1200) {
        chart.svgContainer.htmlElement.style.height = "600px";
      } else {
        chart.svgContainer.htmlElement.style.height = "300px";
      }

      // if(this.$route.query.tab === 'month') {
      //   // Pre-zoom the chart
      //   chart.events.on("ready", function () {
      //     xAxis.zoomToIndexes(
      //       6,
      //       12,
      //       false,
      //       true
      //     );
      //   });
      // }


      if (this.displayDatas.chartType == 'XYChartH') {
        chart.exporting.dataFields = {
      "x": "Fournisseur",
      "y":  this.displayDatas.SerieNameA,
      "ybis": this.displayDatas.SerieNameB
      }
      }
      else {
        chart.exporting.dataFields = {
      "x": "Période",
      "y":  this.displayDatas.SerieNameA,
      "ybis": this.displayDatas.SerieNameB
      }

      }
      this.chart = chart
    },

    async fetchExcelContent () {
      try {
        const params = {
          xlsx: 'true',
        }
        const content = this.displayDatasExport;
        this.excelContent = content;
      } catch (err) {
        // Gestion des erreurs
      } finally {
      }
    },

    async downloadCSVData () {
      await this.fetchExcelContent()
      var sheet = this.$xlsx.utils.json_to_sheet(this.excelContent)
      var wb = this.$xlsx.utils.book_new()
      this.$xlsx.utils.book_append_sheet(wb, sheet, 'Feuille 1')
      // export Excel file
      this.$xlsx.writeFile(wb, 'ca-mercurial-fournisseurs-.xlsx')
    },

  },

  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },

  mounted () {
    this.fetchCardData()
  }
}
</script>

<style lang="scss" scoped>
.container {
  &-content {
    @apply flex flex-col items-center justify-center;
    @apply bg-white p-4 rounded shadow-xs;

    .chart {
      width: 100%;
    }
  }
}

.btn_back-to-original {
  @media screen and (min-width: 1220px){
    transform: translate(-20px, 20px)
  }
}
</style>
