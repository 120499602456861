<template>
  <card
    edged
    left-title>
    <template
      slot="header"
      class="text-left">
      <icon icon="ordre-jour"></icon>
      <h2 class="uppercase">Ordre du jour</h2>
    </template>

    <p>{{ text }}</p>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  props: {
    text: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
