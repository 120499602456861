<template>
  <div class="form_engine-select">
    {{ label }}
  </div>
</template>

<script>
export default {
  computed: {
    label () {
      return this.$store.state.engine.label
    }
  }
}
</script>

<style lang="scss" scoped>
.form_engine-select {
  @apply uppercase cursor-default;
}
</style>
