<template>
  <section class="tabs">
    <div
      v-for="(tab, index) in displayTabsAccordingToRole"
      :key="index"
      :class="{ 'active' : tab.value === $route.query.tab }"
      class="btn btn-dark btn-tab"
      @click="handleTab(tab.value)">
      {{ tab.label }}
    </div>
  </section>
</template>

<script>
import subscriberTabs from './../data/subscriberTabs.js'
import supplierTabs from './../data/supplierTabs.js'

export default {
  computed: {
    displayTabsAccordingToRole () {
      return this.$store.getters.userRole === 'subscriber' ? subscriberTabs : supplierTabs
    }
  },

  methods: {
    handleTab (value) {
      let queryVal = ''
      value ? queryVal = value : queryVal = 'offers'
      this.$router.push({ query: { tab: queryVal } }).catch(() => {})
      this.$scrollTo('#amChart', 0, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  @apply flex flex-wrap items-center justify-start;
  @apply my-4;

  @screen lg {
    @apply mt-10 mb-8;
    @apply flex-no-wrap justify-center;
  }
}

.btn {
  @apply cursor-pointer;
  @apply font-normal normal-case text-center text-sm;
  @apply m-1 p-2;
  width: calc(50% - theme('spacing.2'));

  &:after {
    @apply hidden;
  }

  @screen lg {
    max-width: 220px;
    @apply text-base;
    @apply mb-0 p-3;

    &:after {
      @apply block;
    }
  }
}
</style>
