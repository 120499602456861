<template>
  <div class="wrapper">
    <div class="container">
      <section class="container-header">
        <div class="container-header--title">
          <icon
            icon="actualites"
            size="xl" />
          <h1>Fil d'actualités</h1>
        </div>

        <p class="container-header--description">Retrouvez dans notre fil d'actualités l'ensemble des nouvelles du réseau Mercurial, les articles thématiques de conseil, les formations et l'agenda de nos évènements (salons, webinars...)</p>

        <div class="container-header--sorter">
          <button
            class="btn"
            :class="[isDisplayingAllArticles ? 'btn-outline--dark' : 'btn-dark']"
            @click="handleSort()">
            Tous
          </button>

          <button
            class="btn"
            :class="[isSortingFormations ? 'btn-outline--dark' : 'btn-dark']"
            @click="handleClickFormations">
            Formations
          </button>

          <button
            v-for="(category, index) in categories"
            :key="index"
            class="btn"
            :class="[category.url === $route.query.filtre ? 'btn-outline--dark' : 'btn-dark']"
            @click='handleSort(category.url)'>
            {{ category.label }}
          </button>

          <!--<aside class="container-header&#45;&#45;aside">
            <button
              class="btn btn-small btn-outline&#45;&#45;primary"
              @click="handleDownload">télécharger l'agenda des évènements Mercurial</button>
          </aside>-->
        </div>
      </section>
    </div>

    <div class="background">
      <div class="container">
        <list-articles :formations="isSortingFormations"/>
      </div>
      <pagination
        :page-count="pageCount"
      />
    </div>
  </div>
</template>

<script>
import ListArticles from '@/components/customs/articles/ListArticles.vue'
import Pagination from '@/components/commons/Pagination'

export default {
  components: {
    ListArticles,
    Pagination
  },

  computed: {
    categories () {
      return this.$store.state.articles.categories
    },

    filter () {
      return this.$store.state.articles.filters
    },

    pageCount () {
      return this.$store.state.articles.articleList.pager ? this.$store.state.articles.articleList.pager.pageCount : 1
    },

    // On doit séparer les deux types d'articles car les formations ne sont pas
    // des articles à proprement parlé mais sont présents dans la liste.
    isSortingFormations () {
      return this.$route.query.filtre === 'formations'
    },

    // Cas dans lequel on montre tous les articles (on a reset tous les filtes)
    isDisplayingAllArticles () {
      return !this.$route.query.filtre && !this.$route.query.categorie
    }
  },

  methods: {
    /**
     * On ajoute à l'URL l'id du type d'article qu'on souhaite requêter.
     * On définit également quelle est le type pour définir l'état actif du bouton.
     */
    handleSort (value) {
      this.$router.push({
        query: {
          filtre: value
        }
      }).catch(() => {})
    },

    handleClickFormations () {
      this.$router.push({ name: 'articles', query: { filtre: 'formations' }})
    },

    handleDownload () {
      // méthode de téléchargement duf cihier mercurial
    }
  },

  beforeMount () {
    // On requête d'abord les filtres possibles
    this.$store.dispatch('requestArticlesFilters')
  }
}
</script>

<style lang="scss" scoped>
.container {
  &-header {
    @apply my-8;

    &--title {
      @apply flex items-center justify-start;
      @apply font-bold text-xl uppercase;
      @apply mb-4;
    }

    &--description {
      @apply opacity-50 mb-6;
      @apply text-sm;
      max-width: 800px;
    }

    &--sorter {
      .btn {
        @apply m-2;
      }
    }

    &--aside {
      @apply float-right;
    }
  }
}
</style>
