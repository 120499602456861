<template>
<!--  <div-->
<!--    class="wrapper wrapper-custom"-->
<!--    v-html="template">-->
<!--  </div>-->
  <RenderHtml :html="template"></RenderHtml>
</template>

<script>
import axios from '@/axios'
import RenderHtml from "@/components/templates/RenderHtml";

export default {
  components: {
    RenderHtml
  },

  data: () => ({
    template: ''
  }),

  methods: {
    async fetchHtmlTemplate () {
      try {
        const slug = this.$route.params.slug
        const response = await axios.get(`${process.env.VUE_APP_API_VERSION}/page/${slug}`)
        this.template = response.data.html
      } catch (err) {
        console.error(err)
      } finally {
        // Code à exécuter quoiqu'il arrive ici
      }
    }
  },

  beforeMount () {
    this.fetchHtmlTemplate()
  }
}
</script>

<style lang="scss">
/* On a ici tout le style relatif à la page custom, ce style est unique à cette
page et n'est appelé que lorsqu'on est dans cette vue. (classe "wrapper-custom") */
.wrapper {
  @apply py-4;
  &-custom {
    .title-1 {
      @apply text-center uppercase font-bold text-xl;
      @apply py-4;
    }

    .title-2 {
      @apply uppercase font-bold text-lg;
    }

    .container, .subcontainer {
      @apply py-8;
    }

    .text_image {
      @apply grid grid-cols-1 gap-8;
      @screen lg {
        @apply grid-cols-2;
      }
    }

    .presentation {
      @apply flex flex-col items-center justify-between;

      @screen lg {
        @apply flex-row;
      }

      .subtitle-2 {
        color: var(--color-primary);
      }

      .img {
        @apply mb-8;
        width: initial;
        //flex: 1;
      }

      .text {
        @apply px-8;
        flex: 3;
      }

      .infos {
        flex: 1;
        @apply mt-8;
        > * + * {
          @apply mt-4;
        }
      }
    }

    .img {
      @apply w-full;
      &-left {
        order: -1;
      }
    }

    .paragraph {
      @apply mt-8;
    }

    .buttons {
      @apply mt-8;
      .btn {
        @apply m-2;
      }
    }

    .card {
      @apply flex flex-row items-center justify-start;
      @apply p-3 mt-8;

      .img {
        @apply m-8;
        @apply rounded-full h-32 w-32;
      }
    }

    span.fleche-blanche:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 7px;
      background: url(../../../assets/images/icons/fleche-blanche.svg) no-repeat center center;
      vertical-align: middle;
    }

    span.linked-in-icone:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 7px;
      background: url(../../../assets/images/icons/linkedin-blue.svg) no-repeat center center;
    }
  }

  .certifications {
    a {
      text-decoration: none;

      &:hover {
        color: #000;
      }

      .text {
        .paragraph {
          margin: 0;
        }
      }
    }
  }

  .gallery .images {
    @apply flex flex-col flex-wrap items-center justify-between;
    @apply mt-8;

    &:after {
      content: "";
      width: 32%;
    }

    @screen xs {
      @apply flex-row;
    }

    .img {
      @apply mb-5;

      @screen xs {
        width: 32%;
      }
    }
  }

  .indexes {
    &-list {
      @apply flex flex-col justify-between flex-wrap;

      @media (min-width: 481px) {
        @apply flex-row;
      }

      .index {
        @media (min-width: 481px) {
          width: 48%;
        }

        &:not(:last-of-type) {
          @apply mb-6;
        }
      }
    }
  }

  .video {
    position: relative;
    padding-bottom: 30%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

</style>
