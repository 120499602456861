<template>
  <section class="subcontainer-header">
    <span>
      {{ title }}
    </span>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
