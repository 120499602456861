/**
 * Permet d'ouvrir ou fermer le menu de navigation
 * @param {*} Boolean si la valeur est true alors la navbar s'ouvre
 */
const navbarStatus = ({ commit }, status) => {
  commit('SET_NAVBAR', status)
}

/**
 * Permet d'ouvrir ou fermer le drawer sur le côté en mobile
 * @param {*} Boolean si la valeur est true alors le drawer s'ouvre
 */
const drawerStatus = ({ commit }, status) => {
  commit('SET_DRAWER', status)
}

/**
 * Permet de connaître la route précédente dans le cas de Children
 * @param {*} String le from
 */
const savePreviousRoute = ({ commit }, from) => {
  commit('SET_PREVIOUS_ROUTE', from)
}

/**
 * On sauvegarde le type de navigation dans le store pour savoir depuis quel
 * fichier de données charger les différents liens de navigationd ans le drawer.
 * @param {*} type
 */
const updateNavigationType = ({ commit }, type) => {
  commit('SET_NAVIGATION_TYPE', type)
}

export default {
  navbarStatus,
  drawerStatus,
  savePreviousRoute,
  updateNavigationType
}
