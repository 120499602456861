<template>
  <footer
    class="footer"
    :class="{ 'has-drawer' : hasDrawer}">
    <div class="footer-wrapper">
      <template v-for="(item, index) in items">
        <router-link
          class="footer-link"
          :key="index"
          :to="{ name: item.link, params: item.params, query: item.query }">
          {{ item.text }}
        </router-link>
        <span
          class="footer-separator"
          v-if="index < items.length - 1"
          :key="(item.separator)">-</span>
      </template>
    </div>
  </footer>
</template>

<script>
import footerItems from './../datas/footer.js'

export default {
  computed: {
    items () {
      return footerItems
    },

    hasDrawer () {
      return this.$route.meta.hasDrawer || this.$route.meta.hasFilters
    }
  }
}
</script>


<style lang="scss" scoped>
.has-drawer {
  @screen lg {
    @apply border-l;
    margin-left: calc(var(--drawer-size) - 1px);
  }
}
</style>
