<template>
  <div class="toolbar-logo">
    <logo />
  </div>
</template>

<script>
import Logo from '@/components/commons/Logo'

export default {
  components: {
    Logo
  }
}
</script>
