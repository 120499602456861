<template>
  <Card
    leftTitle
    dense
    edged>
    <template slot="header">
      <icon icon="objectif" />
      <h2 class="uppercase">Objectif</h2>
    </template>

    <template>
      <p class="text-lg">
        {{ text }}
      </p>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  computed: {
    text () {
      return this.$store.state.articles.current.metadatas.objectives
    }
  }
}
</script>
