<template>
  <section
    v-if="slides.length > 0"
    class="section pt-6">
    <div class="section-title">
      <icon icon="galerie-images"></icon>
      <h2 class="font-bold">Galerie d'images</h2>
    </div>

    <div
      class="block w-full">
      <div class="images">
        <div
            v-for="(image, index) in slides"
            :key="index"
            class="img">
          <modal class="relative">
            <img
              :src="image.source"
              :alt="image.alt"
              slot="activator"
            >
            <img src="/img/zoom.2d409c8e.svg" class="icon icon-md absolute right-4 bottom-4" alt="" slot="activator">
            <img
                :src="image.zoom"
                :alt="image.alt"
            >
          </modal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal
  },

  data: () => ({
    galleryOptions: {
      loop: true,
      spaceBetween: 15,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),

  props: {
    slides: {
      type: Array,
      default: () => ([]),
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.images {
  @apply flex flex-col flex-wrap items-center justify-between;
  @apply mt-8;

  &:after {
    content: "";
    width: 32%;
  }

  @screen xs {
    @apply flex-row;
  }

  .img {
    @apply mb-5;

    @screen xs {
      width: 32%;
    }
  }
}
</style>
