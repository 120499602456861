import axios from '@/axios'

/**
 * Requêter la liste des fournisseurs en fonction de paramètres
 * /!\ Attention : on utilise directement l'URL avec les query params
 * @param {Object} params Les paramètres de l'URL pour filtrer
 */
const requestSuppliersByParams = async ({ commit }, payload) => {
  commit('SET_SUPPLIERS_PROCESSING', true)
  try {
    const suppliers = await axios.get(`${process.env.VUE_APP_API_VERSION}/suppliers`, {
      params: {
        category: payload.category,
        families: payload.families,
        subFamilies: payload.subfamilies,
        page: payload.page,
        mySuppliers: payload.mySuppliers,
        favorites: payload.favorites
      }
    })

    commit('SET_SUPPLIERS_LIST', suppliers.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_SUPPLIERS_PROCESSING', false)
  }
}

const requestResultsSuppliers = async ({ commit }, payload) => {
  commit('SET_SUPPLIERS_PROCESSING', true)
  try {
    const suppliers = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/suppliers`, {
      params: payload
    })

    commit('SET_SUPPLIERS_LIST', suppliers.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_SUPPLIERS_PROCESSING', false)
  }
}

const requestSuppliersFilters = async ({ commit }, payload) => {
  try {
    const filters = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/suppliers/filters`, {
      params: payload
    })
    commit('SET_SUPPLIERS_FILTERS', filters.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

/**
 * Requêter un fournisseur en fonction de son slug
 * @param {String} slug Slug utilisé pour chercher le fournisseur
 */
const requestSupplierBySlug = async ({ commit }, slug) => {
  commit('SET_SUPPLIERS_PROCESSING', true)
  try {
    const supplier = await axios.get(`${process.env.VUE_APP_API_VERSION}/supplier/${slug}`)
    commit('SET_CURRENT_SUPPLIER', supplier.data)
  } catch (err) {
    console.error(err)
    // @todo: rediriger vers la page 404 si erreur avec un message d'erreur
    // indiquant que le fournisseur recherché n'existe pas.
  } finally {
    commit('SET_SUPPLIERS_PROCESSING', false)
  }
}

const requestBanners = async ({ commit }, slug) => {
  try {
    const banners = await axios.get(`${process.env.VUE_APP_API_VERSION}/banners`)
    commit('SET_BANNERS', banners.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

export default {
  requestSuppliersByParams,
  requestResultsSuppliers,
  requestSuppliersFilters,
  requestSupplierBySlug,
  requestBanners
}
