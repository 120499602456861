<template>
  <div
    class="card shadow-xs"
    v-if="documents.length > 0 || url">

    <header
      class="card-header p-3 justify-start"
    >
      <h2 class="uppercase">Documents de la formation</h2>
    </header>

    <hr class="spacer">

    <article
      v-if="documents.length > 0"
      class="card-content p-4 block"
    >
      <p class="mb-4">{{ text }}</p>
      <ListDocuments
        :documents="documents"
        bordered/>
    </article>

    <hr
      v-if="documents.length > 0 && url"
      class="spacer">

    <footer
      v-if="url"
      class="card-footer">
      <a
        class="btn btn-small"
        :href="url">
        Voir la vidéo de cette formation
      </a>
    </footer>
  </div>
</template>

<script>
import ListDocuments from '@/components/customs/documents/ListDocuments.vue'

export default {
  components: {
    ListDocuments
  },

  computed: {
    text () {
      return this.$store.state.articles.current.metadatas.support
    },

    documents () {
      return this.$store.state.articles.current.documents
    },

    url () {
      return this.$store.state.articles.current.medias.video.url
    }
  }
}
</script>
