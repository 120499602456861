<template>
  <section class="subcontainer-content">
    <div class="subcontainer-content--title">
      <icon icon="documents"></icon>
      <h1>Documents fournisseur</h1>
    </div>

    <!-- <div class="tabs">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ 'active' : tab.value === $route.query.tab }"
        class="btn btn-dark btn-tab"
        @click="handleTab(tab.value)">
        {{ tab.label }}
      </button>-->
      <div class="tabs-documents">
        <list-documents class="border-l border-primary" />
      </div>
      <!--</div>-->

  </section>
</template>

<script>
import ListDocuments from '@/components/customs/documents/ListDocuments'

export default {
  components: {
    ListDocuments
  },

  data: () => ({
    /*tabs: [
      {
        label: 'Offres négociées',
        value: 'offers'
      },
      {
        label: 'CGV',
        value: 'conditions'
      },
      {
        label: 'Administratif',
        value: 'administration'
      },
      {
        label: 'Qualité',
        value: 'quality'
      },
      {
        label: 'Ouverture',
        value: 'open'
      }
    ]*/
  }),

  computed: {
    isLoading () {
      return this.$store.state.documents.isProcessing
    },

    documents () {
      return this.$store.state.documents.list
    }
  },

  /*methods: {
    handleTab (value) {
      let queryVal = ''
      value ? queryVal = value : queryVal = 'offers'
      return this.$router.push({ query: { tab: queryVal } }).catch(() => {})
    },
  },

  watch: {
    $route (to, from) {
      this.fetchDocuments()
    }
  },

  beforeMount () {
    this.handleTab()
    this.fetchDocuments()
  }*/
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: theme('colors.gray.200');
}

.subcontainer {
  &-content {
    > * + * {
      @apply mt-3;
    }
  }
}

.tabs {
  @apply mt-8;
  .btn {
    @apply outline-none;
  }

  &-documents {
    @apply mt-8 py-8 px-16;
    @apply bg-white rounded-lg shadow-xs;
  }
}
</style>
