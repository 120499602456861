<template>
  <div class="subscriber-card">
    <div class="w-1/3 xl:w-1/4">
      <div class="subscriber-card_thumbnail">
        <img
          class="subscriber-card_thumbnail__image cursorPointer"
          @click="handleClick(subscriber.slug)"
          :src="subscriber.medias.thumbnail.source"
          :alt="subscriber.medias.thumbnail.alt"
          :title="subscriber.medias.thumbnail.title">
      </div>
    </div>
    <div class="w-2/3 xl:w-3/4">

      <div class="subscriber-card_infos">
        <div class="subscriber-card_infos__title">
          <h2>{{ subscriber.title }}</h2>
          <div class="flex col-gap-2">
            <work-with :collaborator="subscriber.metadatas.collaborator" class="hidden lg:block" />
            <Favorite :value="subscriber.metadatas.favorite" :entity-type="subscriber.type" :entity-id="subscriber.id" />
          </div>
        </div>
        <p class="subscriber-card_infos__house">
          {{ subscriber.affiliate }}
        </p>
        <p class="subscriber-card_infos__activity">
          {{ subscriber.activity }}
        </p>
        <p class="subscriber-card_infos__address">
          {{ subscriber.address.postal }} {{ subscriber.address.city }}
        </p>
      </div>

      <div class="subscriber-card_actions">
        <div class="w-full flex justify-between items-center">
          <button
            class="btn btn-dark"
            @click="handleClick(subscriber.slug)">Voir la fiche</button>
        </div>
      </div>

      <a
        v-if="subscriber.isParentCompany"
        class="block mt-2"
        @click="handleClick(subscriber.slug, '#companyEntities')">
        Voir les filiales du groupe
      </a>

    </div>

  </div>
</template>

<script>
import Tag from '@/components/commons/Tag.vue'
import WorkWith from "@/components/customs/suppliers/WorkWith";
import $axios from "@/axios";
import Favorite from "@/components/customs/suppliers/Favorite.vue";

export default {
  components: {
    Favorite,
    WorkWith,
    Tag
  },

  props: {
    subscriber: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  methods: {
    handleClick (slug, anchorID = false) {
      if(!anchorID) {
        this.$router.push({ name: 'subscriberPresentation', params: { slug: slug } })
      } else {
        this.$router.push({ name: 'subscriberPresentation', params: { slug: slug, anchorID: anchorID } })
      }
    }
  }
}
</script>
