<template>
  <section
    v-if="tags.length > 0 & $route.name === 'supplierPresentation'"
    class="section pt-6">
    <div class="section-title">
      <icon icon="mots-cles"></icon>
      <h2 class="font-bold">Mots clés</h2>
    </div>

    <div class="block">
      <tag
        v-for="(tag, index) in tags"
        :key="index"
        :label="tag">
      </tag>
    </div>
  </section>
</template>

<script>
import Tag from '@/components/commons/Tag.vue'

export default {
  components: {
    Tag
  },

  props: {
    tags: {
      type: Array,
      default: () => ([

      ]),
      required: false
    }
  }
}
</script>
