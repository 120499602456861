<template>
  <section
    class="subcontainer"
    v-if="pageCount > 1">
    <v-paginate
      v-model="currentPage"
      :pageCount="pageCount"
      :clickHandler="handleClick"
      :prevText="'Précédent'"
      :nextText="'Suivant'"
      :containerClass="'pagination'"
      :pageClass="'pagination-item'">
    </v-paginate>
  </section>
</template>

<script>
export default {
  props: {
    pageCount: {
      type: Number,
      default: 0,
      required: true
    }
  },

  data: function () {
    return {
      currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1
    }
  },

  methods: {
    async handleClick () {
      const query = Object.assign({}, this.$route.query)
      query.page = this.currentPage
      await this.$router.push({ query })
    }
  },

  watch: {
    '$route.query' () {
      if (!this.$route.query.page) {
        this.currentPage = 1
      }
    }
  }
}
</script>
