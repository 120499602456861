<template>
  <div class="wrapper">
    <div class="background">
      <div class="subcontainer">
        <div class="subcontainer-header">
          <h1 class="uppercase font-bold text-lg mb-2">
            <icon
              icon="fournisseurs"
              class="inline-block"/>
            <span>Fournisseurs</span>
          </h1>

          <div class="w-full lg:w-auto flex justify-between">
            <button
              class="lg:hidden btn btn-dark btn-small mr-2"
              @click="handleOpenDrawer">
              Filtrer
            </button>

            <button
              @click="handleExportXLSX"
              class="btn btn-small btn-outline--primary"
            >
              Export de la liste au format xlsx
            </button>
          </div>
        </div>

        <!-- Liste des fournisseurs -->
        <ul class="suppliers-list">
          <div
            v-if="isLoadingSuppliers"
            class="p-4">
            Chargement...
          </div>
          <ListSuppliersItem
            v-else
            v-for="(supplier, index) in suppliers.rows"
            :key="index"
            :supplier="supplier"/>
        </ul>

        <pagination
          v-if="suppliers.pager"
          :page-count="suppliers.pager.pageCount" />

        <!-- Liste des documents -->
        <Card
          class="mt-8"
          edged
          leftTitle>
          <div slot="header">
            <h2 class="font-bold text-lg">
              <icon
                icon="offres-negociees"
                class="inline-block" />
              <span>Offres négociées</span>
            </h2>
          </div>
          <ListDocuments :documents="documents" />
          <div slot="footer">
            <button
              class="btn btn-small btn-outline--primary"
              @click="handleGoOffersResults"
            >
              Voir le détail de ces offres
            </button>
          </div>
        </Card>

        <!-- Liste des articles -->
        <Card
          class="mt-8"
          edged
          leftTitle>
          <div slot="header">
            <h2 class="font-bold text-lg">
              <icon
                icon="articles"
                class="inline-block" />
              <span>Articles</span>
            </h2>
          </div>
          <div
            v-if="articles.length > 0"
            class="grid grid-cols-2 gap-4"
          >
            <article
              v-for="(article, index) in articles"
              :key="index"
              class="article_minified">
              <img
                class="article_minified-media"
                v-if="article.medias"
                :src="article.medias.thumbnail.source"
                :alt="article.medias.thumbnail.alt">
              <div class="article_minified-description">
                <h3 class="text-base">{{ article.title }}</h3>
                <p class="text-sm mt-2">{{ article.body }}</p>
                <button
                  class="btn btn-small btn-outline--primary mt-2"
                  @click="handleArticleRead(article.slug)">En lire +</button>
              </div>
            </article>
          </div>
          <div v-else>Aucun article</div>
          <div slot="footer">
            <button
              @click="handleGoArticles"
              class="btn btn-small btn-outline--primary"
            >
              Voir l'ensemble des articles
            </button>
          </div>
        </Card>

        <!-- Bannière interstitielle -->
        <div
          v-for="(banner, i) in banner"
          :key="i"
          class="banner_interstitial">
          <img
            v-if="banner.medias"
            :src="banner.medias.heading.source.search"
            :alt="banner.medias.heading.alt">
          <div class="banner_interstitial-content">
            <!-- <img
              :src="banner.medias.logo.source"
              :alt="banner.medias.logo.alt"> -->
            <h2 class="xl:text-3xl xl:uppercase font-bold bg-gray-200 bg-opacity-75 text-center px-4">{{ banner.title }}</h2>
            <a
              class="btn btn-tertiary"
              target="_blank"
              :href="banner.link">En savoir plus</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import { mapState } from 'vuex'

import ListSuppliersItem from '@/components/customs/suppliers/ListSuppliersItem.vue'
import ListDocuments from '@/components/customs/documents/ListDocuments.vue'
import Card from '@/components/commons/Card.vue'
import Pagination from '@/components/commons/Pagination'

export default {
  components: {
    ListSuppliersItem,
    ListDocuments,
    Card,
    Pagination
  },

  data: function () {
    return {
      excelContent: []
    }
  },

  computed: {
    ...mapState({
      isLoadingSuppliers: state => state.suppliers.isProcessing,
      isLoadingDocuments: state => state.documents.isProcessing,
      isLoadingArticles: state => state.articles.isProcessing,

      suppliers: state => state.suppliers.list,
      documents: state => state.documents.list,
      articles: state => state.articles.articleList,
      banners: state => state.suppliers.banners
    }),

    banner () {
      return this.banners.slice(0, 1)
    }
  },

  methods: {
    initiateResults () {
      const payload = {
        families: this.$route.query.famille,
        subFamilies: this.$route.query.sousfamille,
        s: this.$route.query.motclef,
        page: this.$route.query.page,
        order: this.$route.query.order
      }

      this.$store.dispatch('requestResultsSuppliers', payload)
      this.$store.dispatch('requestSuppliersFilters', payload)
      this.$store.dispatch('requestOffersDocuments', payload)
      this.$store.dispatch('requestResultsArticles', this.$route.query.motclef)
      this.$store.dispatch('requestBanners')
    },

    async fetchExcelContent () {
      try {
        const params = {
          xlsx: 'true',
          families: this.$route.query.famille,
          subFamilies: this.$route.query.sousfamille,
          s: this.$route.query.motclef
        }
        const content = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/suppliers`, {
          params: params
        })
        this.excelContent = content.data.rows
      } catch (err) {
        // Gestion des erreurs
      } finally {
      }
    },

    async handleExportXLSX () {
      await this.fetchExcelContent()

      var sheet = this.$xlsx.utils.json_to_sheet(this.excelContent)
      var wb = this.$xlsx.utils.book_new()
      this.$xlsx.utils.book_append_sheet(wb, sheet, 'Feuille 1')
      // export Excel file
      this.$xlsx.writeFile(wb, 'fournisseurs.xlsx')
    },

    handleGoArticles () {
      this.$router.push({ name: 'articles' })
    },

    handleArticleRead (slug) {
      this.$router.push({ name: 'article', params: { slug } })
    },

    handleGoOffersResults () {
      this.$router.push({ name: 'offers', query: { motclef: this.$route.query.motclef } })
    },

    handleOpenDrawer () {
      this.$store.dispatch('drawerStatus', true)
    }
  },

  watch: {
    $route (newQuery, oldQuery) {
      this.initiateResults()
    }
  },

  beforeMount () {
    this.initiateResults()
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply pt-0
}

.subcontainer-header {
  @apply flex justify-between;
}

.banner_interstitial {
  @apply mt-8 rounded-lg overflow-hidden relative;

  &-content {
    @apply flex items-center justify-evenly;
    @apply absolute w-full;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
</style>
