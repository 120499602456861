import axios from '@/axios'

/**
 * Action permettant de requêter les informations du dashboard.
 *
 * Note importante : le dashboard a 2 affichages possibles conditionnés par le
 * role de l'user (supplier ou subscriber) et possède plusieurs routes qui
 * permettent d'afficher un ensemble d'éléments.
 *
 * @param {String} type est le type de la route
 */
const requestDashboardDatas = async ({ commit }, type) => {
  try {
    const endpoint = typeof type === 'object' ? type.join('/') : type;
    const nameProp = typeof type === 'string' ? type : type[0];

    const url = process.env.VUE_APP_API_VERSION + '/dashboard/' + endpoint;

    const results = await axios.get(url)
    const mutationPayload = {
      type: nameProp,
      data: results.data
    }

    commit('SET_DASHBOARD_CARDS', mutationPayload)
  } catch (err) {
    console.error(err)
  }
}

export default {
  requestDashboardDatas
}
