const drawerItemsSupplier = [
  {
    value: 'subscriberPresentation',
    label: 'Présentation',
    icon: 'presentation',
    link: '',
    active: true
  },
  {
    value: 'subscriberDocuments',
    label: 'Documents',
    icon: 'documents-subscriber',
    link: 'documents',
    active: false
  }
]

export default drawerItemsSupplier
