<template>
  <div>
    <MglMap
      :mapboxGl="mapboxGL"
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :attributionControl="false"
      :center="mapCoordinates"
      @load="onMapLoaded"
      :zoom="defaultZoom"
      :style="{ minHeight: this.$route.name === 'dashboard' ? '600px' : '700px' }"
      >

      <MglGeojsonLayer
        sourceId="subscribers"
        :source="geoJsonSource"
        layerId="clusters"
        :layer="clustersLayer"
      />

      <MglGeojsonLayer
        sourceId="subscribers"
        :source="geoJsonSource"
        layerId="unclustered-point"
        before="clusters"
        :layer="{ type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'marker-icon'
        }
      }"
      />

    </MglMap>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MglMap, MglGeojsonLayer, MglMarker, MglPopup } from 'vue-mapbox'

// Pour tout usage de la lib mapbox, cf la doc :
// https://soal.github.io/vue-mapbox/guide/basemap.html

import clusterCarte from '../../../src/assets/images/icons/cluster-carte.png';
import markerCarte from '../../../src/assets/images/icons/marker-carte.png';

export default {
  components: {
    MglMap,
    MglGeojsonLayer,
    MglMarker,
    MglPopup
  },

  data: function () {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
      mapCoordinates: [2.213749, 46.227638],
      defaultZoom: 5.1,
      mapboxGL: Mapbox,
      clustersLayer: {
        type: 'symbol',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': 'cluster-icon',
          'icon-anchor': 'bottom',
          'text-field': '{point_count_abbreviated}\nAdhérents',
          'text-size': 12
        },
        paint: {
          'text-color': '#FFF',
          'text-translate': [0, -35]
        }
      }
    }
  },


  computed: {
    subscribers () {
      return this.$store.state.subscribers.list.rows ? this.$store.state.subscribers.list.rows : []
    },

    filteredSubscribers() {
      let filteredSubscribers = []

      if (this.subscribers.length > 0) {
        filteredSubscribers = this.subscribers.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.coordinates.lng === value.coordinates.lng && t.coordinates.lat === value.coordinates.lat
          ))
        )
      }

      return filteredSubscribers
    },

    subscribersCoordinates () {
      let coordinates = []
      for (const subscriber of this.filteredSubscribers) {
        if (subscriber.coordinates.lng && subscriber.coordinates.lat) {
          coordinates.push([subscriber.coordinates.lng, subscriber.coordinates.lat])
        }
      }
      return coordinates
    },

    geoJsonSource () {
      let features = []

      for (const subscriber of this.filteredSubscribers) {
        if (subscriber.coordinates.lng && subscriber.coordinates.lat) {
          const otherSubscribers = this.subscribers.filter(otherSubscriber => {
            return otherSubscriber.id !== subscriber.id &&
              otherSubscriber.coordinates.lng === subscriber.coordinates.lng &&
              otherSubscriber.coordinates.lat === subscriber.coordinates.lat
          })

          features.push({
            "type": "Feature",
            "properties": {
              "title": subscriber.title,
              "slug": subscriber.slug,
              "street": subscriber.address.street,
              "postal": subscriber.address.postal,
              "city": subscriber.address.city,
              "otherSubscribers":  otherSubscribers
            },
            "geometry": {
              "type": "Point",
              "coordinates": [
                subscriber.coordinates.lng,
                subscriber.coordinates.lat
              ]
            }
          })
        }
      }

      return {
        data : {
          "type": "FeatureCollection",
          "features": features
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 100,
        clusterMinPoints: 2
      }
    }
  },

  methods: {
    onMapLoaded (event) {
      // in componentK
      this.map = event.map
      this.map.resize()

      this.map.loadImage(
        clusterCarte,
        (error, image) => {
          if (error) throw error
          this.map.addImage('cluster-icon', image)
      })

      this.map.loadImage(
        markerCarte,
        (error, image) => {
          if (error) throw error
          this.map.addImage('marker-icon', image)
      })

      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('subscribers').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.flyTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      })

      this.map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice()

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        const subscriberData = e.features[0].properties

        let html = `<div class="block">
              <h3 class="font-bold">${ subscriberData.title }</h3>
              <address>
                <p class="text-xs"><strong>Rue : ${ subscriberData.street }</strong> </p>
                <p class="text-xs"><strong>Code postal :</strong> ${ subscriberData.postal }</p>
                <p class="text-xs"><strong>Ville :</strong> ${ subscriberData.city }</p>
              </address>
              <a href="/adherent/${ subscriberData.slug }/">Voir la fiche</a></div>`

        const otherSubscribers = JSON.parse(subscriberData.otherSubscribers)

        if (otherSubscribers.length > 0) {
          html += '<br><div><p class="font-bold">Autres adhérents à cette adresse</p>'

          for (const otherSubscriber of otherSubscribers) {
            html += `<p><a href="/adherent/${ otherSubscriber.slug }/">${ otherSubscriber.title }</a></p>`
          }

          html += '</div>'
        }

        new Mapbox.Popup()
          .setLngLat(coordinates)
          .setHTML(html)
          .addTo(this.map)
      });

      //this.autoZoom()
    },

    autoZoom () {
      if (this.$route.query.department ||
        this.$route.query.naf ||
        this.$route.query.activitySector ||
        this.$route.query.affiliate ||
        this.$route.query.ca ||
        this.$route.query.staff ||
        this.$route.query.referent
      ) {
        const bounds = this.subscribersCoordinates.reduce((bounds, coord)=> {
            return bounds.extend(coord);
          }, new Mapbox.LngLatBounds(this.subscribersCoordinates[0], this.subscribersCoordinates[0])
        );

        this.map.fitBounds(bounds, {
          padding: 30
        })

      } else {
        this.map.flyTo({
          center: this.mapCoordinates,
          zoom: this.defaultZoom
        });
      }
    }
  },

  watch: {
    'subscribersCoordinates'() {
      this.autoZoom()
    }
  },

  created () {
    this.map = Mapbox
  }
}
</script>
