<template>
  <div v-if="isLoading" class="mt-6">
    <p>Chargement en cours ...</p>
  </div>
  <div v-else class="h-full flex flex-col justify-center items-center">
    <h1 class="font-bold">Le téléchargement n'a pas fonctionné ?</h1>
    <button @click="fetchDocumentFile" class="btn btn-large btn-primary mt-8">Télécharger le document</button>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "Document",

  data: function () {
    return {
      isLoading: false
    }
  },

  beforeMount() {
    this.fetchDocumentFile()
  },

  methods: {
    async fetchDocumentFile() {
      try {
        this.isLoading = true

        const blob = await axios.get(`${process.env.VUE_APP_API_VERSION}/documents/${this.$route.params.slug}/file`, {
          responseType: 'blob'
        })

        const filename = blob.headers['content-disposition'].split('filename=')[1].replaceAll('"', '')
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)

      } catch (e) {
        console.log(e)

      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
