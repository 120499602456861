<template>
  <div class="wrapper">
    <div class="container my-8">
      <MarketIndexes card />
    </div>

    <RenderHtml
      v-for="(sector, i) in sectors"
      :html="sector.html"
      :key="i"
    />
  </div>
</template>

<script>
import MarketIndexes from '@/components/amcharts/MarketIndexes.vue'
import RenderHtml from '@/components/templates/RenderHtml.vue'
import $axios from '@/axios'

export default {
  components: {
    RenderHtml,
    MarketIndexes
  },

  data () {
    return {
      sectors: []
    }
  },

  methods: {
    async fetchIndexes () {
      try {
        const res = await $axios.get(`${process.env.VUE_APP_API_VERSION}/indexes`)
        this.sectors = res.data.rows
      } catch (e) {
        // Gestion des erreurs ici
      } finally {

      }
    }
  },

  beforeMount () {
    // Requête des infos de la page des indices ici
    this.fetchIndexes()
  }
}
</script>
