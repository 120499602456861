<template>
  <div class="toolbar-login">
    <m-dropdown left>
      <template v-slot:activator>
        <button
          @click="$router.push('/profil')">
          <icon
            icon="compte"
            size="xl">
          </icon>
        </button>
      </template>

      <div>
        <ul class="list">
          <div class="list-subheader">
            {{ userName }}
          </div>
          <div class="list-item--group">
              <li
                @click="handleGoFiche(currentUser.slug)"
                class="list-item">
                <div class="list-item--content">
                <span class="list-item--title">Ma fiche</span>
              </div>
              </li>
            <li
              v-for="(item, index) in toolbarItems"
              :key="index"
              class="list-item">
              <div class="list-item--content">
                <span v-if="item.link !== 'dashboard'" class="list-item--title" @click="handleClick(item.link, item.external)">
                  {{ item.text}}
                </span>
                <span v-else-if="currentUser.isFiliale && identity[0] == 'subscriber'"
                  class="list-item--title"
                  @click="handleGoDashboardwithFiliale(currentUser.isFiliale)">
                  {{ item.text}}
                </span>
                <span v-else
                  class="list-item--title"
                  @click="handleGoDashboard()">
                  {{ item.text}}
                </span>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </m-dropdown>
  </div>
</template>

<script>
import MDropdown from '@/components/commons/Dropdown'
import toolbarItems from '../../datas/toolbar'

export default {
  components: {
    MDropdown
  },

  computed: {
    toolbarItems () {
      return toolbarItems.user
    },

    userName () {
      return this.$store.state?.authentication?.user?.firstname + ' ' + this.$store.state?.authentication?.user?.lastname
    },

    currentUser () {
      return this.$store.state.authentication.user
    },

    identity () {
      return this.$store.state.authentication.user.roles
    },
  },

  methods: {
    handleClick (link, external) {
      link
        ? this.handleNavigation(link, external)
        : this.handleLogout()
    },

    handleNavigation (link, external) {
      if (external) {
        window.open(link, '_blank')
      } else {
        this.$router.push({ name: link })
      }
    },

    handleGoFiche (slug) {
      if (this.identity[0] === 'supplier') {
        this.$router.push(`/fournisseur/${slug}`)
      } else {
        this.$router.push(`/adherent/${slug}`)
      }
    },
    
    handleGoDashboardwithFiliale(isFiliale) {
      this.$router.push(`/dashboard/${isFiliale}`)
    },

    handleGoDashboard() {
        this.$router.push({ name: 'dashboard' })
    },

    handleLogout () {
      this.$store.dispatch('disconnectUser')
    }
  }
}
</script>
