<template>
    <modal :visible="isCUModalOpen" :close-action="null" :is-shadow-closable="false">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Conditions d'utilisation
            </span>
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>

        <div class="p-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus quam risus, commodo sollicitudin lacus luctus ut. Nullam nec ante ut massa bibendum accumsan in nec enim. Suspendisse iaculis ipsum a volutpat scelerisque. Praesent nisl neque, dictum a lectus eget, hendrerit tincidunt nisl.
        </div>

        <div :class="['flex', 'items-center', 'row', 'justify-center']">
          <input
            v-model="isCUChecked"
            class="cursor-pointer"
            type="checkbox"
            :id="'chekbox__cgu'"
          >
          <label for="chekbox__cgu" :style="{ fontWeight: 'bold' }" :class="'mx-2'">
            Je consens lorem ipsum dolor sit amet lectus tortor
          </label>
          <div>
            <icon 
            icon="infos-primary-bluer" 
            size="md" 
            :style="{ cursor: 'pointer'}"
            v-tooltip.top="'Gain moyen estimé sur base des éléments mis à la disposition de Mercurial. Ce résultat n\'engage pas Mercurial et correspond seulement à une analyse à un instant donné et à des éléments passés ou présents.'"
          />
          </div>
        </div>
        <button
          :class="['btn', 'btn-primary', 'm-auto', 'w-fit', 'my-10', !isCUChecked ? 'disabled': null]"
          @click="submitCU"
        >
          Valider
        </button>
      </div>
    </modal>
</template>
  
<script>
import $axios from "@/axios";
import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal,
  },

  data: () => ({
    isCUModalOpen: false,
    isCUChecked: false,
  }),

  beforeMount(){
    this.getStatusCU()
  },

  methods: {
    /**
     * Patch contracts Consent Action
     * @return {void}
     */
    async submitCU(){
      try {
        const request = await $axios.patch(
          `${process.env.VUE_APP_API_VERSION}/account-informations`,
          {
            contractsConsent: true
          }, {
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            }
          }
        )

        if (request.status === 200){
          this.isCUModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    },
    /**
     * Get contracts Consent Status
     * @return {void}
     */
    async getStatusCU(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/account-informations`
        )

        if (response?.data?.contractsConsent){
          this.isCUModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    }
  }
}
</script>