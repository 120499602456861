<template>
  <div
    class="dropdown"
    @mouseleave="isOpen = false"
    @click="isOpen = false">
    <div
      class="dropdown-activator"
      @mouseenter="isOpen = true"
    >
      <slot name="activator"></slot>
    </div>

    <transition
      name="fade">
      <div
        v-if="isOpen"
        @mouseover="isOpen = true"
        class="dropdown-content"
        :class="{ 'dropdown-content--left': left }">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    isOpen: false
  })
}
</script>
