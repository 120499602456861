<template>
  <div
    v-if="isLoading"
    class="subcontainer">
    Chargement...
  </div>

  <div
    v-else
    class="wrapper">
    <div class="subcontainer subcontainer-large">
      <SectionHeader />
    </div>
    <div class="background">
      <div class="subcontainer subcontainer-large">
        <div class="flex flex-wrap my-8">
          <article class="article">
            <SectionObjectives />
            <SectionDescription />
            <SectionPublic />
            <SectionSpeakers />
            <SectionProgram />
            <SectionDocuments />
          </article>
          <aside class="aside">
            <SectionTabs/>
          </aside>
        </div>
      </div>
    </div>
    <div class="background-darklight">
      <div class="subcontainer subcontainer-large">
        <div class="flex flex-col mt-8">
          <div class="bottom mb-8">
            <SectionSessions />
            <SectionInterviews />
            <SectionAccessibility v-if="$store.state.articles.current.access.a11y" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from './blocks/SectionHeader'
import SectionObjectives from './blocks/SectionObjectives'
import SectionDescription from './blocks/SectionDescription'
import SectionPublic from './blocks/SectionPublic'
import SectionSpeakers from './blocks/SectionSpeakers'
import SectionProgram from './blocks/SectionProgram'
import SectionDocuments from './blocks/SectionDocuments'
import SectionTabs from './blocks/SectionTabs'
import SectionSessions from './blocks/SectionSessions'
import SectionInterviews from './blocks/SectionInterviews'
import SectionAccessibility from './blocks/SectionAccessibility'

export default {
  components: {
    SectionHeader,
    SectionObjectives,
    SectionDescription,
    SectionPublic,
    SectionSpeakers,
    SectionProgram,
    SectionDocuments,
    SectionTabs,
    SectionSessions,
    SectionInterviews,
    SectionAccessibility
  },

  computed: {
    isLoading () {
      return this.$store.state.articles.isProcessingArticle
    }
  },

  methods: {
    fetchFormation () {
      const slug = this.$route.params.slug
      this.$store.dispatch('requestFormationBySlug', slug)
    }
  },

  watch: {
    $route: (to, from, next) => {
      this.fetchFormation()
    }
  },

  beforeMount () {
    this.fetchFormation()
  }
}
</script>

<style lang="scss" scoped>
.flex {
  .article {
    @apply w-full order-2;

    @screen xl {
      @apply w-2/3 pr-2;
    }

    > * + * {
      @apply mt-4;
    }
  }

  .bottom {
    > * + * {
      @apply mt-4;
    }
  }

  .aside {
    @apply w-full mb-4 order-1;

    @screen xl {
      @apply w-1/3 pl-2 mb-0 order-3;
    }
  }
}
</style>
