import axios from '@/axios'

/**
 * On requête la liste des documents à afficher
 * @param {Object} payload
 */
const requestResultsDocuments = async ({ commit, dispatch }, payload) => {
  commit('SET_DOCUMENTS_PROCESSING', true)

  try {
    const documents = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/documents`, {
      params: payload
    })

    dispatch('requestDocumentsFilters', payload)

    commit('SET_DOCUMENTS_LIST', documents.data.rows)
    commit('SET_PAGE_COUNT', documents.data.pager.pageCount)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_DOCUMENTS_PROCESSING', false)
  }
}

/**
 * On requête la liste des documents à afficher selon le slug d'un fournsiseur
 * @param {String} payload.category le fournisseur
 * @param {String} payload.type le type de document
 */
const requestDocumentsByCategory = async ({ commit }, payload) => {
  commit('SET_DOCUMENTS_PROCESSING', true)
  try {
    const documents = await axios.get(`${process.env.VUE_APP_API_VERSION}/documents/${payload.category}`, {
      // params: {
      //   type: payload.type
      // }
    })

    commit('SET_DOCUMENTS_LIST', documents.data.rows)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_DOCUMENTS_PROCESSING', false)
  }
}

/**
 * On requête la liste des filtres sur le côté
 */
const requestDocumentsFilters = async ({ commit }, payload) => {
  try {
    const filters = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/documents/filters`, {
      params: payload
    })
    commit('SET_DOCUMENTS_FILTERS', filters.data)
  } catch (err) {
    console.error(err)
  } finally {
    // Quoiqu'il arrive...
  }
}

const requestOffersDocuments = async ({ commit }, payload) => {
  commit('SET_DOCUMENTS_PROCESSING', true)

  try {
    const documents = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/offers-documents`, {
      params: payload
    })

    commit('SET_DOCUMENTS_LIST', documents.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_DOCUMENTS_PROCESSING', false)
  }
}

export default {
  requestResultsDocuments,
  requestDocumentsByCategory,
  requestDocumentsFilters,
  requestOffersDocuments
}
