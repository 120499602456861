<template>
    <div class="flex justify-between">
        <div class="title--filters">Filtrer</div>
        <button
            :class="['btn', 'btn-outline', 'btn-outline--primary-bluer', 'btn-small', !alwaysClosable ? 'lg:hidden' : null]"
            @click="handleCloseDrawer">
            <span class="font-normal">Fermer</span>
            <icon
                icon="fermer-primary-bluer"
                size="xs"
                class="ml-2"
            />
        </button>
    </div>
</template>
  
<script>
export default {
  props: {
    alwaysClosable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleCloseDrawer () {
      this.$store.dispatch('drawerStatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
button[type="submit"] {
    max-width: 195px;
    margin-top: 30px;
}

.title--filters {
    padding-right: 2rem;
    text-transform: uppercase;
    font-weight: 700;
}
</style>
  