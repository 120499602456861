<template>
  <div class="banner-item">
    <div class="banner-item--container">
      <div class="banner-item--heading">

        <div v-if="slide.logos && slide.logos.length > 0">
          <div v-for="(logos, index) in slide.logos" :key="index" class="">
            <img class="" :src="`${logos.logo}`" :alt="`${logos.title}`">
          </div>
        </div>

        <div v-if="slide.logos_categ && slide.logos_categ.length > 0">
          <div v-for="(logos_categ, index) in slide.logos_categ" :key="index" class="">
            <img class="" :src="`${logos_categ.logo}`">
          </div>
        </div>

        <img  v-if="slide.logo_mercurial && slide.logo_mercurial > 0"
          class="banner-item&#45;&#45;logo"
          src="@/assets/images/default/interstitial-logo-2.png"
          alt="Logo de mercurial">

        <h3 class="banner-item--title" >
          <div v-html="slide.titre"></div>
        </h3>
      </div>
        <div class="banner-item--text" v-html="slide.description"></div>
      <a
        v-if="slide.link"
        class="btn btn-secondary z-50"
        target="_blank"
        :href="`${slide.link}`">En savoir plus
      </a>
    </div>
 <!--     <img
      class="banner-item--cover"
      :src="slide.medias.heading.source.home"
      :alt="slide.medias.heading.alt"> -->
      <span class="border-bottom"></span>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      default: () => ({
        title: '',
        body: '',
        description: '',
        logos: '',
        logos_categ: '',
        logo_mercurial: '',
        titre: '',
        link: '',
        media: {
          source: '',
          alt: ''
        }
      }),
      required: true
    }
  }
}
</script>
