<template>
  <article class="article_miniature">
    <h3 class="article_miniature-title">{{ title }}</h3>

    <div class="article_miniature-media--box">
      <img
        class="article_miniature-media--image"
        :src="media.source"
        :alt="media.alt">
    </div>

    <p class="article_miniature-body">{{ body }}</p>
    <div class="article_miniature--actions mt-2">
    <button
        class="btn btn-small btn-outline--primary"
        @click="handleGoPage">
        Lire la suite
      </button>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },

    media: {
      type: Object,
      default: () => ({
        source: '',
        alt: ''
      }),
      required: false
    },

    body: {
      type: String,
      default: '',
      required: false
    },
    slug: {
      type: String,
      default: '',
      required: false
    },
    type: {
      type: String,
      default: '',
      required: false
    },    
  },
  methods: {
    handleGoPage () {
      if (this.type === 'Formation') {
        this.$router.push(`/formation/${this.slug}`)
      } else {
        this.$router.push(`/article/${this.slug}`)
      }
    }
  }
}
</script>
