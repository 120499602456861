<template>
  <address class="contact">
    <span class="contact-name">{{ displayFullName }}</span><br>
    <span v-if="contact.title">{{ contact.title }}</span><br v-if="contact.title">
    <span v-if="contact.service">{{ contact.service }}</span><br v-if="contact.service">
    <span v-if="contact.function">{{ contact.function }}</span><br v-if="contact.function">
    <span v-if="contact.email">{{ contact.email }}</span><br v-if="contact.email">
    <span v-if="displayAnyPhoneNumber">{{ displayAnyPhoneNumber }}</span>
  </address>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      default: () => ({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        tel: '',
        title: '',
        service: '',
        function: '',
      }),
      required: true
    }
  },

  computed: {
    displayAnyPhoneNumber () {
      return this.contact.phone ? this.contact.phone : this.contact.tel
    },

    displayFullName () {
      return this.contact.firstname + ' ' + this.contact.lastname
    }
  }
}
</script>
