import axios from '@/axios'

/**
 * On récupère les différents indices
 */
const requestMacroeconomic = async ({ commit }) => {
  try {
    const datas = await axios.get(`${process.env.VUE_APP_API_VERSION}/macroeconomic`)

    commit('SET_MACROECONOMIC_DATAS', datas.data)
  } catch (err) {
    console.error(err)
  } finally {
    // Effet quoiqu'il arrive
  }
}

/**
 * On requête la liste des articles à afficher sur la home
 */
const requestArticles = async ({ commit }) => {
  try {
    const articles = await axios.get(`${process.env.VUE_APP_API_VERSION}/home/articles`)
    commit('SET_ARTICLES', articles.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

/**
 * On requête la liste des articles à afficher selon des paramètres
 */
const requestWebinar = async ({ commit }) => {
  try {
    const webinar = await axios.get(`${process.env.VUE_APP_API_VERSION}/home/articles/webinars`)
    commit('SET_WEBINAR', webinar.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

const requestDocuments = async ({ commit }) => {
  try {
    const documents = await axios.get(`${process.env.VUE_APP_API_VERSION}/home/documents`)
    commit('SET_DOCUMENTS', documents.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

const requestReferences = async ({ commit }) => {
  try {
    const references = await axios.get(`${process.env.VUE_APP_API_VERSION}/home/suppliers`)
    commit('SET_REFERENCES', references.data)
  } catch (err) {
    console.error(err)
  } finally {
  }
}

const requestFormations = async ({ commit }) => {
  try {
    const formations = await axios.get(`${process.env.VUE_APP_API_VERSION}/home/formation`)
    commit('SET_FORMATIONS', formations.data)
  } catch (err) {
    console.error(err)
  } finally {
    // Effet quoiqu'il arrive
  }
}

const requestBanners = async ({ commit }) => {
  try {
    const banners = await axios.get(`${process.env.VUE_APP_API_VERSION}/banners`, { params: { front: '1' } })
    commit('SET_BANNERS', banners.data)
  } catch (err) {
    console.error(err)
  } finally {
    // Effet quoiqu'il arrive
  }
}

export default {
  requestMacroeconomic,
  requestWebinar,
  requestArticles,
  requestDocuments,
  requestReferences,
  requestFormations,
  requestBanners
}
