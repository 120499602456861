<template>
    <modal :visible.sync="isVisible" :close-action="null" class="modal--contract-history">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <div :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Historique des modifications apportées au contrat
            </span>
          </div>
        </section>
        <icon icon="fermer" class="absolute right-4 top-9 cursor-pointer" @click.native="isVisible = false" />
      </div>

      <template v-if="loading">
        <div class="flex justify-center">
          <PrimeProgressSpinner stroke-width="2" :style="{ height: '50px' }" />
        </div>
      </template>

      <template v-else>
        <div class="p-3 sm:p-0">
          <PrimeDatatable :value="history">
            <PrimeColumn
              field="date"
              header="Date d’enregistrement"
              headerClass="contract-history--date"
            />
            <PrimeColumn
              field="user"
              header="Utilisateur"
              headerClass="contract-history--user"
            />
            <PrimeColumn
              field="action"
              header="Action"
              headerClass="contract-history--action"
            />
            <PrimeColumn
              field="note"
              header="Note"
              headerClass="contract-history--note"
            />
            <template #empty>
              <p class="text-center">Aucun historique</p>
            </template>
          </PrimeDatatable>
        </div>
      </template>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import axios from '@/axios'

export default {
  components: {
    Modal,
  },

  watch: {
    isVisible(newVal) {
      if (!newVal) {
        this.$emit('close')
      }
    },
  },

  data() {
    return {
      isVisible: false,
      loading :false,
      history: []
    }
  },

  async beforeMount() {
    this.isVisible = true
    await this.fetch()
  },

  methods: {
    async fetch() {
      try {
        this.loading = true
        const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/contract/${this.$route.params.id}/history`)
        this.history = res.data || []
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dialog-window {
  min-height: 200px;
}
::v-deep .p-datatable {
  max-width: 100%;

  .p-datatable-table {
    .p-datatable-thead {
      tr > th:last-child .p-column-header-content {
        justify-content: flex-start;
        text-align: left;
      }
      .contract-history--date { width: 17%; }
      .contract-history--user { width: 23%; }
      .contract-history--action { width: 15%; }
      .contract-history--note { width: 45%; }
    }
    .p-datatable-tbody {
      tr > td {
        text-align: center;
        overflow-wrap: anywhere;
        &:first-child { border-left: none; }
        &:last-child {
          text-align: left;
          border-right: none;
        }
      }
      tr:first-child td {
        &:first-child { border-radius: 0; }
        &:last-child { border-radius: 0; }
      }
    }
  }
}
</style>
