<template>
  <Card
    leftTitle
    dense
    edged>
    <template slot="header">
      <h2 class="uppercase">Accessibilité personnes en situation de handicap</h2>
    </template>

    <template>
      <p>L’accessibilité de chaque site de formation Mercurial est garantie pour le public en situation de handicap, y compris pour les personnes à mobilité réduite. Cette accessibilité concerne l’accès aux bâtiments, l’accès à nos surfaces privatives ainsi que la circulation et l’utilisation de toutes les surfaces mises à dispositions du public (circulations, accueil, salles de formation, espaces détente et toilettes).</p>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  }
}
</script>
