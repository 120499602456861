const subscriberTabs = [
  {
    value: 'month',
    label: 'Votre CA achats Mercurial par mois'
  },
  {
    value: 'year',
    label: 'Votre CA achats Mercurial par année'
  },
  {
    value: 'family',
    label: 'Votre CA achats Mercurial par famille'
  },
  {
    value: 'supplier',
    label: 'Votre CA achats Mercurial par fournisseur'
  },
  {
    value: 'history',
    label: 'Historique du nombre de fournisseurs utilisés'
  }
]

export default subscriberTabs
