<template>
  <section class="page-drawer--evaluation">
    <button
      class="btn"
      @click="handleClick(linkeval)">
      <div class="btn-subtext">
        <icon icon="evaluer" />
        <span>Evaluer ce fournisseur</span>
      </div>
      <div class="btn-subtext">
        <icon
          icon="fleche-orange"
          size="sm" />
        <span>Laissez votre avis</span>
      </div>
    </button>
  </section>
</template>

<script>
export default {
    computed: {
        linkeval() {
            return this.$store.state.suppliers.current.metadatas ? this.$store.state.suppliers.current.metadatas.linkeval: '';
         }
    },
    methods: {
    handleClick(link){
        window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-drawer--evaluation {
  @apply flex items-center justify-center;
  margin-top: 150px;
}

.btn {
  @apply flex flex-col;
  @apply bg-secondary-light border-0;
  @apply uppercase text-secondary font-normal;
  @apply p-3;

  &-subtext {
    @apply flex;

    + .btn-subtext {
      @apply mt-3;
    }

    &:nth-child(2) {
      @apply text-sm;
    }
  }

}
</style>
