<template>
  <section class="page-drawer--section">
    <div
      class="checker"
      v-for="(family, index) in families"
      :key="index">
      <div class="checker-parent">
        <input
          type="checkbox"
          :id="family.slug"
          :value="family.slug"
          v-model="checkedFamilies">
        <label :for="family.slug">
          {{ family.title }}
        </label>
      </div>

      <div
        class="checker-children"
        v-for="(subFamily, subIndex) in family.subFamilies"
        :key="subIndex"
      >
        <input
          type="checkbox"
          :value="subFamily.slug"
          :id="subFamily.slug"
          v-model="checkedSubFamilies">
        <label
          :for="subFamily.slug">
          {{ subFamily.title }}
        </label>
      </div>
    </div>

    <div class="checker-confirm">
      <button
        class="btn btn-primary"
        @click="handleCloseDrawer">
        Valider
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    checkedFamilies: [],
    checkedSubFamilies: []
  }),

  computed: {
    ...mapState({
      loading: state => state.categories.isProcessing,
      families: state => state.categories.current.families
    })
  },

  methods: {
    // Quand on sélectionne ou déselectionne une checkbox, d'abord on renseigne
    // dans l'URL quel élément a été checké puis ensuite à chaque fois qu'on
    // change d'URL, la page va forcément effectuer une nouvelle requête des
    // fournisseurs avec les nouveaux params.
    itemChecked () {
      this.$router.push({
        query: {
          famille: this.checkedFamilies.join(','),
          sousfamille: this.checkedSubFamilies.join(',')
        }
      })

      // Pour éviter d'avoir un message d'erreur dans le cas où on redirige sur
      // la même route (ex rafraîchissement de page), on met ce petit catch :
        .catch(() => {})
    },

    handleCloseDrawer () {
      this.$store.dispatch('drawerStatus', false)
    }
  },

  watch: {
    'checkedFamilies' () {
      this.itemChecked()
    },

    'checkedSubFamilies' () {
      this.itemChecked()
    },

    $route (to, from) {
      // On remet les filtres à zéro si on change de catégorie d'achat.
      if (to.params.slug !== from.params.slug) {
        this.checkedFamilies = []
        this.checkedSubFamilies = []
      }
    }
  },

  beforeMount () {
    // En cas de refresh de la page, on récupère ce qui a déjà été renseigné
    // dans les query params pour ne pas perdre la recherche précédente.
    if (this.$route.query.famille) {
      this.checkedFamilies = this.$route.query.famille.split(',')
    }

    if (this.$route.query.sousfamille) {
      this.checkedSubFamilies = this.$route.query.sousfamille.split(',')
    }
  }
}
</script>
