<template>
  <card
    dense
    leftTitle class="relative">
    <template slot="header">
      <icon
        icon="favoris"
        size="sm" />
      <h2 class="uppercase text-base font-bold">
        Cartographie des adhérents
      </h2>
      <div
        @click="openFilters = !openFilters"
        class="btn filter-btn btn-outline--primary btn-small"
      >
        {{ this.openFilters ? 'Fermer' : 'Filtrer' }}
      </div>
    </template>

    <template slot="default">
      <div class="mapbox">
        <section class="mapbox-map">
          <map-view></map-view>
        </section>

        <section
          v-if="this.openFilters"
          class="mapbox-filters">
          <active-filter-pills state-key="subscribers" />
          <autocomplete-filters :filters="autocompleteFilters" state-key="subscribers" />
          <select-filters-list state-key="subscribers" />
        </section>
      </div>
    </template>

    <!-- <template slot="footer">
      <button
        class="btn btn-small btn-outline--primary"
        @click="$router.push({ name: 'subscribers' })">Voir la liste des adhérents</button>
    </template> -->
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import SelectFilter from '@/components/commons/SelectFilter'
import MapView from '@/components/mapbox/MapView'
import ActiveFilterPills from "@/layout/blocks/drawer/ActiveFilterPills";
import AutocompleteFilters from "@/layout/blocks/drawer/AutocompleteFilters";
import SelectFiltersList from "@/layout/blocks/drawer/SelectFiltersList";

export default {
  components: {
    SelectFiltersList,
    AutocompleteFilters,
    ActiveFilterPills,
    Card,
    SelectFilter,
    MapView
  },

  data: function ()  {
    return {
      openFilters: false,
      autocompleteFilters: [
        /*{
          name: 'activitySector',
          placeholder: 'Par secteur d\'activité',
          entityType: 'taxonomy_term'
        },*/
        {
          name: 'naf',
          placeholder: 'Par code NAF'
        },
        {
          name: 'affiliate',
          placeholder: 'Par maison mère',
          entityType: 'node'
        },
      ]
    }
  },

  computed: {
    filters () {
      return this.$store.state.subscribers.filters
    },

    newFilters () {
      return Object.keys(this.filters).map(key => {
        const newFilters = {
          ...this.filters[key],
          value: key
        }
        return newFilters
      })
    }
  },

  watch: {
    '$route.query' (to, from) {
      this.openFilters = false
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-btn {
  @apply absolute cursor-pointer;
  right: 85px;
}

.mapbox {
  @apply relative;
  @apply h-full;

  &-filters {
    width: 300px;
    @apply absolute top-0 right-0 bottom-0 bg-white ;

    @screen lg {
      .select + .select {
        @apply ml-3 mt-0;
      }
    }
  }

  .mapboxgl-canvas {
    min-width: 100%;
  }

  .mapbox-map > div {
    height: 100%;
  }
}
</style>
