const SET_DOCUMENTS_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_DOCUMENTS_LIST = (state, data) => {
  state.list = data
}

const SET_PAGE_COUNT = (state, data) => {
  state.pageCount = data
}

const SET_DOCUMENTS_FILTERS = (state, data) => {
  state.filters = data
}

export default {
  SET_DOCUMENTS_PROCESSING,
  SET_DOCUMENTS_LIST,
  SET_PAGE_COUNT,
  SET_DOCUMENTS_FILTERS
}
