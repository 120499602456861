<template>
  <div
    class="select"
    v-click-outside="handleClose">
      <div class="relative">
        <button
          class="btn btn-select"
          :class="{'disabled' : isEmpty}"
          @click="handleDropdown">
      <span :class="{'text-xs' : small}">
        {{ filter.label || 'Aucun filtre' }}
      </span>
          <icon
            class="transition-all duration-100 ease-in-out ml-3"
            :class="{'transform rotate-180' : isOpen}"
            icon="fleche-bas"
            size="xs"/>
        </button>

        <transition
          name="fade"
          mode="out-in">
          <div
            class="select-dropdown"
            v-if="isOpen">
            <ul class="select-list">
              <li
                class="select-list--item"
                v-for="(item, index) in filter.items"
                :key="index">
                <input
                  @change="handleFilter(item.value)"
                  class="cursor-pointer"
                  type="checkbox"
                  :id="item.value"
                  :value="item.value"
                  v-model="checked">
                <label
                  :for="item.value"
                  class="cursor-pointer">
                  {{ item.label }}
                </label>
              </li>
            </ul>
          </div>
        </transition>
      </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({
        value: '', // Définit quelle variable filtrer
        label: '', // Texte du bouton affiché à l'user
        items: [ // Liste des choix
          {
            value: '', // Valeur du choix pour la requête
            label: '' // Texte du choix affiché à l'user
          }
        ]
      }),
      required: true // Ne pas utiliser ce composant si filter est vide
    },

    small: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    isOpen: false,
    checked: []
  }),

  computed: {
    isEmpty () {
      return this.filter.items.length === 0
    }
  },

  methods: {
    handleClose () {
      this.isOpen = false
    },

    handleDropdown () {
      this.isOpen = !this.isOpen
    },

    handleFilter (value) {
      const query = { ...this.$route.query }
      if (this.checked.length > 0) {
        this.checked = [value]
        query[this.filter.value] = value
      } else {
        delete query[this.filter.value]
      }
      this.$router.replace({ query })
    },

    handleChecked () {
      if (this.$route.query[this.filter.value]) {
        this.checked = [this.$route.query[this.filter.value]]
      } else {
        this.checked = []
      }
    }
  },

  watch: {
    $route (to, from) {
      this.isOpen = false
      this.handleChecked()
    }
  },

  beforeMount () {
    this.handleChecked()
  },

  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
.select {
  @apply flex justify-end;

  .btn-select, .select-dropdown { min-width: 225px; }

  .btn-select {
    @apply bg-white;
  }
}
</style>
