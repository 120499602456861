<template>
  <div id="">
    <h1>Page des Actualités</h1>
    <p>On n'affiche seulement que les actyaliés de type : {{ $route.query.type }}</p>
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>
