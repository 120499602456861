<template>
  <div
    class="toolbar-search--panel"
    v-if="$store.state.engine.isPannelOpen"
    v-click-outside="handleClosePannel"
  >
    <div v-if="hasResults">
      <div v-if="searchTypeValue === 'suppliers'">
        <section class="panel-wrapper--filters">
          <div
            v-if="tags.length > 0"
            class="filter-tags"
          >
            <tag
              v-for="(tag, i) in tags"
              :key="i"
              :label="tag"
              :engine="true"
            />
          </div>
          <div
            class="filter filter-partners"
            v-if="supplierPartners.length > 0"
          >
            <h2>Fournisseurs avec qui vous travaillez déjà</h2>
            <engine-supplier-partners :suppliers="supplierPartners" />
          </div>
          <div
            v-if="suppliers.length > 0"
            class="filter filter-suppliers"
          >
            <h2>Fournisseurs</h2>
            <engine-suppliers :suppliers="suppliers" />
          </div>
          <div
            v-if="families.length > 0"
            class="filter filter-families"
          >
            <h2>Famille et sous-famille</h2>
            <engine-families :families="families" />
          </div>
          <div class="filter filter-offers">
            <h2>Offres négociées</h2>
            <button
              class="btn btn-rounded"
              @click="handleGoOffers"
            >
              Voir les offres négociées liées à cette recherche
            </button>
          </div>
        </section>

        <section
          v-if="articles.rows && articles.rows.length > 0"
          class="panel-wrapper--newslinks background"
        >
          <div class="articles-header">
            <div class="articles-amount">Articles ({{ articles.amount }})</div>
            <a
              class="all-articles"
              href="/articles"
            >
              Voir tous les articles
            </a>
          </div>
          <div class="displayer displayer-articles">
            <engine-article-links :articles="articles.rows" />
          </div>
        </section>
      </div>
      <div v-else>
        <section class="mt-8">
          <div
            v-if="subscribers.length > 0"
            class="filter filter-suppliers"
          >
            <h2>Adhérents</h2>
            <engine-subscribers :subscribers="subscribers" />
          </div>
          <div v-else>Aucun résultat</div>
        </section>
      </div>
    </div>
    <div v-else>
      <section class="panel-wrapper--filters no-result">
        <h2>Un besoin sur-mesure ?</h2>
        <div
          @click="handleGoContact"
          class="tag"
        >
          Demande de contact Mercurial
        </div>
        <div class="tag">
          Liste acheteurs Mercurial
        </div>
        <div class="categs-list">
          <div
            v-for="(categ, i) in categories"
            :key="i"
            class="categ"
            @click="handleGoCateg(categ.slug)"
          >
            <div
              :style="{ backgroundImage: 'url(' + categ.media.source + ')' }"
              alt=""
              class="img"
            ></div>
            <span>{{ categ.title }}</span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// Dépendences extérieures
import ClickOutside from 'vue-click-outside'

// Composants globaux de la plateforme
import Tag from '@/components/commons/Tag.vue'

// Composants pour le template du moteur de recherche
import EngineSupplierPartners from '@/components/templates/EngineSupplierPartners.vue'
import EngineSuppliers from '@/components/templates/EngineSuppliers.vue'
import EngineSubscribers from '@/components/templates/EngineSubscribers'
import EngineFamilies from '@/components/templates/EngineFamilies.vue'
import EngineArticleLinks from '@/components/templates/EngineArticleLinks.vue'

export default {
  components: {
    Tag,
    EngineSupplierPartners,
    EngineSuppliers,
    EngineSubscribers,
    EngineFamilies,
    EngineArticleLinks
  },

  computed: {
    tags () {
      return this.$store.state.engine.tags
    },

    suppliers () {
      return this.$store.state.engine.suppliers
    },

    subscribers () {
      return this.$store.state.engine.subscribers
    },

    supplierPartners () {
      return this.$store.state.engine.supplierPartners
    },

    families () {
      return this.$store.state.engine.families
    },

    articles () {
      return this.$store.state.engine.articles
    },

    hasResults () {
      return this.$store.state.engine.hasResults
    },

    searchTypeValue () {
      return  this.$store.state.engine.searchTypeValue
    },

    categories () {
      return this.$store.state.engine.categories
    }
  },

  methods: {
    handleClosePannel () {
      this.$store.dispatch('engine/panelStatus', false)
    },

    handleGoOffers () {
      this.$router.push(`/offres?motclef=${this.$store.state.engine.keyword}`)
      this.$store.dispatch('engine/panelStatus', false)
    },

    handleGoCateg (slug) {
      this.$router.push(`/fournisseurs/${slug}`)
    },

    handleGoContact () {
      this.$router.push({ name: 'form' })
    }
  },

  directives: {
    ClickOutside
  }
}
</script>

<style lang="postcss" scoped>

.filter:not(:last-child) {
  @apply pb-6 border-b-2
}

/* Element de style h2 personnalisé */
.filter h2 {
  @apply text-primary font-bold mb-2
}

/* Element de liste qui affiche les informations */
.filter > [class*="--list"] {
  @apply grid grid-cols-2 gap-2
}

.filter-tags .tag {
  @apply border-secondary text-secondary capitalize;
}

/* Affichage du nom du fournisseur */
.filter >>> .list-item--name {
  @apply inline-block uppercase font-bold mr-2
}

/* Style de la catégorie mise en avant */
.filter .list-item--category {
  text-transform: capitalize;
}

/* Nombre d'éléments */
.filter >>> .list-item--amount {
  @apply font-bold
}

/* Texte dans la zone des familles */
.filter >>> .list-item--description {
  @apply block
}

.panel-wrapper--newslinks {
  @apply p-5
}

.articles-header {
  @apply flex justify-between
}

.articles-amount {
  @apply font-bold text-lg
}

.all-articles {
  @apply p-2 bg-white rounded
}

.displayer-articles {
  @apply mt-2
}

.no-result .categs-list {
  @apply flex flex-wrap justify-between my-2
}

.no-result .categ {
  width: 30%;
  @apply flex items-center my-2 cursor-pointer
}

.no-result .categ .img {
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  @apply mr-2 bg-cover
}
</style>
