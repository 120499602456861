const SET_LOADING = (state, status) => {
  state.loading = status
}

const SET_DETAIL_ENTITY = (state, data) => {
  state.detail.entity = data
}

const SET_DETAIL_FILTERS = (state, data) => {
  state.detail.filters = data
}

const SET_LISTING = (state, data) => {
  state.listing = data
}

const SET_FAMILIES = (state, data) => {
  state.families = data
}

export default {
  SET_LOADING,
  SET_DETAIL_ENTITY,
  SET_DETAIL_FILTERS,
  SET_LISTING,
  SET_FAMILIES,
}
