<template>
  <div class="homepage">
    <section class="homepage-categories">
      <div
        v-if="isSubscriber"
        class="container">
        <list-categories />
      </div>
    </section>

    <section class="homepage-dashboard">
      <div class="container">
        <div class="grid grid-cols-1 xl:grid-cols-24 gap-4">
          <div class="grid col-span-1 xl:col-span-12 gap-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <card-references class="order-1 md:order-none"/>
              <div class="grid gap-4">
                <dashboard-stats />
                <card-webinar />
              </div>
            </div>

            <div class="grid grid-cols-12 gap-4">
              <snippet-articles class="md:col-span-8 col-span-12" />
              <snippet-formations class="md:col-span-4 col-span-12" />
            </div>
          </div>
          <div class="grid col-span-1 xl:col-span-12 grid-rows-1 gap-4">
            <card-banner />
            <dashboard-market />
            <card-documents />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// Page components
import CardBanner from './blocks/CardBanner.vue'
import CardDocuments from './blocks/CardDocuments'
import CardReferences from './blocks/CardReferences.vue'
import CardWebinar from './blocks/CardWebinar.vue'
import DashboardMarket from './blocks/DashboardMarket.vue'
import DashboardStats from './blocks/DashboardStats'
import SnippetArticles from './blocks/SnippetArticles.vue'
import SnippetFormations from './blocks/SnippetFormations.vue'

// Global components
import ListCategories from '@/components/customs/categories/ListCategories'

export default {
  name: 'Home',

  components: {
    CardBanner,
    CardDocuments,
    CardReferences,
    CardWebinar,
    DashboardMarket,
    DashboardStats,
    SnippetArticles,
    SnippetFormations,
    ListCategories
  },

  computed: {
    isSubscriber () {
      return this.$store.getters.userRole === 'subscriber'
    }
  }
}
</script>
