export default {
  methods: {
    /**
     * En fonction du rôle de l'user, on cache certains menus
     */
    hasPermission (role) {
      return role.hiddenFor
        ? !role.hiddenFor.includes(this.$store.getters.userRole)
        : true
    }
  }
}
