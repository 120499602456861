<template>
  <section class="flex flex-wrap my-8">
    <div class="w-full xl:w-2/3">
      <div class="flex flex-col items-start justify-between h-full b-background">
        <div class="block hide-on-print">
          <span
            v-if="a11y"
            class="label">
            Accessible aux personnes en situation de handicap ou avec particularité
          </span>
        </div>

        <div class="block with-bg">
          <h1 class="uppercase font-bold text-xl text-tertiary">{{ title }}</h1>
          <p
            v-if="metadatas.category"
            class="inline-block">
            <span>Catégorie : </span>
            <span class="uppercase font-bold text-lg">{{ metadatas.category }} </span>
          </p>
          <p
            v-if="metadatas.branch"
            class="inline-block">
            <span class="ml-4">Branche d'activité : </span>
            <span class="uppercase font-bold text-lg">{{ metadatas.branch }}</span>
          </p>
        </div>

        <div class="flex items-center interspace-horizontal">
          <div
            v-if="metadatas.taux"
            class="flex flex-row">
            <div class="block ml-2">
              <span class="text-lg">Taux de satisfaction lors des dernières sessions: 
                <span v-if="(metadatas.taux <= 25)" class="font-bold taux-color-red text-xl">{{ metadatas.taux }} %</span>
                <span v-else-if="(metadatas.taux <= 50)"  class="font-bold taux-color-orange text-xl">{{ metadatas.taux }} %</span>
                <span v-else-if="(metadatas.taux <= 75)"  class="font-bold taux-color-yellow text-xl">{{ metadatas.taux }} %</span>
                <span v-else class="font-bold taux-color-green text-xl">{{ metadatas.taux }} %</span>
              </span>
            </div>
          </div>
          <div
            v-else-if="metadatas.rate"
            class="flex flex-row">
            <img
              v-for="star in numberOfFilledStarsToDisplay"
              :key="star.index"
              src="@/assets/images/icons/star-full.svg"
              alt="Etoile vide">
            <img
              v-if="showHalfFilledStar"
              src="@/assets/images/icons/star-half.svg"
              alt="Etoile vide">
            <img
              v-for="star in numberOfEmptyStarsToDisplay"
              :key="star.index"
              src="@/assets/images/icons/star-empty.svg"
              alt="Etoile vide">
            <div class="block ml-2">
              <span class="text-xl font-bold">{{ metadatas.rate }} </span> / 10
            </div>
          </div>

          <a
            v-if="interviews.length > 0"
            v-scroll-to="'#interviews'"
            href="#"
            class="text-black text-sm hide-on-print">Voir les témoignages</a>
          <icon
            class="hide-on-print"
            icon="imprimer"
            size="md"/>
          <a
            href="#"
            @click="handlePrint"
            class="text-black text-sm hide-on-print">Imprimer le programme</a>
        </div>
      </div>
    </div>

    <div class="w-full xl:w-1/3">
      <div class="flex flex-col items-center justify-center hide-on-print">
        <button
          class="btn btn-outline--tertiary"
          @click="handleGoFormations">Les autres formations Mercurial</button>
        <div class="flex flex-wrap items-center mt-8">
          <img
            class="h-full logo-certif"
            v-for="certif in medias.certifications"
            :key="certif.index"
            :src="certif.source"
            :alt="certif.alt">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      title: state => state.articles.current.title,
      category: state => state.articles.current.category,
      metadatas: state => state.articles.current.metadatas,
      medias: state => state.articles.current.medias,
      interviews: state => state.articles.current.interviews,
      a11y: state => state.articles.current.access.a11y
    }),

    numberOfFilledStarsToDisplay () {
      return this.metadatas.rate % 2 !== 0
        ? Math.round(this.metadatas.rate / 2) - 1
        : Math.round(this.metadatas.rate / 2)
    },

    showHalfFilledStar () {
      return this.metadatas.rate % 2 !== 0
    },

    numberOfEmptyStarsToDisplay () {
      return this.metadatas.rate % 2 !== 0
        ? Math.round(5 - (this.metadatas.rate / 2)) - 1
        : Math.round(5 - (this.metadatas.rate / 2))
    }
  },

  methods: {
    handlePrint () {
      window.print()
    },

    handleGoFormations () {
      this.$router.push({ name: 'formations' })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  @apply inline-block bg-dark text-white;
  @apply py-2 px-4;

  + .label {
    @apply ml-4;
  }
}

.taux-color-red {
  color: red;
}
.taux-color-yellow {
  color: #FDDD38;
}
.taux-color-orange {
  color: orange;
}
.taux-color-green {
  color: green;
}


</style>
