<template>
  <span v-if="isLoading">
    Chargement...
  </span>

  <ul
    v-else
    class="suppliers-list">
    <list-suppliers-item
      v-for="(supplier, index) in suppliersList"
      :key="index"
      :supplier="supplier"/>
  </ul>
</template>

<script>
import ListSuppliersItem from '@/components/customs/suppliers/ListSuppliersItem.vue'

export default {
  components: {
    ListSuppliersItem
  },

  computed: {
    suppliersList () {
      return this.$store.state.suppliers.list.rows
    },

    isLoading () {
      return this.$store.state.suppliers.isProcessing
    }
  }
}
</script>
