import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    isProcessing: false,
    current: {
      metadatas: {
        linkedin: null
      }
    },
    list: {
      rows: [],
      pager: {}
    },
    filters: {
      default: {
        label: 'Filtrer par',
        items: []
      }
    },
    autocompletesSuggestions: {
      naf: [],
      activitySector: [],
      affiliate: []
    },
    isAffiliates: false,
    autocompletesValues: {
      naf: {
        label: '',
        value: ''
      },
      activitySector: {
        label: '',
        value: ''
      },
      affiliate: {
        label: '',
        value: ''
      }
    }
  },

  actions,
  mutations,
  getters
})
