<template>
  <div
    v-if="isLoading"
    class="">Chargement...</div>

  <div
    v-else-if="offers"
    class="offers-list">
    <list-offers-item
      v-for="(offer, index) in offers"
      :key="index"
      :offer="offer" />

    <pagination :pageCount="pageCount" />
  </div>

  <div
    v-else
    class="">
    Aucune offre disponible
  </div>
</template>

<script>
import ListOffersItem from '@/components/customs/offers/ListOffersItem.vue'
import Pagination from '@/components/commons/Pagination.vue'

export default {
  components: {
    ListOffersItem,
    Pagination
  },

  computed: {
    offers () {
      return this.$store.state.offers.list.rows
    },

    isLoading () {
      return this.$store.state.offers.isProcessing
    },

    pageCount () {
      return this.$store.state.offers.list.pager ? this.$store.state.offers.list.pager.pageCount : 1
    }
  }
}
</script>
