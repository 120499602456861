<template>
  <card
    edged
    dense
    leftTitle
    >
    <template slot="header"
      v-if="content.title">
      <icon
        v-if="content.icon"
        :icon="content.icon"
        size="sm" />
      <h2 class="uppercase text-base font-bold">
        {{ content.title }}
      </h2>
    </template>

    <article>
      <div
        v-if="displayDatas && displayDatas.lastTwelveMonths"
        class="flex items-center justify-between">
        <span>(sur les 12 derniers mois)</span>
        <span class="text-lg font-bold">{{ displayDatas.lastTwelveMonths }}</span>
      </div>

      <div
        v-if="displayDatas && displayDatas.lastSixMonths"
        class="flex items-center justify-between">
        <span>Sur les 3 derniers mois</span>
        <span class="text-lg font-bold">{{ displayDatas.lastSixMonths }}</span>
      </div>

      <div
        v-if="displayDatas && displayDatas.lastThreeMonths"
        class="flex items-center justify-between">
        <span>Sur les 3 derniers mois</span>
        <span class="text-lg font-bold">{{ displayDatas.lastThreeMonths }}</span>
      </div>

      <div
        v-if="displayDatas && displayDatas.lastYear"
        class="flex items-center justify-between">
        <span>(de l'année précédente)</span>
        <span class="text-lg font-bold">{{ displayDatas.lastYear }}</span>
      </div>

      <div
        v-if="displayDatas && displayDatas.currentSubscribers"
        class="flex items-center justify-between">
        <span>Adhérents utilisant vos services</span>
        <span class="text-lg font-bold">{{ displayDatas.currentSubscribers }}</span>
      </div>

      <div
        v-if="displayDatas && displayDatas.remainingSubscribers"
        class="flex items-center justify-between">
        <span>Potentiel adhérents restant</span>
        <span class="text-lg font-bold">{{ displayDatas.remainingSubscribers }}</span>
      </div>

      <p
        v-if="displayDatas && displayDatas.paragraph"
        class="text-xs">
        {{ displayDatas.paragraph }}
      </p>
    </article>

    <nav
      v-if="content.buttons" 
      :class="[
        'flex', 
        'items-center', 
        'mt-4',
        (content.buttons.length > 1 && isAffiliates) ? 'justify-between' : 'justify-center', 
      ]"
    >
      <template v-for="(button, index) in content.buttons">
        <button
          v-if="button.type === 'anchor'"
          :key="index"
          :class="['btn', 'btn-small', 'btn-outline--primary']"
          slot="footer"
          @click="handleClick(button.link)"
        >
          {{ button.label }}
        </button>
        <section-card-modal
          v-else-if="isAffiliates"
          :key="index"
          :title="button.label"
          :endpoint="button.endpoint"
          :popin_id="button.popin_wording.id"
          :popin_icon="button.popin_wording.icon"
          :popin_title="button.popin_wording.title"
          :popin_label_group="button.popin_wording.label_group"
          slot="footer"
        />
      </template>
    </nav>
  </card>
</template>

<script>
import Card from '@/components/commons/Card'
import SectionCardModal from '@/components/customs/dashboard/SectionCardModal'

export default {
  components: {
    Card,
    SectionCardModal
  },

  props: {
    content: {
      type: Object,
      default: {
        title: '',
        value: '',
        icon: '',
        lastTwelveMonths: 0,
        lastSixMonths: 0,
        currentSubscribers: 0,
        remainingSubscribers: 0,
        buttons: {}
      }
      ,
      required: true
    },
  },

  data: () => ({
    chartType: 'month'
  }),

  watch:{
    '$store.state.subscribers.isAffiliates'(){
      this.fetchCardData().then(
        this.$forceUpdate()
      )
    },
  },

  computed: {
    displayDatas () {
      return this.$store.state.content.dashboard[this.content.value]
    },
    isAffiliates(){
      return this.$store.state.subscribers.isAffiliates
    }
  },

  methods: {
    async fetchCardData () {

      this.$store.dispatch(
        'requestDashboardDatas',
        this.$store.getters.userRole === 'supplier' ? 
        this.content.value : 
        [
          this.content.value,
          ((this.$route.name === 'dashboard-slug' || !this.isAffiliates) ? this.$route.params.slug : 'headquarter')
        ]
      )
    },

    handleClick (link) {
      if (link === '/dashboard?tab=family') {
        var query = { tab: 'family' }
        this.$router.push({ query })
        this.$scrollTo('#amChart', 500, {})
      } else {
        this.$router.push(link)
      }
    }
  },

  mounted () {
    this.fetchCardData()
  },
}
</script>
