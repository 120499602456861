<template>
  <div
    v-if="!hasValue"
    class="autocomplete-filter">
    <input class="form-input" type="text" v-model="selection" :placeholder="placeholder"
           @keydown.enter="enter"
           @input="change"
    />
<!--    <icon
      v-if="autocompleteValue.value"
      @click.native="searchClick"
      class="search"
      icon="rechercher"
    />-->
    <ul
      v-click-outside="handleCloseSuggestions"
      v-if="suggestions && suggestions.length > 0"
    >
      <li v-for="(suggestion, i) in suggestions"
          @click="suggestionClick(suggestion)"
      >
        <div class="cursor-pointer">{{ suggestion.label }}</div>
      </li>
    </ul>
    <ul v-else-if="!isloading && selection.length > 1">
      <li>Aucun résultat</li>
    </ul>
  </div>
</template>

<script>
import axios from '@/axios'
import ClickOutside from "vue-click-outside";

export default {
  name: 'AutocompleteFilter',

  data() {
    return {
      selection: '',
      isloading: false
    }
  },

  props: {
    filterName: {
      type: String,
      default: () => '',
      required: true
    },
    placeholder: {
      type: String,
      default: () => '',
      required: true
    },
    entityType: {
      type: String,
      default: () => '',
      required: false
    },
    stateKey: {
      type: String,
      default: function () {
        return this.$route.name
      },
      required: false
    }
  },

  computed: {
    suggestions () {
      return this.$store.state[this.stateKey].autocompletesSuggestions[this.filterName] ?
        this.$store.state[this.stateKey].autocompletesSuggestions[this.filterName] : []
    },

    autocompleteValue () {
      return this.$store.state[this.stateKey].autocompletesValues[this.filterName]
    },

    hasValue () {
      return this.autocompleteValue.value.length > 0
    }
  },

  beforeMount() {
    this.setDefaultValue()
  },

  methods: {
    async enter() {
      /*if (this.suggestions.length > 0) {
        await this.suggestionClick(this.suggestions[0])
      }*/
    },

    async setDefaultValue () {
      const queryValue = this.$route.query[this.filterName]
      if(queryValue) {
        let label = ''

        if (this.entityType) {
          const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/${this.entityType}/${queryValue}/label`)
          label = res.data.label

        } else {
          label = queryValue
        }

        await this.$store.commit('SET_AUTOCOMPLETE_VALUE', {
          filterName : this.filterName,
          label: label,
          value: queryValue
        })
      }
    },

    async change() {
      this.isloading = true

      await this.$store.dispatch('resetAutocompleteFilterSuggestions', this.filterName)

      await this.$store.dispatch('requestAutocompleteFilterSuggestions', {
        params: {
          s: this.selection,
          ...this.$route.query
        },
        filter: this.filterName
      })

      this.isloading = false
    },

    async suggestionClick(suggestion) {
      await this.$store.dispatch('resetAutocompleteFilterSuggestions', this.filterName)

      await this.$store.commit('SET_AUTOCOMPLETE_VALUE', {
        filterName : this.filterName,
        label: suggestion.label,
        value: suggestion.value
      })

      // this.selection = suggestion.label

      let query = { ...this.$route.query }
      query[this.filterName] = this.autocompleteValue.value
      await this.$router.replace({ query })

      this.selection = ''
    },

    /*async searchClick () {
      if (!this.autocompleteValue.value) {
        return
      }
      let query = { ...this.$route.query }
      query[this.filterName] = this.autocompleteValue.value
      await this.$router.replace({ query })
    },*/

    async handleCloseSuggestions () {
      await this.$store.dispatch('resetAutocompleteFilterSuggestions', this.filterName)
    }
  },

  /*watch: {
    '$route.query' (to, from) {
      if (from[this.filterName] && !to[this.filterName]) {
        this.selection = ''
      }
    }
  },*/

  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
.autocomplete-filter {
  @apply relative my-3;

  .form-input {
    width: 250px;
    @apply relative mb-0 bg-gray-100 border-gray-400 rounded-full;
  }

  .search {
    top: 7px;
    right: 10px;
    @apply absolute cursor-pointer;
  }

  ul {
    width: 270px;
    top: 15px;
    left: -10px;
    @apply absolute m-0 pt-8 pb-2 px-2 border rounded shadow bg-white;

    li {
      @apply p-2 border-gray-400 font-bold;
    }
  }
}
</style>
