<template>
  <section class="autocomplete-filters">
      <autocomplete-filter
        v-for="(filter, i) in filters"
        :key="i"
        :filter-name="filter.name"
        :placeholder="filter.placeholder"
        :entity-type="filter.entityType"
        :state-key="stateKey"
      />
  </section>
</template>

<script>
import AutocompleteFilter from '@/components/commons/AutocompleteFilter'

export default {
  components: {
    AutocompleteFilter
  },

  props: {
    filters: {
      type: Array,
      default: () => [
        {
          name: '',
          placeholder: ''
        }
      ],
      required: true
    },
    stateKey: {
      type: String,
      default: function () {
        return this.$route.name
      },
      required: false
    }
  }
}
</script>

<style lang="scss">
.autocomplete-filters {
  @apply px-8;

  .autocomplete-filter {

    &:nth-of-type(1) {
      .form-input { @apply z-40; }
      .search { @apply z-40 }
      ul { @apply z-30; }
    }

    &:nth-of-type(2) {
      .form-input { @apply z-20; }
      .search { @apply z-20 }
      ul { @apply z-10; }
    }
  }
}
</style>
