const SET_MACROECONOMIC_DATAS = (state, datas) => {
  state.macroeconomic = datas
}

const SET_ARTICLES = (state, datas) => {
  state.articles = datas
}

const SET_WEBINAR = (state, datas) => {
  state.webinar = datas
}

const SET_DOCUMENTS = (state, datas) => {
  state.documents = datas
}

const SET_REFERENCES = (state, datas) => {
  state.references = datas
}

const SET_FORMATIONS = (state, datas) => {
  state.formations = datas
}

const SET_BANNERS = (state, datas) => {
  state.banners = datas
}

export default {
  SET_MACROECONOMIC_DATAS,
  SET_ARTICLES,
  SET_WEBINAR,
  SET_DOCUMENTS,
  SET_REFERENCES,
  SET_FORMATIONS,
  SET_BANNERS
}
