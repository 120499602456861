<template>
  <div>
    <!-- Affichage en card, avec plus d'infos. -->
    <div v-if="documentsList && documentsList.length > 0">
      <div
        v-if="card"
        class="list_card_documents">
        <list-documents-card-item
          v-for="(document, index) in documentsList"
          :key="index"
          :document="document"
          class="list_card_documents-item" />
      </div>

      <!-- Affichage en liste simple sur une ligne. -->
      <div
        v-else
        class="list_documents"
        :class="[
          { 'max-h-120' : restricted },
          { 'border-dark border-l-2' : bordered }
        ]">
        <div
          class="list_documents-item document"
          v-for="(document, index) in documentsList"
          :key="index">
          <icon
            class="list_documents-item--icon"
            :icon="document.format ? document.format : 'pdf'"
            size="sm"></icon>
            <a
            class="list_documents-item--title"
            target="_blank"
            :href="document.link">
            {{ document.title }}
      </a>
        </div>
      </div>
    </div>
    <div
      v-else
      class="list_documents">Aucun document</div>
    <div
      v-if="catalog"
      class="catalog"
    >
      <div
        class="icon-text underline"
      >
        <icon
          icon="fleche-bleue"
          size="sm" />
        <a
          class="text-black"
          :href="catalog"
          target="_blank"
        >Consulter le catalogue en ligne</a>
      </div>
    </div>
  </div>
</template>

<script>
import ListDocumentsCardItem from '@/components/customs/documents/ListDocumentsCardItem'

export default {
  components: {
    ListDocumentsCardItem
  },

  props: {
    // Documents reçus depuis le parent
    documents: {
      type: Array,
      default: () => [],
      required: false
    },

    // Pour l'onglet "Tarifs" d'une offre d'un fournisseur -> Catalogue en ligne
    catalog: {
      type: String,
      default: '',
      required: false
    },

    // Permet l'affichage des cards complexes
    card: {
      type: Boolean,
      default: false,
      required: false
    },

    // Permet de restreindre la hauteur à 120px
    restricted: {
      type: Boolean,
      default: false,
      required: false
    },

    // Ajoute une barre sur le côté
    bordered: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    /**
     * En fonction du contexte, soit on affiche les documents fecthés lors de la
     * dernière requête API, soit les documents reçus en props via le parent
     * (dans le cas d'une liste des offres par exemple). Dans tous les cas, ce
     * sont les documents passés via les props qui sont prioritaires.
     */
    documentsList () {
      return this.documents.length > 0 || this.$route.name === 'supplierOffers' || this.$route.name === 'offers'
        ? this.documents
        : this.$store.state.documents.list
    }
  },

  methods: {
    downloadDocument(slug) {
      this.$router.push({ name: 'DocumentFile', params: { slug }});
    }
  }
}
</script>

<style lang="scss">
 .catalog {
   display: flex;
   justify-content: center;

   a {
     @apply font-bold;
     font-size: 15px;
   }
 }
</style>
