<template>
  <card
    class="card--dashboard-stats"
    centered-wrappers
    edged>
    <template slot="header">
      <icon
        icon="tableau-bord-white"
        size="lg" />
      <h2>Votre tableau de bord en résumé</h2>
    </template>

    <template slot="default">
      <table class="table table-blank">
        <tr
          v-for="(data, index) in displayDatas"
          :key="index"
          class="table-line">
          <td class="table-line--key">
            {{data.key}}
          </td>
          <td class="table-line--value">
            {{data.value}}
          </td>
        </tr>
      </table>
    </template>

    <template slot="footer">
      <button v-if="currentUser.isFiliale && this.$store.getters.userRole == 'subscriber'"
        class="btn btn-small"
        @click="handleGoDashboardwithFiliale(currentUser.isFiliale)">Voir votre tableau de bord dans le détail</button>
        <button v-else
        class="btn btn-small"
        @click="handleGoDashboard()">Voir votre tableau de bord dans le détail
        </button>
      <button
        class="btn btn-small"
        @click="handleGoDeclareCA"
      >Déclarer mon chiffre d'affaire Mercurial</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

// Import des mocks en attendant l'API
// import dataStats from '../../../../../mocks/homepage/stats.json'

export default {
  components: {
    Card
  },

  computed: {
    displayDatas () {
      return this.$store.state.content.dashboard.home
    },

    currentUser () {
      return this.$store.state.authentication.user
    },
  },

  methods: {
    fetchCardData () {
      this.$store.dispatch('requestDashboardDatas', 'home')
    },

    handleGoDeclareCA () {
      window.open('https://crm.e-deal.net/mercurialPortal/list_my_declarationca_.fl', '_blank')
    },

    handleGoDashboardwithFiliale(isFiliale) {
      this.$router.push(`/dashboard/${isFiliale}`)
    },

    handleGoDashboard() {
      this.$router.push({ name: 'dashboard' })
    },

  },

  beforeMount () {
    // Requête de l'API distante
    this.fetchCardData()
  }
}
</script>

<style lang="scss" scoped>
.table {
  @apply w-full;
  &-line {
    &--key {
      @apply font-bold uppercase text-sm;
    }

    &--value {
      @apply font-bold text-right text-lg;
    }
  }
}

.table-line--value {
  white-space: nowrap;
}
</style>
