<template>
  <section class="page-drawer--section">
    <button
      class="btn btn-primary w-full"
      :class="{ 'disabled' : isFavorite}"
      @click="handleSortFavorite">
      Adhérents repérés
    </button>
    <button
      class="btn btn-primary w-full mt-3"
      :class="{ 'disabled' : isTagged}"
      @click="handleSortTagged">
      Mes adhérents
    </button>
    <button
      v-if="isTagged || isFavorite"
      class="btn btn-primary w-full mt-3"
      @click="handleReset">
      Réinitialiser
    </button>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      isFavorite: this.$route.query.favorites === 'true',
      isTagged: this.$route.query.mySubscribers === '1'
    }
  },

  methods: {
    handleSortTagged () {
      this.isFavorite = false
      this.isTagged = true
      this.$router.push({ query: { mySubscribers: '1' } })
    },

    handleSortFavorite () {
      this.isFavorite = true
      this.isTagged = false
      this.$router.push({ query: { favorites: 'true' } })
    },

    handleReset () {
      var query = { ...this.$route.query }
      delete query.mySubscribers
      delete query.favorites
      this.isTagged = false
      this.isFavorite = false
      this.$router.push({ query })
      this.$store.dispatch('drawerStatus', false)
    }
  }
}
</script>
