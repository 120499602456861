<template>
  <div
    class="flex flex-wrap items-center">
    <tag
      v-if="collaborator"
      :label="workWithLabel"
      variant="secondary"
      icon="check-orange" />
  </div>
</template>

<script>
import Tag from '@/components/commons/Tag'
import $axios from '@/axios'

export default {
  components: {
    Tag
  },

  props: {
    collaborator: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    workWithLabel () {
      if (this.$store.getters.userRole === 'supplier') {
        return 'Vous travaillez avec cet adhérent'
      } else {
        return 'Vous travaillez avec ce fournisseur'
      }
    }
  }
}
</script>
