import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  state: {
    dashboard: {
      // Home
      home: [],

      'ca/month': {
      	chartDatas: [],
      	chartTitle: '',
      	chartType: ''
      },
      'card/subscriber': [],
      'card/supplier': [],

      // if new card, please add the new resource uri here
      revenue: {},
      summary: {},
      roadmap: {},
      families: {},
      suppliers: {},
      views: {}
    }
  },

  actions,
  mutations,
  getters
})
