<template>
  <div class="wrapper profil-page">

    <!-- HEADER -->
    <div class="container">
      <div class="container-header--title">
        <h1>Votre profil</h1>
      </div>
      <div class="container-header--links">
        <a
          href="https://crm.e-deal.net/mercurialPortal/list_my_declarationca_.fl"
          target="_blank"
          class="btn btn-outline btn-outline--primary"
        >
          <icon
            :icon="'actualiser'" />
          <span>Déclarer mon chiffre d'affaire</span>
        </a>

        <modal :class="'edit-password'">
          <button
            slot="activator"
            class="btn btn-outline btn-outline--primary"
          >
            <icon
              :icon="'editer'" />
            <span>Modifier mon mot de passe</span>
          </button>
          <NewPasswordForm />
        </modal>
          
        <aside class="container-header--aside">
          <button v-if="currentUser.isFiliale && this.$store.getters.userRole == 'subscriber'"
            @click="handleGoDashboardwithFiliale(currentUser.isFiliale)"
            class="btn btn-dark"
          >
            <icon
              :icon="'tableau-bord'" />
            <span>Voir mon tableau de bord</span>
          </button>
          <button v-else
            @click="handleGoDashboard"
            class="btn btn-dark"
          >
            <icon
              :icon="'tableau-bord'" />
            <span>Voir mon tableau de bord</span>
          </button>
        </aside>
      </div>
    </div>

    <div class="background">
      <div class="container">

        <!-- INFOS GÉNÉRALES -->
        <div
          v-if="metadatas"
          class="sub-item metadatas">
          <div class="card metadatas-card">
            <div class="you-are">
              <icon
                :icon="metadatas.type === 'supplier' ? 'fournisseurs' : 'adherents'"/>
              <h3>Vous êtes {{ metadatas.typeLabel }}</h3>
            </div>
            <p v-if="metadatas.profil">Profil : <strong>{{ metadatas.profil }}</strong></p>
            <p v-if="metadatas.fonction">Fonction : <strong>{{ metadatas.fonction }}</strong></p>
            <p v-if="metadatas.service">Service : <strong>{{ metadatas.service }}</strong></p>
          </div>
          <div class="card metadatas-card">
            <p v-if="metadatas.lastname">Nom : <strong>{{ metadatas.lastname }}</strong></p>
            <p v-if="metadatas.firstname">Prénom : <strong>{{ metadatas.firstname }}</strong></p>
            <p v-if="metadatas.email">Email : <strong>{{ metadatas.email }}</strong></p>
            <p v-if="metadatas.tel || metadatas.phone">Téléphone : <strong>{{ metadatas.tel }} | {{ metadatas.phone }}</strong></p>
          </div>
        </div>

        <!-- IDENTITÉ -->
        <div
          v-if="identity"
          class="sub-item identity">
          <h2>Identité de l'entreprise</h2>
          <div class="card">
            <h3>{{ identity.title }}</h3>
            <div class="content">
              <div class="logo-address">
                <div class="logo">
                  <img
                    :src="identity.medias.thumbnail.source"
                    alt="">
                </div>
                <div class="address">
                  {{ identity.address.street }}<br>
                  {{ identity.address.postal }}
                  {{ identity.address.city }}
                  <p
                    class="siret"
                    v-if="identity.metadatas.siret"
                  >
                    SIRET : <strong>{{ identity.metadatas.siret }}</strong>
                  </p>
                </div>
              </div>
              <div class="other-infos">
                <p v-if="identity.metadatas.category">Catégorie d'achat : <strong>{{ identity.metadatas.category }}</strong></p>
                <p v-if="identity.metadatas.family">Famille d'achat : <strong>{{ identity.metadatas.family }}</strong></p>
                <p v-if="identity.metadatas.staff">Effectif : <strong>{{ identity.metadatas.staff }}</strong></p>
                <p v-if="identity.metadatas.sector">Secteur d'activity : <strong>{{ identity.metadatas.sector }}</strong></p>
                <p v-if="identity.metadatas.ca">CA : <strong>{{ identity.metadatas.ca }}</strong></p>
                <p v-if="identity.metadatas.naf">NAF : <strong>{{ identity.metadatas.naf }}</strong></p>
              </div>
            </div>
            <div class="see-more">
              <button
                @click="handleGoFiche(identity.slug)"
                class="btn btn-outline btn-outline--primary"
              >
                <icon
                  :icon="'play'"
                  :size="'xs'"
                />
                <span>Consulter ma fiche</span>
              </button>
              <button
                @click="handleGoContactForm"
                class="btn btn-outline btn-outline--primary"
              >
                <icon
                  :icon="'editer'"
                  :size="'xs'"
                />
                <span>Demander à modifier ma fiche</span>
              </button>
            </div>
          </div>
        </div>

        <!-- CONTACTS -->
        <div
          v-if="contacts"
          class="sub-item contacts">
          <h2>Contacts</h2>
          <div class="card">
            <div
              v-for="(contact, i) in contacts"
              :key="i"
              class="contact"
            >
              <div><strong>{{ contact.lastname }} {{ contact.firstname }}</strong></div>
              <div>{{ contact.service }}</div>
              <div>{{ contact.fonction }}</div>

              <modal>
                <button
                  slot="activator"
                  class="btn btn-outline btn-outline--primary btn-small">
                  En savoir +
                </button>
                <div class="p-6">
                  <div v-if="contact.email">Email : <strong>{{ contact.email }}</strong></div>
                  <div v-if="contact.tel">Téléphone : <strong>{{ contact.tel }}</strong></div>
                  <div v-if="contact.phone">Mobile : <strong>{{ contact.phone }}</strong></div>
                  <div v-if="contact.address">Adresse :
                    <strong>{{ contact.address.street }}<br>
                      {{ contact.address.postal }}
                      {{ contact.address.city }}</strong>
                  </div>
                </div>
              </modal>
            </div>
          </div>
        </div>

        <!-- FORMATIONS VALIDÉES À VENIR -->
        <div
          v-if="formations && formations.scheduled && formations.scheduled.length > 0"
          class="sub-item scheduled-formations"
        >
          <div class="swiper-button-prev swiper-button-black"></div>
          <div class="swiper-button-next swiper-button-black"></div>
          <div
            v-swiper:formationSwiper="swiperOptions">
            <h2>Formations validées à venir</h2>
            <div class="swiper-wrapper">
              <div
                class="swiper-slide scheduled-formation card"
                v-for="(formation, i) in formations.scheduled"
                :key="i"
              >
                <p class="dates">{{ formation.metadatas.dates }}</p>
                <h3
                  @click="handleGoFormation(formation.slug)"
                >{{ formation.title }}</h3>
                <div>
                  <button
                    @click="handleGoFormation(formation.slug)"
                    class="btn btn-outline btn-outline--primary btn-small"
                  >
                    En savoir +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DERNIÈRES FORMATIONS RÉALISÉES -->
        <div
          v-if="formations && formations.past && formations.past.length > 0"
          class="sub-item past-formations"
        >
          <h2>Dernières Formations réalisées</h2>
          <div class="past-formations-list">
            <div
              class="past-formation card"
              v-for="(formation, i) in formations.past"
              :key="i"
              v-show="i < 2 || (i >= 2 && toggleFormation)"
            >
              <p class="dates">{{ formation.metadatas.dates }}</p>
              <h3
                @click="handleGoFormation(formation.slug)"
              >{{ formation.title }}</h3>
              <div>
                <button
                  @click="handleGoFormation(formation.slug)"
                  class="btn btn-outline btn-outline--primary btn-small"
                >
                  En savoir +
                </button>
              </div>
              <div
                class="documents"
              >
                <div>
                  <p>Support</p>
                  <a
                    @click="handleGoDocumentFormation(formation.support)"
                    class="btn btn-small"
                    :class="{'btn-dark': formation.support}"
                  >
                    {{ formation.support ? 'Télécharger' : 'Aucun document' }}
                  </a>
                </div>
                <div>
                  <p>Convention</p>
                  <a
                    @click="handleGoDocumentFormation(formation.convention)"
                    class="btn btn-small"
                    :class="{'btn-dark': formation.convention}"
                  >
                    {{ formation.convention ? 'Télécharger' : 'Aucun document' }}
                  </a>
                </div>
                <div>
                  <p>Feuille de présence</p>
                  <a
                    @click="handleGoDocumentFormation(formation.presence)"
                    class="btn btn-small"
                    :class="{'btn-dark': formation.presence}"
                  >
                    {{ formation.presence ? 'Télécharger' : 'Aucun document' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="see-more">
            <button
              v-if="formations.past.length > 2"
              @click="toggleFormation = !toggleFormation"
              class="btn btn-outline btn-outline--primary btn-small"
            >
              Afficher toutes les formations réalisées
            </button>
          </div>
        </div>

        <!-- OFFRES & DOCUMENTS -->
        <div
          v-if="documents || offers"
          class="sub-item offers-documents">
          <div class="content">
            <div
              v-if="offers"
              class="offers">
              <h2>Offres</h2>
              <ListDocumentsCardItem
                v-for="(offer, i) in offers"
                :document="offer"
                :key="i"
                v-show="i < 3 || (i >= 3 && toggleDocuments)"
              />
            </div>
            <div
              v-if="documents"
              class="documents">
              <h2>Documents</h2>
              <ListDocumentsCardItem
                v-for="(document, i) in documents"
                :document="document"
                :key="i"
                v-show="i < 3 || (i >= 3 && toggleDocuments)"
              />
            </div>
          </div>
          <div
            v-if="(documents && documents.length > 3) || (offers && offers.length > 3)"
            class="see-more">
            <button
              @click="toggleDocuments = !toggleDocuments"
              class="btn btn-outline btn-outline--primary"
            >
              En voir plus
            </button>
          </div>
        </div>

        <button
          class="btn btn-outline btn-outline--primary mb-4 md:hidden"
          @click="handleLogout">
          Se déconnecter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/commons/Modal'
import NewPasswordForm from '@/views/pages/Profile/blocks/NewPasswordForm'
import ListDocumentsCardItem from '@/components/customs/documents/ListDocumentsCardItem'

export default {
  components: {
    Modal,
    NewPasswordForm,
    ListDocumentsCardItem
  },

  data: () => ({
    swiperOptions: {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        480: {
          slidesPerView: 2
        }
      }
    },
    toggleFormation: false,
    toggleDocuments: false
  }),

  computed: {
    metadatas () {
      return this.$store.state.authentication.profil.metadatas
    },

    identity () {
      return this.$store.state.authentication.profil.identity
    },

    currentUser () {
      return this.$store.state.authentication.user
    },

    formations () {
      return this.$store.state.authentication.profil.formations
    },

    documents () {
      return this.$store.state.authentication.profil.documents
    },

    offers () {
      return this.$store.state.authentication.profil.offers
    },

    contacts () {
      return this.$store.state.authentication.profil.contacts
    }
  },

  methods: {
    handleGoFiche (slug) {
      if (this.metadatas.type === 'supplier') {
        this.$router.push(`/fournisseur/${slug}`)
      } else {
        this.$router.push(`/adherent/${slug}`)
      }
    },

    handleGoContactForm () {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'Demander à modifier ma fiche'
          }
        })
    },

    handleGoDashboardwithFiliale(isFiliale) {
      this.$router.push(`/dashboard/${isFiliale}`)
    },

    handleGoDashboard () {
      this.$router.push({ name: 'dashboard' })
    },

    handleGoFormation (slug) {
      this.$router.push({ name: 'formation', params: { slug: slug } })
    },

    handleGoDocumentFormation (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },

    fetchUserData () {
      this.$store.dispatch('requestUserDatas')
    },

    handleLogout () {
      this.$store.dispatch('disconnectUser')
    }
  },

  beforeMount () {
    this.fetchUserData()
  }
}
</script>
