import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default ({
  namespaced: true,
  state: {
    macroeconomic: {},
    articles: [],
    webinar: [],
    documents: [],
    references: [],
    formations: [],
    banners: []
  },

  actions,
  mutations,
  getters
})
