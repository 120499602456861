import axios from '@/axios'
import dates from '@/mixins/dates'

const getListing = async ({ commit }, params) => {
  try {
    commit('SET_LOADING', true)
    const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/indices`, {
      params
    })
    commit('SET_LISTING', res.data?.indices)
    commit('SET_FAMILIES', res.data?.families)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_LOADING', false)
  }
}

const getDetail = async ({ state, commit }, id) => {
  try {
    commit('SET_LOADING', true)
    const params = {
      startDate: state.detail.filters.startDate?.toLocaleDateString('fr-FR'),
      endDate: state.detail.filters.endDate?.toLocaleDateString('fr-FR'),
      currency: state.detail.filters.currency,
    }
    const res = await axios.get(`${process.env.VUE_APP_API_VERSION}/indices/${ id }`, {
      params
    })
    commit('SET_DETAIL_ENTITY', res.data?.indice)
    if (!state.detail.filters.startDate && !state.detail.filters.endDate && !state.detail.filters.currency) {
      commit('SET_DETAIL_FILTERS', {
        startDate: dates.methods.parseDateFromFormat(res.data?.filters?.startDate, 'dd/mm/yyyy'),
        endDate: dates.methods.parseDateFromFormat(res.data?.filters?.endDate, 'dd/mm/yyyy'),
        currency: res.data?.filters?.currency,
      })
    }
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_LOADING', false)
  }
}

const toggleFavorite = async ({ rootState, dispatch, commit }, id) => {
  try {
    const res = await axios.patch(
      `${process.env.VUE_APP_API_VERSION}/indices/${id}/favorite`,
      {},
      {
        headers: {
          'X-CSRF-Token': rootState.authentication.xcsrf_token
        }
      }
    )
    dispatch('getListing')
  } catch (err) {
    console.error(err)
  }
}

export default {
  getListing,
  getDetail,
  toggleFavorite,
}
