<template>
  <card
    v-if="slides.length > 0"
    flat>
    <swiper-banner :slides="slides" />
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import SwiperBanner from './CardBannerList'

export default {
  components: {
    Card,
    SwiperBanner
  },

  computed: {
    slides () {
      return this.$store.state.home.banners
    }
  },

  beforeMount () {
    this.$store.dispatch('home/requestBanners')
  }
}
</script>
