<template>
  <section
    class="page-drawer--navigation"
    v-click-outside="handleClosePannel">
    <ul class="list">
      <li
        v-for="(category, index) in listCategories"
        :key="index"
        :class="{ 'active' : $route.name === category.title }"
        class="list-item"
        @click="handleRedirection(category.slug)">
        <img
          class="image"
          :src="category.media.source"
          :alt="category.media.alt">
        <span
          class="label">
          {{ category.title }}
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  computed: {
    listCategories () {
      // Est déjà requêté dans la toolbar, mais à dispatcher dans un beforeMount
      // si jamais l'info n'existe pas dans ce composant.
      return this.$store.state.categories.list
    }
  },

  methods: {
    handleRedirection (slug) {
      this.$router.push(`fournisseurs/${slug}`)
    },

    handleClosePannel () {
      this.$store.dispatch('drawerStatus', false)
    }
  },

  directives: {
    ClickOutside
  }
}
</script>
