import axios from '@/axios'

/**
 * Requêter la liste des adhérents en fonction de paramètres
 * @param {Object} params Les paramètres de l'URL pour filtrer
 */
const requestSubscribersByParams = async ({ commit, dispatch }, payload) => {
  commit('SET_SUBSCRIBERS_PROCESSING', true)
  try {

    // Rebuild query for facets support
    const params = { ...payload.params }
    let facets = {
      f: []
    }

    for (const [i, entry] of Object.entries(params)) {
      switch (i) {
        case 'referent': {
          const values = entry.split(',')

          for(const value of values) {
            facets.f.push(`${i}:${value}`)
          }

          delete params[i]
          break
        }

        case 'department':
        case 'ca':
        case 'staff': {
          const values = entry.split(',')

          for(const value of values) {
            const range = value.split('-')
            facets.f.push(`${i}:(min:${range[0]},max:${range[1]})`)
          }

          delete params[i]
          break
        }

        case 'naf':
        case 'affiliate': {
          facets.f.push(`${i}:${entry}`)
          delete params[i]
          break
        }
      }
    }

    const requestParams = {
      ...facets,
      ...params
    }

    const subscribers = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/subscribers`, {
      params: requestParams
    })

    const filters = subscribers.data.filters
    delete subscribers.data.filters

    commit('SET_SUBSCRIBERS_LIST', subscribers.data)
    commit('SET_SUBSCRIBERS_FILTERS', filters)

  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_SUBSCRIBERS_PROCESSING', false)
  }
}

/**
 * Requêter un fournisseur en fonction de son slug
 * @param {String} slug Slug utilisé pour chercher le fournisseur
 */
const requestSubscriberBySlug = async ({ commit }, slug) => {
  commit('SET_SUBSCRIBERS_PROCESSING', true)
  try {
    const subscriber = await axios.get(`${process.env.VUE_APP_API_VERSION}/subscriber/${slug}`)
    commit('SET_CURRENT_SUBSCRIBER', subscriber.data)
  } catch (err) {
    console.error(err)
    // @todo: rediriger vers la page 404 si erreur avec un message d'erreur
    // indiquant que l'adhérent recherché n'existe pas.
  } finally {
    commit('SET_SUBSCRIBERS_PROCESSING', false)
  }
}

const requestAutocompleteFilterSuggestions = async ({ commit, state }, payload) => {
  try {
    // Rebuild query for facets support (to improve)
    const params = { ...payload.params }
    let facets = {
      f: []
    }

    for (const [i, entry] of Object.entries(params)) {
      switch (i) {
        case 'referent': {
          const values = entry.split(',')

          for(const value of values) {
            facets.f.push(`${i}:${value}`)
          }

          delete params[i]
          break
        }

        case 'department':
        case 'ca':
        case 'staff': {
          const values = entry.split(',')

          for(const value of values) {
            const range = value.split('-')
            facets.f.push(`${i}:(min:${range[0]},max:${range[1]})`)
          }

          delete params[i]
          break
        }

        case 'naf':
        case 'affiliate': {
          facets.f.push(`${i}:${entry}`)
          delete params[i]
          break
        }
      }
    }

    const requestParams = {
      ...facets,
      ...params
    }

    const suggestions = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/subscribers/${payload.filter}`, {
      params: requestParams
    })

    let autocompletesSuggestionsState = state.autocompletesSuggestions
    autocompletesSuggestionsState[payload.filter] = suggestions.data

    commit(`SET_AUTOCOMPLETES_SUGGESTIONS`, autocompletesSuggestionsState)
  } catch (err) {
    console.error(err)
  }
}

const resetAutocompleteFilterSuggestions = async ({ commit, state }, filter) => {
  try {
    let autocompletesSuggestionsState = state.autocompletesSuggestions
    autocompletesSuggestionsState[filter] = []

    commit(`SET_AUTOCOMPLETES_SUGGESTIONS`, autocompletesSuggestionsState)
  } catch (err) {
    console.error(err)
  }
}

export default {
  requestSubscribersByParams,
  requestSubscriberBySlug,
  requestAutocompleteFilterSuggestions,
  resetAutocompleteFilterSuggestions
}
