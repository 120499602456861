<template>
  <section
    v-if="certifs.length > 0"
    class="section pt-6">
    <div class="section-title">
      <icon icon="certification"></icon>
      <h2 class="font-bold">Certifications</h2>
    </div>

    <div class="certifs">
      <div
        v-for="(certif, index) in certifs"
        :key="index"
        @click="handleGoPage"
        class="certif cursor-pointer">
        <img
          :src="certif.media.source"
          :alt="certif.media.alt"
          class="bg-gray-400 mb-3"
          width="100px"
          height="100px">
        <span class="font-bold">
          {{ certif.title }}
        </span>
<!--        <p v-if="certif.body">
          {{ certif.body.split('.')[0] + '.' }}
        </p>-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    certifs: {
      type: Array,
      default: () => ([

      ]),
      required: false
    }
  },

  methods: {
    handleGoPage() {
      this.$router.push({ name: 'page', params: { slug: 'glossaire-certifications' }})
    }
  }
}
</script>

<style lang="scss">
  .certifs {
    @apply flex flex-col justify-start flex-wrap;

    @screen xs {
      @apply flex-row;
    }

    .certif {
      @apply p-3 flex flex-col items-center;

      @screen xs {
        @apply w-1/5;
      }
    }
  }
</style>
