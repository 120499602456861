const SET_OFFERS_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_OFFERS_LIST = (state, data) => {
  state.list = data
}

export default {
  SET_OFFERS_PROCESSING,
  SET_OFFERS_LIST
}
