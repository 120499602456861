const footerItems = [
  {
    text: 'Les Clubs métiers Mercurial',
    link: 'page',
    params: {
      slug: 'les-clubs-metiers-mercurial'
    }
  },
  {
    text: 'Le Club Avantages Mercurial',
    link: 'page',
    params: {
      slug: 'le-club-avantages-mercurial'
    }
  },
  {
    text: 'Mentions légales',
    link: 'page',
    params: {
      slug: 'mentions-legales'
    }
  },
  {
    text: 'Contactez-nous',
    link: 'form',
    params: {},
    query: {
      receiverEmail: 'mercurial@mercurial.fr',
      receiverName: 'Equipe Mercurial'
    }
  },
  {
    text: 'Politique d\'utilisation des cookies',
    link: 'page',
    params: {
      slug: 'politique-dutilisation-des-cookies'
    }
  }
]

export default footerItems
