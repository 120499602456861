const SET_SUBSCRIBERS_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_SUBSCRIBERS_LIST = (state, data) => {
  state.list = data
}

const SET_CURRENT_SUBSCRIBER = (state, data) => {
  state.current = data
}

const SET_SUBSCRIBERS_FILTERS = (state, data) => {
  state.filters = data
}

const SET_IS_AFFILIATES = (state, data) => {
  state.isAffiliates = data
}

const SET_AUTOCOMPLETES_SUGGESTIONS = (state, data) => {
  state.autocompletesSuggestions = data
}

const SET_AUTOCOMPLETE_VALUE = (state, data) => {
  state.autocompletesValues[data.filterName] = {
    label: data.label,
    value: data.value
  }
}

const RESET_AUTOCOMPLETES_VALUES = (state) => {
  state.autocompletesValues = {
    naf: {
      label: '',
      value: ''
    },
    activitySector: {
      label: '',
      value: ''
    },
    affiliate: {
      label: '',
      value: ''
    }
  }
}

export default {
  SET_SUBSCRIBERS_PROCESSING,
  SET_SUBSCRIBERS_LIST,
  SET_CURRENT_SUBSCRIBER,
  SET_SUBSCRIBERS_FILTERS,
  SET_AUTOCOMPLETES_SUGGESTIONS,
  SET_AUTOCOMPLETE_VALUE,
  RESET_AUTOCOMPLETES_VALUES,
  SET_IS_AFFILIATES
}
