<template>
  <card centered-title>
    <template slot="header">
      <h2>
        {{ cardTitle }}
      </h2>
    </template>

    <ul class="list_elaborated">
      <m-elaborated-list-item
        v-for="(item, index) in first3Items"
        :key="index"
        :title="item.title"
        :slug="item.slug"
        :type="item.type"
        :medias="item.medias"
      >
        {{ item.body }}
      </m-elaborated-list-item>
    </ul>

    <template slot="footer">
      <button
        class="btn btn-small btn-outline--primary"
        @click='handleClick'>Voir toute la liste</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import MElaboratedListItem from '@/components/customs/elaborated/ListElaboratedItem.vue'

export default {
  components: {
    Card,
    MElaboratedListItem
  },

  computed: {
    items () {
      return this.$store.state.home.references
    },

    first3Items () {
      return this.items.slice(0, 6)
    },

    cardTitle () {
      return this.$store.getters.userRole === 'subscriber'
        ? 'Les derniers fournisseurs et adhérents qui ont rejoint Mercurial'
        : 'Les derniers adhérents qui ont rejoint Mercurial'
    }
  },

  methods: {
    handleClick () {
      this.$store.getters.userRole === 'subscriber'
        ? this.$router.push({ name: 'suppliers' })
        : this.$router.push({ name: 'subscribers' })
    },

    fetchReferences () {
      this.$store.dispatch('home/requestReferences')
    }
  },

  beforeMount () {
    this.fetchReferences()
  }
}
</script>
