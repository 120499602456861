<template>
  <div
    class="articles--list"
  >
    <div
      class="article"
      v-for="(article, i) in articles"
      :key="i"
      @click="handleGoArticle(article.slug)"
    >
      <span class="article-icon">
        <icon
          :icon="'article-engine'"
          size="s" />
      </span>
      {{ article.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  methods: {
    handleGoArticle (slug) {
      this.$router.push(`/article/${slug}`)
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>

<style lang="postcss" scoped>
  .article {
    @apply cursor-pointer flex flex-wrap
  }

  .article-icon {
    @apply flex items-center mr-2
  }
</style>
