<template>
  <card
    :class="['container', 'relative']">
    <div class="choice">
      <p
        class="choice-card">
        Bienvenue dans votre Centre de Préférences, l'espace dédié où vous avez le contrôle total sur les notifications du site.<br>
        Personnalisez votre expérience en choisissant les alertes qui correspondent à vos préférences.
      </p>
      <ul class="list">
        <div class="flex flex-row justify-between list-header">
          <span>Je souhaite être alerté des thématiques suivantes : </span>
          <ul class="card-channels">
            <li>E-mail</li>
            <li>Push</li>
            <!--<li>SMS</li> masqué temporairement-->
          </ul>
        </div>
        <ul 
          v-for="(category, index) in categories"
          :key="index"
          class="list-item flex flex-row items-center justify-between"
          >
          <div class="list-content">
            <span 
              class="list-item--title">
              {{ category.title }} 
            </span><br v-if="category.title">
            <span>
              {{ category.description }}
            </span>
          </div>
          <div class="checkbox-list">
            <input
              class="cursor-pointer"
              type="checkbox"
              v-model="category.email"
              @change="savePreferences(category, 'email', category.email)">
            <input
              class="cursor-pointer"
              type="checkbox"
              v-model="category.push"
              @change="savePreferences(category, 'push', category.push)">
            <!--<input
              class="cursor-pointer"
              type="checkbox"
              v-model="category.sms"
              @change="savePreferences(category, 'sms', category.sms)"> masqué temporairement-->
          </div>
        </ul>
      </ul>
    </div>
  </card>
</template>

<script>
import Card from '../../../../components/commons/Card.vue'

export default {
  components: { Card },

  computed: {
    categories () {
      return this.$store.state.notifications.categories
    },
    preferences () {
      return this.$store.state.notifications.preferences
    }
  },

  watch: {
    '$store.state.notifications': {
      handler(data, olddata) {
        this.showPreferences()
      },
      deep: true,
    },
  },

  methods: {
    savePreferences(category, type, value) {
      const updatedPreference = {
        idCateg: category.id,
        email: category.email || false,
        push: category.push || false,
        // sms: category.sms || false => masqué temporairement
      }

      const existingPreference = this.preferences.find(preference => preference.idCateg === category.id)

      if (existingPreference) {
        existingPreference[type] = value
      } else {
        this.preferences.push(updatedPreference)
      }

      const data = {
        userId: this.$store.state.authentication.user.id,
        preferences: this.preferences
      }

      this.$store.dispatch('notifications/updateNotificationPreferences', data)
    },
    showPreferences() {
      this.categories?.forEach(category => {
        const preference = this.preferences.find(preference => preference.idCateg === category.id)
        if (preference) {
          category.email = preference.email
          category.push = preference.push
          // category.sms = preference.sms => masqué temporairement
        }
      })

      this.$forceUpdate()
    },
    async fetchNotificationCategories () {
      await this.$store.dispatch('notifications/getNotificationCategories')
    },
    async fetchNotificationPreferences () {
      await this.$store.dispatch('notifications/getNotificationPreferences')
    }
  },

  created () {
    this.fetchNotificationCategories()
    this.fetchNotificationPreferences()
    this.showPreferences()
  }
}
</script>

<style lang="scss" scoped>
.container {
  @apply flex flex-col items-center justify-center w-2/5;
  @apply bg-white p-8 rounded shadow-xs;

  .choice {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    &-card {
      @apply text-sm;
    }
  }
}

.card {
  &-subtitle {
    @apply flex flex-row items-center
  }
  &-channels {
    @apply flex flex-row items-center justify-between gap-3
  }
}

.list {
  &-header {
    color: var(--color-primary-bluer);
    font-size: 16px;
    font-weight: bold;
  }
  &-item {
    padding: 1rem 0;

    &--title {
      @apply font-bold text-lg;
    }
  }
  &-content {
    width: 24rem;
  }

}
.checkbox-list {
  @apply grid grid-cols-2 gap-10;
}
</style>