<template>
  <div class="wrapper">
    <div class="background-white">
      <div class="container">
        <section class="container-header mb-5">
          <h1 class="container-header--title">
            <icon icon="tableau-bord" />
            <span>{{ dashboardTitle }}</span>
          </h1>
          <p
            v-if="userRole === 'subscriber'"
            class="container-header--description">
            Votre tableau de bord Mercurial vous donne une vision globale chiffrée de votre relation avec Mercurial et vos fournisseurs référencés
          </p>
          <p
            v-else
            class="container-header--description">
            Votre tableau de bord Mercurial vous donne une vision globale chiffrées de votre relation avec Mercurial et ses adhérents</p>
        </section>

        <nav v-if="isAffiliates" class="flex flex-wrap items-center mx-auto space-x-6">
          <affiliates-modal
            v-if="userRole === 'subscriber'"
            :title="currentRouteCtx === 'dashboard' ? 'Voir les filiales' : 'Voir les autres filiales'"
            :affiliates="affiliates"/>
          <a
            v-if="userRole === 'subscriber' && currentRouteCtx === 'dashboard-slug'"
            class="btn btn-outline--secondary subscribers"
            :href="'/dashboard'">
            Voir la maison mère
          </a>
        </nav>
      </div>
    </div>

    <div class="background">
      <div class="container">
        <div
          v-if="userRole === 'subscriber'"
          class="block">
          <section-card-list />
        </div>

        <div
          v-else-if="userRole === 'supplier'"
          class="block">
          <div class="lg:flex justify-between">
            <div class="map">
              <section-map></section-map>
            </div>

            <div class="favorites-cards">
              <div class="favorites">
                <section-favorite></section-favorite>
              </div>
              <div class="favorites-card-list">
                <section-card-list />
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <section-tabs />
          <section-chart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Composants affichés aux adhérents (subscribers)
import AffiliatesModal from '@/components/customs/dashboard/AffiliatesModal'

// Composants affichés aux fournisseurs (suppliers)
import SectionMap from './blocks/SectionMap.vue'
import SectionFavorite from './blocks/SectionFavorite.vue'

// Composants communs
import SectionCardList from './blocks/SectionCardList.vue'
import SectionTabs from './blocks/SectionTabs.vue'
import SectionChart from './blocks/SectionChart.vue'
import MDropdown from '@/components/commons/Dropdown'
import $axios from '@/axios'

export default {
  name: 'Dashboard',

  components: {
    AffiliatesModal,
    SectionCardList,
    SectionTabs,
    SectionChart,
    SectionMap,
    SectionFavorite,
    MDropdown
  },

  data: () => ({
    dashboardTitle: 'VOTRE TABLEAU DE BORD',
    affiliates: [],
  }),

  computed: {
    userRole () { // subscriber | supplier
      return this.$store.getters.userRole
    },
    currentRouteCtx() {
      return this.$route.name
    },
    currentAffiliateSlug() {
      return this.$route.params.slug
    },
    isAffiliates(){
      return this.$store.state.subscribers.isAffiliates
    }
  },

  async beforeMount () {
    await this.fetchSubscribers()

    if (this.userRole === 'subscriber'){
      this.fetchAffiliates()
    }
  },
  watch: {
    '$route.query'() {
      if (this.userRole === 'supplier') {
        this.fetchSubscribers()
      }
    },
    affiliates(){
      const headQuarterName = this.affiliates.find(
        affiliate => affiliate.headquarter === true
      )?.title;

      const currentAffiliateName = this.affiliates.find(
        affiliate => affiliate.slug === this.currentAffiliateSlug
      )?.title;

      // If maison-mère
      if (this.currentRouteCtx === 'dashboard'){
        this.dashboardTitle += headQuarterName ? ' ' + headQuarterName : '';
      }
      // Filiale
      else if (this.currentRouteCtx === 'dashboard-slug') {
        this.dashboardTitle += ' DE LA FILIALE' + (currentAffiliateName ? ' ' + currentAffiliateName : '');

        if (headQuarterName && headQuarterName !== currentAffiliateName){
          this.dashboardTitle += ' - ' + headQuarterName;
        }
      }
    }
  },

  methods: {
    async fetchSubscribers () {
      const query = this.$route.query;

      const params = {
        all: true,
        ...query
      }

      const payload = {
        params
      }

      await this.$store.dispatch('requestSubscribersByParams', payload)
    },
    async fetchAffiliates () {
      const response = await $axios.get(`${process.env.VUE_APP_API_VERSION}/dashboard/affiliates`)

      this.affiliates = response.data.affiliates;

      this.$store.commit('SET_IS_AFFILIATES', this.affiliates.length > 1)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply pt-0;
}

.container {
  @apply my-8;

  &-header {
    @apply flex items-center justify-center flex-col;

    &--title {
      @apply flex items-center;
      @apply uppercase font-bold;
    }

    &--description {
      @apply text-center;
      @apply mt-4;
      max-width: 800px;
    }
  }

  .block /deep/ {
    .card {
      @apply flex-1 h-full;
    }
  }

  .map {
    @screen lg {
      width: 66%;
    }
  }

  .favorites-cards {
    @apply flex flex-col justify-between;

    @screen lg {
      width: 32%;
    }

    .favorites {
      @apply my-8;

      @screen lg {
        height: 70%;
        @apply m-0;
      }
    }

    .favorites-card-list {
      @screen lg {
        height: 29%;
      }
    }
  }
}
</style>
