<template>
  <form
    class="toolbar-search--form form_engine"
    autocomplete="off"
    v-on:submit.prevent="handleSubmit">
    <input
      id="search"
      v-model="keyword"
      name="engine"
      type="text"
      class="form_engine-input"
      placeholder="Tapez un ou plusieurs mots clef"
      @click.stop="handleSearch"
      @focus="handleSearch"
      @input="handleSearch"
    >

    <button
      type="submit"
      class="form_engine-submit"
    >
      <icon icon="rechercher" />
    </button>

    <form-dropdown
      v-if="isDropdown"
      @handler="handleSubmitDropdown" />

    <form-dropdown-unset v-else />
  </form>
</template>

<script>
import FormDropdown from './FormDropdown.vue'
import FormDropdownUnset from './FormDropdownUnset.vue'

export default {
  components: {
    FormDropdown,
    FormDropdownUnset
  },

  data: () => ({
    keyword: ''
  }),

  computed: {
    isDropdown () {
      return this.$store.state.engine.displayDropdown
    },

    hasResults () {
      if (this.$store.state.engine.searchTypeValue === 'suppliers') {
        return (this.$store.state.engine.articles.rows && this.$store.state.engine.articles.rows.length > 0) ||
          this.$store.state.engine.tags.length > 0 ||
          this.$store.state.engine.supplierPartners.length > 0 ||
          this.$store.state.engine.suppliers.length > 0 ||
          this.$store.state.engine.families.length > 0

      } else if (this.$store.state.engine.searchTypeValue === 'subscribers') {
        return this.$store.state.engine.subscribers.length > 0
      }
    }
  },

  methods: {
    /**
     * On ouvre le panneau du moteur de recherche si et seulement si les
     * conditions suivantes sont remplies :
     *
     * 1. Un mot de plus de 3 lettres est renseigné par l'user (au passage on
     *    envoie une requête pour l'autocomplétion)
     *
     * 2. On est dans le cas où on recherche dans une liste de fournisseurs (non
     *    pas des offres ou des documents) = valeur paramétrée dans le store au
     *    clique sur le champ select
     */
    async handleSearch () {
      if(this.keyword.length >= 3) {
        if (this.$store.state.engine.searchTypeValue === 'suppliers') {
          // SetKeyword
          await this.$store.dispatch('engine/setKeyword', this.keyword)

          // Get tags
          await this.$store.dispatch('engine/requestTagsBySearch', this.keyword)

          // Get suppliers
          await this.$store.dispatch('engine/requestSuppliersBySearch', this.keyword)

          // Get suppliers partners
          await this.$store.dispatch('engine/requestSupplierPartnersBySearch', this.keyword)

          // Get families
          await this.$store.dispatch('engine/requestFamiliesBySearch', this.keyword)

          // Get articles
          await this.$store.dispatch('engine/requestArticlesBySearch', this.keyword)

          // Get categories for no result
          if (!this.hasResults) {
            await this.$store.dispatch('engine/requestCategories')
            this.$store.commit('engine/SET_RESULTS', false)
          } else {
            this.$store.commit('engine/SET_RESULTS', true)
          }

          // Open panel
          await this.$store.dispatch('engine/panelStatus', true)

        } else if (this.$store.state.engine.searchTypeValue === 'subscribers') {
          // Get subscribers
          await this.$store.dispatch('engine/requestSubscribersBySearch', this.keyword)

          if (this.hasResults) {
            this.$store.commit('engine/SET_RESULTS', true)
          }

          await this.$store.dispatch('engine/panelStatus', true)

        } else {
          await this.$store.dispatch('engine/panelStatus', false)
        }
      }
    },

    handleSubmit () {
      const type = this.$store.state.engine.searchTypeValue

      if (type === 'subscribers') {
        this.$router.push({
          name: 'subscribersSearch',
          query: {
            motclef: this.keyword
          }
        }).catch(() => {})

      } else if (type !== 'suppliers') {
        this.$router.push({
          name: `${type}`,
          query: {
            motclef: this.keyword
          }
        }).catch(() => {})

      } else {
        this.$router.push({
          name: 'result',
          query: {
            motclef: this.keyword
          }
        }).catch(() => {})
      }

      this.$emit('onSearch')
    },

    handleSubmitDropdown () {
      const type = this.$store.state.engine.searchTypeValue

      if (this.keyword) {
        if (type === 'subscribers') {
          this.$router.push({
            name: 'subscribersSearch',
            query: {
              motclef: this.keyword
            }
          }).catch(() => {})

        } else if (type !== 'suppliers') {
          this.$router.push({
            name: `${type}`,
            query: {
              motclef: this.keyword
            }
          }).catch(() => {})

        } else {
          this.$router.push({
            name: 'result',
            query: {
              motclef: this.keyword
            }
          }).catch(() => {})
        }

      }

      this.$emit('onSearch')
    }
  },

  watch: {
    '$route.query' (newQuery, oldQuery) {
      if (this.$route.name === 'result' && newQuery.motclef) {
        this.keyword = newQuery.motclef
      }
    }
  }
}
</script>
