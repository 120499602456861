<template>
  <div class="wrapper">
    <div class="subcontainer subcontainer-large">

      <div
        class="subcontainer-header">
        <h1 class="flex items-center font-bold uppercase text-xl mb-4">
          <icon
            icon="adherents"
            size="lg"/>
          <span>{{ pageTitle }}</span>
        </h1>

        <button
          class="lg:hidden btn btn-dark btn-small mr-2"
          @click="handleOpenDrawer">
            Filtrer
        </button>

        <div class="flex justify-center items-center">
          <div
            @click="displayList"
            class="p-2 rounded border border-gray-700 cursor-pointer">
            <icon icon="liste" />
          </div>
          <div
            @click="displayMap"
            class="p-2 rounded border border-gray-70 ml-2 cursor-pointer">
            <icon icon="carte" />
          </div>
        </div>

        <button
          @click="fetchExcelContent"
          class="btn btn-small btn-outline--primary"
        >
          Export de la liste au format xlsx
        </button>

      </div>
    </div>

    <div class="background">
      <div class="subcontainer subcontainer-large">
        <div class="subcontainer-content">
          <map-view v-if="map"/>
          <div v-else>
            <sort-filter :filter="sortFilter"/>
            <list-subscribers />
            <pagination :page-count="pageCount" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ListSubscribers from '@/components/customs/subscribers/ListSubscribers.vue'
import Pagination from '@/components/commons/Pagination'
import axios from '@/axios'
import SortFilter from '@/components/commons/SortFilter'
import MapView from '@/components/mapbox/MapView'

export default {
  components: {
    MapView,
    SortFilter,
    ListSubscribers,
    Pagination
  },

  data: function () {
    return {
      excelContent: [],
      map: false,
      sortFilter: {
        value: 'sort',
        label: 'Trier par',
        items: [
          {
            value: 'ca-asc',
            label: 'Chiffre d\'affaires croissant'
          },
          {
            value: 'ca-desc',
            label: 'Chiffre d\'affaires décroissant'
          },
          {
            value: 'staff-asc',
            label: 'Effectif croissant'
          },
          {
            value: 'staff-desc',
            label: 'Effectif décroissant'
          },
          {
            value: 'adherence_date-asc',
            label: 'Adhérents du plus ancien au plus récent'
          },
          {
            value: 'adherence_date-desc',
            label: 'Adhérents du plus récent au plus ancien'
          },
          {
            value: 'alphabetic',
            label: 'Ordre alphabétique'
          },
          {
            value: 'alphabetic-reverse',
            label: 'Ordre alphabétique inverse'
          }
        ]
      }
    }
  },

  computed: {
    pageTitle () {
      if (this.$route.query.favorites) {
        return 'Liste des adhérents repérés'
      } else if (this.$route.query.mySubscribers) {
        return 'Mes adhérents'
      } else if(this.$route.query.motclef) {
        return 'Résultat de votre recherche « ' + this.$route.query.motclef  + ' »'
      } else return 'Liste des adhérents'
    },

    pageCount () {
      return this.$store.state.subscribers.list.pager ? this.$store.state.subscribers.list.pager.pageCount : 1
    }
  },

  methods: {
    fetchSubscribers () {
      const payload = {
        params: this.$route.query
      }

      this.$store.dispatch('requestSubscribersByParams', payload)
    },

    async fetchMapSubscribers () {
      const payload = {
        params: {
          all: true,
          ...this.$route.query
        }
      }

      await this.$store.dispatch('requestSubscribersByParams', payload)
    },

    async displayMap () {
      await this.fetchMapSubscribers()
      this.map = true
    },

    displayList () {
      this.map = false
      this.fetchSubscribers()
    },

    /**
     * Ouverture de l'onglet de navigation en mobile
     */
    handleOpenDrawer () {
      this.$store.dispatch('drawerStatus', true)
    },

    async fetchExcelContent () {
      const params = { ...this.$route.query }
      let facets = {
        f: []
      }

      for (const [i, entry] of Object.entries(params)) {
        switch (i) {
          case 'referent': {
            const values = entry.split(',')

            for(const value of values) {
              facets.f.push(`${i}:${value}`)
            }

            delete params[i]
            break
          }

          case 'department':
          case 'ca':
          case 'staff': {
            const values = entry.split(',')

            for(const value of values) {
              const range = value.split('-')
              facets.f.push(`${i}:(min:${range[0]},max:${range[1]})`)
            }

            delete params[i]
            break
          }

          case 'naf':
          case 'affiliate': {
            facets.f.push(`${i}:${entry}`)
            delete params[i]
            break
          }
        }
      }

      const requestParams = {
        ...facets,
        ...params
      }

      const content = await axios.get(`${process.env.VUE_APP_API_VERSION}/subscribers/xlsx`, {
        params: requestParams,
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([content.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'adhérents.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
    }
  },

  watch: {
    '$route.query' (to, from) {
      if (this.map) {
        this.fetchMapSubscribers()

      } else {
        this.fetchSubscribers()
      }
    }
  },

  beforeMount () {
    this.$store.dispatch('engine/setTypeOfValueToSearch', 'subscribers')
    this.$store.dispatch('engine/setLabelToDisplayInSelect', 'Adhérents')

    if (this.$route.query.carto) {
      this.displayMap()
    }
    else {
      this.fetchSubscribers()
    }

  }
}
</script>

<style lang="scss" scoped>
.subcontainer-header {
  @apply justify-between;
}
</style>
