const SET_CONTRACTS_FILTERS = (state, data) => {
  state.filters = data
}

const SET_LOADING = (state, status) => {
  state.loading = status
}

const SET_LISTING = (state, data) => {
  state.listing = data
}


export default {
  SET_CONTRACTS_FILTERS,
  SET_LOADING,
  SET_LISTING,
}
