<template>
  <modal>
    <div
      class="icon-text underline"
      slot="activator">
      <icon
        icon="fleche-bleue"
        size="sm" />
      <a
        class="text-primary"
        href="#"
        @click.prevent="">
        {{ title }}
      </a>
    </div>
    <div class="p-3">
      <contact
        v-for="(contact, index) in contacts"
        :key="index"
        :contact="contact"/>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import Contact from '@/components/customs/contacts/Contact'

export default {
  components: {
    Modal,
    Contact
  },

  props: {
    title: {
      type: String,
      default: 'Autres contacts',
      required: false
    },

    contacts: {
      type: Array,
      default: () => ([]),
      required: true
    }
  }
}
</script>
