import axios from '@/axios'

const requestResultsOffers = async ({ commit }, payload) => {
  commit('SET_OFFERS_PROCESSING', true)
  try {
    const offers = await axios.get(
        `${process.env.VUE_APP_API_VERSION}/search/results/offers`, {
          params: {
            s: payload.keyword,
            page: payload.page
          }
        }
    )

    commit('SET_OFFERS_LIST', offers.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_OFFERS_PROCESSING', false)
  }
}

/**
 * On requête la liste des offres à afficher selon le slug d'un fournsiseur
 * @param {String} supplier
 */
const requestOffersBySupplier = async ({ commit, dispatch }, supplier) => {
  commit('SET_OFFERS_PROCESSING', true)
  try {
    const offers = await axios.get(
      `${process.env.VUE_APP_API_VERSION}/offers/${supplier}`
    )

    commit('SET_OFFERS_LIST', offers.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_OFFERS_PROCESSING', false)
  }
}

export default {
  requestResultsOffers,
  requestOffersBySupplier
}
