<template>
  <div
    v-if="label"
    :class="tagClasses"
    class="tag"
    @click="engine ? handleGoTag(label) : ''"
  >
    <icon
      v-if="icon"
      :icon="icon"
      size="xs">
    </icon>
    <span class="truncate">{{ label }}</span>
    <icon
      v-if="closeIcon"
      icon="fermer"
      size="xs"
      class="ml-2"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },

    variant: {
      type: String,
      default: undefined,
      required: false
    },

    icon: {
      type: String,
      default: null,
      required: false
    },

    engine: {
      type: Boolean,
      default: false,
      required: false
    },

    closeIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    tagClasses () {
      return this.variant ? `tag-${this.variant}` : ''
    }
  },

  methods: {
    handleGoTag (tag) {
      this.$router.push(`/recherche?motclef=${tag}`)
      this.$store.dispatch('engine/panelStatus', false)
    }
  }
}
</script>
