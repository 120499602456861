<template>
  <div class="variations">

    <!-- Valeur -->
    <div class="variation variation--value">
      <div class="background flex flex-col items-center w-full py-5 rounded">
        <span class="variation__title">
        Prix actuel ({{ currency + (currency && unity ? '/' : '') + unity}})
      </span>
      <div class="variation__content">
        <span class="value">
          {{
            value ? value.replace('.00', '') : '—'
          }}
        </span>
      </div>
      </div>
    </div>

    <!-- Variation -->
    <div class="variation">
      <span class="variation__title flex gap-x-2">
        Variation
        <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="variationTooltip"/>
      </span>
      <div
        v-if="variation && variation.value"
        :class="'variation__content variation__content--' + variation.direction"
      >
        <span class="value">
          {{ variation.direction === 'top' ? '+' : (variation.direction === 'bottom' ? '-' : '') }}{{ variation.value.replace('.00', '') }}
        </span><br/>
        <span class="percentage">
          ({{ variation.direction === 'top' ? '+' : (variation.direction === 'bottom' ? '-' : '') }}{{ variation.percentage.replace('.00', '') }}%)
        </span>
      </div>
      <div v-else>
        —
      </div>
    </div>

    <!-- Variation janvier -->
    <div class="variation">
      <span class="variation__title flex gap-x-2">
        Variation au 1er janvier
        <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="januaryVariationTooltip"/>
      </span>
      <div
        v-if="januaryVariation && januaryVariation.value"
        :class="'variation__content variation__content--' + januaryVariation.direction"
      >
        <span class="value">
          {{ januaryVariation.direction === 'top' ? '+' : (januaryVariation.direction === 'bottom' ? '-' : '') }}{{ januaryVariation.value.replace('.00', '') }}
        </span><br/>
        <span class="percentage">
          ({{ januaryVariation.direction === 'top' ? '+' : (januaryVariation.direction === 'bottom' ? '-' : '') }}{{ januaryVariation.percentage.replace('.00', '') }}%)
        </span>
      </div>
      <div v-else>
        —
      </div>
    </div>

    <!-- Variation 1 an -->
    <div class="variation">
      <span class="variation__title flex gap-x-2">
        Variation 1an
        <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="yearVariationTooltip"/>
      </span>
      <div
        v-if="yearVariation && yearVariation.value"
        :class="'variation__content variation__content--' + yearVariation.direction"
      >
        <span class="value">
          {{ yearVariation.direction === 'top' ? '+' : (yearVariation.direction === 'bottom' ? '-' : '') }}{{ yearVariation.value.replace('.00', '') }}
        </span><br/>
        <span class="percentage">
          ({{ yearVariation.direction === 'top' ? '+' : (yearVariation.direction === 'bottom' ? '-' : '') }}{{ yearVariation.percentage.replace('.00', '') }}%)
        </span>
      </div>
      <div v-else>
        —
      </div>
    </div>

    <!-- Variation période -->
    <div class="variation">
      <span class="variation__title flex gap-x-2">
        Variation sur la période
        <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="periodeVariationTooltip"/>
      </span>
      <div
        v-if="periodeVariation && periodeVariation.value"
        :class="'variation__content variation__content--' + periodeVariation.direction"
      >
        <span class="value">
          {{ periodeVariation.direction === 'top' ? '+' : (periodeVariation.direction === 'bottom' ? '-' : '') }}{{ periodeVariation.value.replace('.00', '') }}
        </span><br/>
        <span class="percentage">
          ({{ periodeVariation.direction === 'top' ? '+' : (periodeVariation.direction === 'bottom' ? '-' : '') }}{{ periodeVariation.percentage.replace('.00', '') }}%)
        </span>
      </div>
      <div v-else>
        —
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    currency: {
      type: String,
      default: '€'
    },
    unity: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    variation: {
      type: Object,
      default: () => ({})
    },
    januaryVariation: {
      type: Object,
      default: () => ({})
    },
    yearVariation: {
      type: Object,
      default: () => ({})
    },
    periodeVariation: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      variationTooltip: 'Compare la dernière valeur à la valeur précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      januaryVariationTooltip: 'Compare la dernière valeur à la valeur au 1er Janvier de l’année en cours et affiche la variation en pourcentage et/ou en unité d’achat',
      yearVariationTooltip: 'Compare la dernière valeur à la valeur à la même date de l’année précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      periodeVariationTooltip: 'Compare la valeur du début de période à la valeur de fin de période et affiche la variation en pourcentage et/ou en unité d’achat',
    }
  }
}
</script>
