<template>
  <Card
    leftTitle
    dense
    edged>
    <template slot="header">
      <h2 class="uppercase">Programme</h2>
    </template>

    <template>
      <ul class="accordion hide-on-print">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="accordion-item"
          :class="[{ 'active' : index === active }]">
          <div
            class="accordion-item--title"
            @click="handleClick(index)">
            <h3 class="uppercase font-bold">{{ step.title }}</h3>
            <icon
              v-if="index === active"
              icon="minus"
              size="sm"/>
            <icon
              v-else
              icon="plus"
              size="sm"/>
          </div>

          <div
            v-if="index === active"
            class="accordion-item--content">
            <div v-html="step.description"></div>
            <div
              v-if="step.situation"
              class="mt-4 pt-4 border-t">
              <h4 class="font-bold mb-4 text-lg">Mise en situation et méthode pédagogique</h4>
              <div v-html="step.situation"></div>
            </div>
          </div>
        </li>
      </ul>

      <ul class="accordion printableArea">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="accordion-item active"
          >
          <div
            class="accordion-item--title"
            >
            <h3 class="uppercase font-bold">{{ step.title }}</h3>
            <icon
              icon="minus"
              size="sm"/>
          </div>

          <div
            class="accordion-item--content">
            <div v-html="step.description"></div>
            <div
              v-if="step.situation"
              class="mt-4 pt-4 border-t">
              <h4 class="font-bold mb-4 text-lg">Mise en situation et méthode pédagogique</h4>
              <div v-html="step.situation"></div>
            </div>
          </div>
        </li>
      </ul>

    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  data: () => ({
    active: 0
  }),

  computed: {
    steps () {
      return this.$store.state.articles.current.steps
    }
  },

  methods: {
    handleClick (index) {
      if (this.active === index) {
        this.active = ''
      } else {
        this.active = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &-item {
    @apply border border-tertiary mb-4;

    &--title {
      @apply flex items-center justify-between;
      @apply transition duration-150 ease-in-out;
      @apply text-tertiary p-3 cursor-pointer;
    }

    &--content {
      @apply p-3;
    }

    &.active {
      .accordion-item--title {
        @apply bg-tertiary text-white;
      }
    }
  }
}

</style>
