const SET_SUPPLIERS_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_SUPPLIERS_LIST = (state, data) => {
  state.list = data
}

const SET_CURRENT_SUPPLIER = (state, data) => {
  state.current = data
}

const SET_BANNERS = (state, data) => {
  state.banners = data
}

const SET_SUPPLIERS_FILTERS = (state, data) => {
  state.filters = data
}


export default {
  SET_SUPPLIERS_PROCESSING,
  SET_SUPPLIERS_LIST,
  SET_CURRENT_SUPPLIER,
  SET_BANNERS,
  SET_SUPPLIERS_FILTERS
}
