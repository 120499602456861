<template>
  <section class="section flex flex-col lg:flex-row">
    <img
      class="rounded-lg h-48 w-48 object-contain bg-white border"
      :src="thumbnail.source"
      :alt="thumbnail.alt">

    <div class="lg:w-4/5 lg:pl-12">
      <div class="flex justify-between items-center flex-wrap mb-3">
        <h1 class="font-bold"> {{ title  }} </h1>
        <div class="flex">
        <work-with
            :collaborator="collaborator"
            :favorite="favorite"
            :entity-id="entityId"
            :entity-type="entityType"
        />
        <div class="ml-4" v-if="this.$store.state.subscribers.current.type !== userType && entityType != 'supplier'">
            <div
              v-if="isFavorite"
              @click="handleProcessFavorite">
              <icon
                class="ml-6"
                icon="favoris"
                size=lg>
              </icon>
            </div>
            <div
              v-else
              @click="handleProcessFavorite">
              <icon
                icon="favoris-plus"
                size="lg">
              </icon>
            </div>
          </div>
        </div>
      </div>

      <p class="mb-2" v-if="this.variant != 'supplier'">
        SIRET : {{ siret }}
      </p>

      <span
        v-if="variant === 'subscriber'"
        class="affiliate">
          {{ affiliate }}
      </span>


      <p class="mb-3"> {{ body }} </p>

      <modal v-if="address">
        <span
          class="text-primary-bluer underline cursor-pointer"
          slot="activator">Coordonnées de l'entreprise</span>

        <div class="address">
          <h3 class="address-title">Coordonnées de l'entreprise</h3>
          <address>
            <strong>Rue :</strong> {{ address.street }} <br>
            <strong>Code postal :</strong> {{ address.postal }} <br>
            <strong>Ville :</strong> {{ address.city }} <br>
            <strong>Pays :</strong> {{ address.country }}
          </address>
          <strong>Téléphone :</strong> {{ phone }}

          <a
            v-if="variant === 'subscriber'"
            class="carto btn btn-large btn-primary"
            @click="handleGoCarto">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff" version="1.1" id="Capa_1" width="22px" height="22px" viewBox="0 0 395.71 395.71" xml:space="preserve">
            <g>
              <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
            </g>
            </svg>
            Localiser sur la carte
          </a>
        </div>
      </modal>
    </div>
  </section>
</template>

<script>
import Modal from '@/components/commons/Modal.vue'
import Tag from '@/components/commons/Tag'
import WorkWith from '@/components/customs/suppliers/WorkWith'
import $axios from "@/axios";

export default {
  data: function () {
    return {
      isFavorite: false
    }
  },

  components: {
    Modal,
    Tag,
    WorkWith
  },

  beforeMount() {
    this.isFavorite = this.$store.state.subscribers.current.metadatas.favorite
  },

  methods: {

    async handleProcessFavorite () {
      try {
        const favoriteId = this.$store.state.subscribers.current.id
        await $axios.patch(`${process.env.VUE_APP_API_VERSION}/favorites`, {
          favoriteId: favoriteId
        }, {
          headers: {
            'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
          }
        })
        this.isFavorite = !this.isFavorite
      } catch (err) {
        // Gérer les erreurs ici
      } finally {
      }
    },

    handleGoCarto() {
      this.$router.push(`/recherche/adherents?id=${this.$store.state.subscribers.current.id}&all=true&carto=true`)
    }
  },

  props: {
    title: {
      type: String,
      default: 'Sans-titre',
      required: false
    },

    affiliate: {
      type: String,
      default: '',
      required: false
    },

    siret: {
      type: String,
      default: '',
      required: false
    },

    body: {
      type: String,
      default: '',
      required: false
    },

    thumbnail: {
      type: Object,
      default: () => ({
        source: '',
        alt: ''
      }),
      required: false
    },

    address: {
      type: Object,
      default: () => ({
        street: '',
        postal: '',
        city: '',
        country: ''
      }),
      required: false
    },

    phone: {
      type: String,
      default: '',
      required: false
    },

    collaborator: {
      type: Boolean,
      default: false,
      required: true
    },

    favorite: {
      type: Boolean,
      default: false,
      required: true
    },

    entityId: {
      type: String,
      default: '',
      required: true
    },

    entityType: {
      type: String,
      default: '',
      required: true
    },

    variant: {
      type: String,
      default: '',
      required: true
    }
  },

  computed: {
    userType () {
      return this.$store.getters.userRole
    }
  },

}
</script>

<style lang="scss" scoped>
.address {
  @apply p-8;

  &-title {
   @apply font-bold text-xl uppercase mb-3;
  }
}

.carto {
    display: block;
    font-size: 14px;
    text-decoration: none;
    width: fit-content;
    margin: 10px 0;
    svg {
    display: inline;
    margin: 0 0px 0 -10px;
    }
}

.affiliate { @apply text-primary opacity-50 uppercase font-bold text-base; }
</style>
