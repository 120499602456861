import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import Cookies from 'js-cookie'

export default ({
  state: {
    isProcessing: false,
    errorMessage: '',
    emailSended: false,
    isConnected: !!localStorage.getItem('mercurial_user_data'),
    access_token: Cookies.get('mercurial_access_token') || undefined,
    refresh_token: Cookies.get('mercurial_refresh_token') || undefined,
    xcsrf_token: Cookies.get('mercurial_xcsrf_token') || undefined,
    user: localStorage.getItem('mercurial_user_data') !== 'undefined' ? JSON.parse(localStorage.getItem('mercurial_user_data')) : {
      roles: [
        'guest'
      ]
    },
    profil: {}
  },

  actions,
  mutations,
  getters
})
