<template>
  <aside class="sidebar">
    <div class="sidebar-shortcuts">
      <template v-for="(item, index) in sidebarItems">
        <button
          v-if="hasPermission(item)"
          :key="index"
          class="sidebar-shortcuts--button"
          @click="handleClick(item.link, item.params, item.query)">
          <icon :icon="item.icon"></icon>
          <span>
            {{ item.text }}
          </span>
        </button>
      </template>
    </div>
  </aside>
</template>

<script>
// Import des données en dur mais plus tard appel API
import sidebarItems from './../datas/sidebar.js'

// Import de la mixin qui vérifie les permissions de liens
import checkPermissionMatch from '@/mixins/checkPermissionMatch'

export default {
  computed: {
    sidebarItems () {
      return sidebarItems
    }
  },

  mixins: [checkPermissionMatch],

  methods: {
    handleClick (link, params, query) {
      this.$router.push({ name: link, params: params, query: query })
    }
  }
}
</script>

<style lang="scss" scoped>
// À déplacer dans le fichier icon.scss si le comportemet se répète.
// Comportement : bouton avec icône et texte aligné en colonne.
.icon ~ * {
  @apply ml-0;
}
</style>
