import store from '@/store'

export function useFirebase($axios, $messaging) {
  let openModalFunction = null
  let listenersStarted = false
  let _messaging = $messaging
  let _axios = $axios

  async function startListeners(openModal) {
    if (openModal && typeof openModal === 'function') {
      openModalFunction = openModal
    }
    try {
      // Ecoute l'arrivée de nouvelles notification push
      if (openModalFunction) {
        startOnMessageListener()
      }

      // Intercepte la regénération du token
      // await startTokenRefreshListener()

      // Demande l'autorisation à l'utilisateur de le notifiers
      await requestPermission()
      // Retourne le token de l'utilisateur en cours
      await getIdToken()
      listenersStarted = true
    } catch (e) {
      console.error("Error : ", e)
    }
  } 

  function startOnMessageListener() {
    try {
      _messaging.onMessage((payload) => {
        if (typeof openModalFunction === 'function') {
          openModalFunction(payload)
        }
      })
    } catch (e) {
      console.error("Error : ", e)
    }
  }

  // async function startTokenRefreshListener() {
  //   try {
  //     const firebaseInitialized = await isFirebaseInitialized()
  //     if (firebaseInitialized) {
  //       console.log(_fire)
  //       console.log(_fire.messaging)
  //       console.log(_fire.messaging.onTokenRefresh)

  //       _fire.messaging.onTokenRefresh(async () => {
  //         try {
  //           await _fire.messaging.getToken()
  //         } catch (e) {
  //           console.error("Error : ", e)
  //         }
  //       })
  //     } else {
  //       console.error('Firebase is not initialized.')
  //     }
  //   } catch (e) {
  //     console.error("Error : ", e)
  //   }
  // }

  async function requestPermission() {
    try {
      if ('Notification' in window) {
        Notification.requestPermission()
      }
    } catch (e) {
      console.error("Error : ", e)
    }
  }
  async function getIdToken() {
    try {
      const idToken = await _messaging.getToken({ vapidKey: process.env.VUE_APP_VAPID_KEY_FIREBASE })
      const md = window.innerWidth > 640
      const type = md ? 'desktop' : 'mobile'

      const response = await _axios.post(
        '/api/v1/token',
        {
          token: idToken,
          device: type
        }, {
          headers: {
            'X-CSRF-Token': store.state.authentication.xcsrf_token
          }
        }
      )
    } catch (e) {
      console.error("Error : ", e)
    }
  }

  return {
    startListeners,
    startOnMessageListener,
    // startTokenRefreshListener,
    requestPermission,
    getIdToken,
  }
}