import axios from '@/axios'

/**
 * Ouvre ou ferme le panneau des filtres dans le moteur de recherche
 * @param {*} Boolean Si la valeur est true alors le panneau s'ouvre
 */
const panelStatus = ({ commit }, status) => {
  commit('SET_PANNEL', status)
}

/**
 * Initialisation des paramètres du moteur de rechercge si l'utilisateur est un
 * fournisseur ou un adhérent, il n'aura pas le même affichage.
 * @param {*} param0
 */
const initiateEngineParams = ({ commit, dispatch, rootGetters }) => {
  let params = {
    label: '',
    value: ''
  }

  if (rootGetters.userRole === 'subscriber') {
    params = { label: 'Fournisseurs', value: 'suppliers' }
    commit('SET_DROPDOWN', true)
  } else if (rootGetters.userRole === 'supplier') {
    params = { label: 'Adhérents', value: 'subscribers' }
    commit('SET_DROPDOWN', false)
  }

  dispatch('setTypeOfValueToSearch', params.value)
  dispatch('setLabelToDisplayInSelect', params.label)
}

/**
 * On sauvegarde le type de recherche que l'user effectue.
 * @param {String} value
 */
const setTypeOfValueToSearch = ({ commit }, value) => {
  commit('SET_SEARCH_TYPE', value)
}

/**
 * On enregistre aussi le label pour l'affichage
 * @param {*} label
 */
const setLabelToDisplayInSelect = ({ commit }, label) => {
  commit('SET_LABEL', label)
}

const setKeyword = ({ commit }, search) => {
  commit('SET_KEYWORD', search)
}

/**
 * On récupère les mots clés en fonction de la recherche
 * @param {String} search
 */
const requestTagsBySearch = async ({ commit }, search) => {
  try {
    const tags = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/keywords`, {
      params: {
        s: search
      }
    })
    commit('SET_TAGS_LIST', tags.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les fournisseurs en fonction de la recherche
 * @param {String} search
 */
const requestSuppliersBySearch = async ({ commit }, search) => {
  try {
    const suppliers = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/suppliers`, {
      params: {
        s: search
      }
    })
    commit('SET_SUPPLIERS_LIST', suppliers.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les adhérents en fonction de la recherche
 * @param {String} search
 */
const requestSubscribersBySearch = async ({ commit }, search) => {
  try {
    const subscribers = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/subscribers`, {
      params: {
        s: search
      }
    })
    commit('SET_SUBSCRIBERS_LIST', subscribers.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les fournisseurs partenaires en fonction de la recherche
 * @param {String} search
 */
const requestSupplierPartnersBySearch = async ({ commit }, search) => {
  try {
    const suppliers = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/supplier-partners`, {
      params: {
        s: search
      }
    })
    commit('SET_SUPPLIER_PARTNERS_LIST', suppliers.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les familles et sous-familles en fonction de la recherche
 * @param {String} search
 */
const requestFamiliesBySearch = async ({ commit }, search) => {
  try {
    const families = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/families`, {
      params: {
        s: search
      }
    })
    commit('SET_FAMILIES_LIST', families.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les articles en fonction de la recherche
 * @param {String} search
 */
const requestArticlesBySearch = async ({ commit }, search) => {
  try {
    const families = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/autocomplete/articles`, {
      params: {
        s: search
      }
    })
    commit('SET_ARTICLES_LIST', families.data)
  } catch (err) {
    console.error(err)
  }
}

/**
 * On récupère les catégories pour le no result
 */
const requestCategories = async ({ commit }) => {
  try {
    const categories = await axios.get(`${process.env.VUE_APP_API_VERSION}/categories`)
    commit('SET_CATEGORIES_LIST', categories.data)
  } catch (err) {
    console.error(err)
  }
}

export default {
  panelStatus,
  initiateEngineParams,
  setTypeOfValueToSearch,
  setLabelToDisplayInSelect,
  setKeyword,
  requestTagsBySearch,
  requestSuppliersBySearch,
  requestSubscribersBySearch,
  requestSupplierPartnersBySearch,
  requestFamiliesBySearch,
  requestArticlesBySearch,
  requestCategories
}
