<template>
  <div v-if="entityType !== userType" :style="{ width: '25px' }">
    <div
      v-if="isFavorite"
      @click="handleProcessFavorite">
      <icon
        icon="favoris"
        size=lg>
      </icon>
    </div>
    <div
      v-else
      @click="handleProcessFavorite">
      <icon
        icon="favoris-plus"
        size="lg">
      </icon>
    </div>
  </div>
</template>

<script>
import $axios from "@/axios";

export default {
  props: {
    entityId: {
      type: [Number, String],
      default: '',
      required: true
    },
    entityType: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      isFavorite: false
    }
  },

  computed: {
    userType () {
      return this.$store.getters.userRole
    }
  },

  beforeMount() {
    this.isFavorite = this.value
  },

  methods: {
    async handleProcessFavorite () {
      try {
        const favoriteId = this.entityId
        await $axios.patch(`${process.env.VUE_APP_API_VERSION}/favorites`, {
          favoriteId: favoriteId
        }, {
          headers: {
            'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
          }
        })
        this.isFavorite = !this.isFavorite
      } catch (err) {
        // Gérer les erreurs ici
      } finally {
      }
    }
  }
}
</script>
