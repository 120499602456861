<template>
  <div class="wrapper">
    <div class="subcontainer subcontainer-large">
      <section class="subcontainer-header pt-6">
        <div class="subcontainer-header--title">
          <icon
            icon="resultat-recherche"
            size="xl"/>
          <div>
            <h1 class="uppercase text-primary-light font-bold">Documentation</h1>
            <p class="uppercase font-bold text-xl">Résultat de votre recherche
              <span>« {{ $route.query.motclef }} »</span>
            </p>
          </div>
        </div>
      </section>
    </div>

    <div class="background">
      <div class="subcontainer subcontainer-large">
        <section class="subcontainer-content pb-6">
          <div
            v-if="isLoading"
            class="p-4">
            Chargement...
          </div>
          <list-documents v-else card />
        </section>
        <pagination
          :page-count="pageCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListDocuments from '@/components/customs/documents/ListDocuments.vue'
import Pagination from '@/components/commons/Pagination'

export default {
  components: {
    ListDocuments,
    Pagination
  },

  computed: {
    isLoading () {
      return this.$store.state.documents.isProcessing
    },

    pageCount () {
      return this.$store.state.documents.pageCount ? this.$store.state.documents.pageCount : 1
    }
  },

  methods: {
    fetchDocuments () {
      const payload = {
        s: this.$route.query.motclef,
        category: this.$route.query.category,
        page: this.$route.query.page
      }

      this.$store.dispatch('requestResultsDocuments', payload)
    }
  },

  watch: {
    $route (to, from) {
      this.fetchDocuments()
    }
  },

  beforeMount () {
    this.fetchDocuments()
  }
}
</script>
