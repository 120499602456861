<template>
    <section class="page-drawer--section" :style="{ borderBottom: '0px solid'}" @click.stop>
      <Calendar
        v-model="notificationDate"
        dateFormat="dd/mm/yy"
        show-icon
        placeholder="Date de prévenance avant le"
        class="w-full"
        showButtonBar
      />
      <Calendar
        v-model="endDate"
        dateFormat="dd/mm/yy"
        show-icon
        placeholder="Date de fin avant le"
        class="w-full mt-6"
        showButtonBar
      />
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        notificationDate: null,
        endDate: null,
      }
    },
  
    computed: {
      dates() {
        return this.$store.state.contracts.dates
      }
    },
  
    watch: {
      notificationDate() {
        const yyyy = this.notificationDate?.getFullYear();
        const mm = this.notificationDate?.getMonth() < 9 ? '0' + (this.notificationDate?.getMonth() + 1) : this.notificationDate?.getMonth() + 1;
        const dd = this.notificationDate?.getDate() < 10 ? '0' + this.notificationDate?.getDate() : this.notificationDate?.getDate();
        const queryDate = `${yyyy}-${mm}-${dd}`;

        // Get current query parameters
        const currentQuery = { ...this.$route.query };

        // Add or update notification_date in query parameters
        if (yyyy && mm && dd){
          currentQuery.notification_date = queryDate;
        } else {
          delete currentQuery.notification_date
        }

        // Redirect to the same page with updated query parameters
        this.$router.push({ query: currentQuery });
      },

      endDate() {
        const yyyy = this.endDate?.getFullYear();
        const mm = this.endDate?.getMonth() < 9 ? '0' + (this.endDate?.getMonth() + 1) : this.endDate?.getMonth() + 1;
        const dd = this.endDate?.getDate() < 10 ? '0' + this.endDate?.getDate() : this.endDate?.getDate();
        const queryDate = `${yyyy}-${mm}-${dd}`;

        // Get current query parameters
        const currentQuery = { ...this.$route.query };

        // Add or update notification_date in query parameters
        if (yyyy && mm && dd){
          currentQuery.end_date = queryDate;
        } else {
          delete currentQuery.end_date
        }

        // Redirect to the same page with updated query parameters
        this.$router.push({ query: currentQuery });
      },
    },
  
    methods: {
      handleDateFilters(){
        const query = { ...this.$route.query }
        console.log(query)
        // delete query.page
        // query[startDate] = this.checked.join(',')
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  button[type="submit"] {
    max-width: 195px;
    margin-top: 30px;
  }
  
  ::v-deep {
    .p-datepicker-trigger, .p-datepicker-trigger:enabled:hover {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  
    .p-datepicker table td { padding: 0; }
  }
  </style>
  