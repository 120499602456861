const toolbarItems = {
  user: [
    {
      text: 'Mon compte',
      link: 'profile',
      external: false
    },
    {
      text: 'Déclarer mon CA',
      link: 'https://crm.e-deal.net/mercurialPortal/list_my_declarationca_.fl',
      external: true
    },
    {
      text: 'Tableau de bord',
      link: 'dashboard',
      external: false
    },
    // {
    //   text: 'Feuille de route',
    //   link: null,
    //   external: false
    // },
    {
      text: 'Déconnexion',
      link: null,
      external: false
    }
  ],

  hints: [
    {
      text: 'Être rappelé',
      link: 'form',
      icon: 'etre-rappele',
      query: {
        object: 'Être rappelé'
      },
      params: {}
    },
    {
      text: 'Acheteurs Mercurial',
      link: 'page',
      icon: 'referent-mercurial',
      query: {},
      params: {
        slug: 'acheteurs-mercurial'
      }
    },
    /*{
      text: 'Infos pratiques',
      link: 'page',
      icon: 'fiches-experts_1',
      query: {},
      params: {
        slug: 'infos-pratiques'
      },
      hiddenFor: [
        'supplier'
      ]
    },*/
    {
      text: 'Demander des conseils d\'achats',
      link: 'form',
      icon: 'demande-conseils',
      query: {
        object: 'Renseignements Achats'
      },
      params: {},
      hiddenFor: [
        'supplier'
      ]
    }
  ]
}

export default toolbarItems
