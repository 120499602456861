<template>
  <section class="page-drawer--section">
    <h3 class="title">Affinez votre recherche</h3>
    <div class="content">
      <button
        @click="handleOrder"
        class="btn btn-white w-full">
        <icon
          icon="fleche-bleue"
          size="sm"></icon>
        <span class="font-normal">{{ pertinence ? 'Trier par ordre alphabétique' : 'Trier par pertinence' }}</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      pertinence: !this.$route.query.order
    }
  },

  methods: {
    async handleOrder () {
      const query = Object.assign({}, this.$route.query)
      if(this.pertinence) {
        query.order = 'ASC'
      } else {
        delete query.order
      }
      await this.$router.push({ query })
      this.pertinence = !this.pertinence
    }
  }
}
</script>
