<template>
  <ContractsDetailTemplate :ref-id="id" />
</template>

<script>
import ContractsDetailTemplate from '@/components/customs/contracts/DetailTemplate.vue'

export default {
  components: {
    ContractsDetailTemplate,
  },

  props: {
    
  },
  computed: {
    id(){
      return this.$route.params.id
    }
  },

  data() {
    return {}
  },

  watch: {

  },

  beforeMount () {

  },

  methods: {

  }
}
</script>
