<template>
  <card>
    <template slot="header">
      <icon icon="documents"></icon>
      <h2>{{ userType === 'subscriber' ? 'Vos documents expert Achat' : 'Vos documents Mercurial' }}</h2>
    </template>

    <div class="flex items-stretch h-full">
      <section class="documents-card--infos">
        {{ userType === 'subscriber'
          ? 'Accédez ici à votre bibliothèque de documents et modèles mis à disposition des adhérents par Mercurial pour vos activités et process achats.'
          : 'Retrouvez ici les documents administratifs, commerciaux et marketing à disposition des adhérents.' }}
      </section>

      <section class="documents-card--links">
        <h4 class="documents-card--subtitle">{{ userType === 'subscriber' ? 'Voir tous les documents expert Achats' : 'Télécharger directement vos documents' }}</h4>

        <list-documents
          :documents="documents"
          restricted />
      </section>
    </div>

    <template slot="footer">
      <button v-if="userType == 'supplier'"
        class="btn btn-small btn-outline--primary"
        @click="handleClickSupplier">Voir tous les documents</button>
        <button v-else
        class="btn btn-small btn-outline--primary"
        @click="handleClick">Voir tous les documents</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import ListDocuments from '@/components/customs/documents/ListDocuments'

export default {
  components: {
    Card,
    ListDocuments
  },

  computed: {
    documents () {
      return this.$store.state.home.documents
    },

    userType () {
      return this.$store.getters.userRole
    }
  },

  methods: {
    /**
     * On donne le type de document et on ressort la bonne icone correspondante
     */
    documentIcon (type) {
      return type === 'pdf' ? 'pdf-small' : 'xls-small'
    },

    handleClick () {
      this.$router.push({ name: 'documents',query: { category: '47,127' } })
    },

    handleClickSupplier () {
      this.$router.push({ name: 'documents',query: { category: '88,85,86,87' } })
    },

    fetchDocuments () {
      this.$store.dispatch('home/requestDocuments')
    }
  },

  beforeMount () {
    this.fetchDocuments()
  }
}
</script>

<style lang="scss" scoped>
.documents {
  &-card {
    &--infos {
      @apply p-3 w-1/2;
    }

    &--links {
      @apply w-1/2 flex flex-col;
      background-color: rgba(234, 89, 6, 0.15);

    }

    &--subtitle {
      @apply uppercase font-bold mb-2 p-3;
    }
  }

  &-list {
    @apply pl-3 mb-3;
  }
}
</style>


