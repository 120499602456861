<template>
  <section class="p-16">
    <p
        v-if="validationError"
        class="p-3 mb-5 bg-red-700 text-white font-bold rounded"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="submitError"
        class="p-3 mb-5 bg-red-700 text-white font-bold rounded"
    >
      {{ submitError }}
    </p>
    <form
      v-if="!submitted"
      @submit.prevent="submitForm"
    >
      <fieldset class="flex flex-col">
        <label
          for="currentPassword"
          class="form-label"
        >Mot de passe actuel</label>
        <input
          type="password"
          placeholder="Ancien mot de passe"
          id="currentPassword"
          class="form-input"
          v-model="currentPassword"
        >

        <label
            for="newPassword"
            class="form-label"
        >Nouveau mot de passe</label>
        <input
          type="password"
          placeholder="Nouveau mot de passe"
          id="newPassword"
          class="form-input"
          v-model="newPassword"
        >

        <label
            for="newPasswordConfirm"
            class="form-label"
        >Confirmer le mot de passe</label>
        <input
          type="password"
          placeholder="Confirmer nouveau mot de passe"
          id="newPasswordConfirm"
          class="form-input"
          v-model="newPasswordConfirm"
        >
        <div class="flex justify-center">
          <button
              type="submit"
              class="btn btn-primary">
            Enregistrer
          </button>
        </div>
      </fieldset>
    </form>
    <div v-else-if="!validationError && !submitError">
      <p
        class="p-3 mb-5 bg-green-800 text-white font-bold rounded"
      >
        Votre mot de passe a bien été modifié
      </p>
      <div class="flex justify-center items-center">
        <button
          @click="disconnectUser"
          class="btn btn-primary">
          Se reconnecter
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "@/components/engine/Form";

export default {
  components: {
      Form
  },

  data: function () {
    return {
      validationError: false,
      submitted: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },

  computed: {
    submitError () {
      return this.$store.state.authentication.errorMessage
    }
  },

  methods: {
    async submitForm () {
      // Validation
      this.validationError = false
      if(!this.currentPassword || !this.newPassword || !this.newPasswordConfirm) {
        this.validationError = 'Tous les champs sont requis'
        return
      }
      if(this.newPassword !== this.newPasswordConfirm) {
        this.validationError = 'Les mots de passe ne correspondent pas'
        return
      }

      const payload = {
        'pass': [{ existing: this.currentPassword, value: this.newPassword }]
      }
      await this.$store.dispatch('changePassword', payload)
      this.submitted = true
    },

    disconnectUser () {
      this.$store.dispatch('disconnectUser')
    }
  },

  beforeMount() {
    this.$store.dispatch('closeErrorMessage')
  }
}
</script>