<template>
  <card centered-title>
    <template slot="header">
      <icon
        icon="prochain-webinar"
        size="xl" />
      <h2>Prochain Webinar</h2>
    </template>

    <ul class="list_elaborated" v-if="lastItem.length">
      <m-elaborated-list-item
        v-for="(item, index) in lastItem"
        :key="index"
        :title="item.title"
        :slug="item.slug"
        :type="'webinar'"
      >
        {{ item.body }}
      </m-elaborated-list-item>
    </ul>
    <ul v-else>
      <li class="p-3">Pas de webinar à l'agenda pour le moment</li>
    </ul>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import MElaboratedListItem from '@/components/customs/elaborated/ListElaboratedItem.vue'

export default {
  components: {
    Card,
    MElaboratedListItem
  },

  computed: {
    items () {
      return this.$store.state.home.webinar
    },

    lastItem () {
      return this.items.slice(0, 1)
    }
  },

  methods: {
    fetchWebinar () {
      this.$store.dispatch('home/requestWebinar')
    }
  },

  beforeMount () {
    // Requête de l'API distante
    this.fetchWebinar()
  }
}
</script>
