<template>
  <transition
    name="slide_left"
    mode="out-in"
    appear>
    <nav
      class="navbar"
      :class="{ open: isOpen }"
      v-click-outside="handleClickOutside"
      v-if="isOpen">
      <ul
        v-for="(navigation, index) in navbarItems"
        :key="index"
        class="navbar-menu">
        <li
          v-for="(item, index) in navigation.subItems"
          :key="index"
          class="navbar-menu--items">
          <router-link
            v-if="hasPermission(item) && item.link !== '/dashboard'"
            class="item-link"
            :to="item.link"
            @click.native="handleClose">
            {{ item.text }}
          </router-link>
          <a class="item-link" v-if="item.link === '/dashboard' && currentUser.isFiliale"
            @click="handleGoDashboardwithFiliale(currentUser.isFiliale)"
            >
          Tableau de bord
          </a>
          <a class="item-link" v-else-if="item.link === '/dashboard' && !currentUser.isFiliale"
            @click="handleGoDashboard()"
            >
          Tableau de bord
          </a>
        </li>
      </ul>

      <div class="navbar-links">
        <template v-for="item in navbarLinks">
          <button
            v-if="hasPermission(item)"
            class="navbar-links--item"
            :key="item.index"
            @click="handleClick(item.link, item.params, item.query)">
            <icon
              :icon="item.icon"
              size="xl" />
            <span class="text">
              {{ item.text }}
            </span>
          </button>
        </template>
      </div>
    </nav>
  </transition>
</template>

<script>
// Dépendences extérieures
import ClickOutside from 'vue-click-outside'

import navbarItems from './../datas/navbar.js'
import sidebarItems from './../datas/sidebar.js'

// Import de la mixin qui vérifie les permissions de liens
import checkPermissionMatch from '@/mixins/checkPermissionMatch'

export default {
  computed: {
    navbarItems () {
      return navbarItems
    },

    navbarLinks () {
      return sidebarItems
    },

    currentUser () {
      return this.$store.state.authentication.user
    },

    isOpen () {
      return this.$store.state.isNavbarOpen
    }
  },

  mixins: [checkPermissionMatch],

  methods: {
    handleClose () {
      this.$store.dispatch('navbarStatus', false)
    },

    handleClickOutside () {
      if (window.innerWidth >= 768) {
        this.handleClose()
      }
    },

    handleClick (link, params, query) {
      this.$router.push({ name: link, params: params, query: query })
      this.handleClose()
    },

    handleGoDashboardwithFiliale(isFiliale) {
      this.$router.push(`/dashboard/${isFiliale}`)
    },

    handleGoDashboard() {
        this.$router.push({ name: 'dashboard' })
    },
  },

  directives: {
    ClickOutside
  }
}
</script>
