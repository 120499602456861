<template>
  <Card
    id="interviews"
    v-if="interviews.length > 0"
    leftTitle
    dense
    edged>
    <template slot="header">
      <h2 class="uppercase">Témoignages</h2>
    </template>

    <template>
      <div
        v-for="interview in interviews"
        :key="interview.index"
        class="interview">
        <h3 class="font-bold">{{ interview.title }}</h3>
        <div class="flex items-center justify-center">
          <div class="w-3/4">
            <p
              class="mt-3"
              v-html="interview.body" />
          </div>
          <div
            v-if="interview.slug"
            @click="handleClick(interview.slug)"
            class="w-1/4 text-center">
            <button class="btn interview-link">Voir ce témoignage</button>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/commons/Card.vue'

export default {
  components: {
    Card
  },

  computed: {
    interviews () {
      return this.$store.state.articles.current.interviews
    }
  },

  methods: {
    handleClick (slug) {
      this.$router.push({ name: 'page', params: { slug: slug } })
    }
  }
}
</script>

<style lang="scss" scoped>
.interview {
  @apply bg-gray-200 p-4 mb-4;

  &-link {
    @apply bg-white;
    @apply uppercase;
    min-width: 200px;
  }
}
</style>
